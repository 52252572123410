<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { useApi } from '@/composables/useApi';
import { useToast } from '@/composables/useToast';
import { useSwal } from "@/composables/useSwal";
import { useRoute, useRouter } from "vue-router/composables";
import store from "@/store";
import PieChart from '../components/PieChart'

const router = useRouter();
const route = useRoute();

const api = useApi();
const toast = useToast();
const swal = useSwal();

const timer = ref(null);
const customer = ref();
const currencies = ref([]);
const selectedCurrency = ref(-1);
const customerCurrency = ref();
const btnCurrencyDisabled = ref(true);
const currenciesListId = ref(0);
const chartData = ref(null);
const chartLoaded = ref(false);
const isActive = ref(null);
const isBlacklisted = ref(null);
const isBlacklistedDomain = ref(null);
const googleConsent = ref(null);
const chartOptions = {
  responsive: true,
  tooltips: {
    callbacks: {
      label: (tooltipItems, data) => {
        return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + ' MB';
      }
    }
  }
}
const fieldsCustomerTags = [
  { key: 'id', label: '#' },
  { key: 'page', label: 'Page' },
  { key: 'nav_dt', label: 'Date' }
];
const fieldsCustomerRois = [
  { key: 'id', label: '#' },
  { key: 'reference', label: 'Reference' },
  { key: 'HT', label: 'HT' },
  { key: 'TTC', label: 'TTC' },
  { key: 'port', label: 'FP' },
  { key: 'nav_dt', label: 'Date' }
];

onMounted(async () => {
  timer.value = setInterval(async () => {
    await refreshData(true)
  }, 5000)
  await refreshData(true)
  await getCurrencies();
  await getCustomerCurrency();
});

onBeforeUnmount(() => {
  clearInterval(timer.value);
});

async function refreshData(quickRefresh = false) {
  if (!quickRefresh) {
    customer.value = null;
    chartData.value = null;
    chartLoaded.value = null;
  }
  const response = await api.get(`/customers/${route.params.id}`);
  customer.value = response.data.content;

  customer.value.url = customer.value.website.toLowerCase();
  if (!customer.value.url.includes('http') || !customer.value.url.includes('https')) {
    customer.value.url = `https://${customer.value.url}`;
  }

  chartData.value = response.data.content.chartData;
  chartLoaded.value = response.data.content.chartLoaded;

  const gCresponse = await api.get(`/gc-consent/${customer.value.login}`);
  googleConsent.value = gCresponse.data.content.isClientUseContentV2;

  const status = await api.get(`/customers/${customer.value.login}/status`);
  if (status.data.status === 'success') {
    isActive.value = status.data.is_active;
    isBlacklisted.value = status.data.is_blacklisted;
    isBlacklistedDomain.value = status.data.is_domain_blacklisted;
  }
}

async function getCustomerCurrency() {
  const response = await api.get(`/customers/${route.params.id}/currency`);
  customerCurrency.value = response.data;
  selectedCurrency.value = customerCurrency.value;
  btnCurrencyDisabled.value = false;
}

async function getCurrencies() {
  const response = await api.get('/customers/currencies/getall');
  currencies.value = [...(Object.keys(response.data)), 'EUR'].sort((a, b) => a.localeCompare(b));
}

async function setCurrency() {
  if (!btnCurrencyDisabled.value) {
    btnCurrencyDisabled.value = true;
    let alertMessage = '';
    let alertStatus = '';

    try {
      const response = await api.put(`/customers/${route.params.id}/currency`, { newCurrency: selectedCurrency.value, customerLogin: customer.value.login });
      currenciesListId.value++;
      customerCurrency.value = selectedCurrency.value;
      alertMessage = response.data.content;
      alertStatus = response.data.status;
    } catch (err) {
      alertMessage = err.response.data.content;
      alertStatus = err.response.data.status;
    }

    toast.toast(alertMessage, {
      title: 'Mise à jour de la devise',
      noCloseButton: true,
      variant: alertStatus === 'error' ? 'danger' : 'success',
      solid: true
    });

    btnCurrencyDisabled.value = false;
  }
}

async function toggleStatus() {
  let result = await swal({
    text: 'Êtes-vous sur de vouloir modifier l\'état de ce client ?',
    icon: 'warning',
    animation: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Oui !'
  })
  if (result.isConfirmed) {
    const response = await api.post(`/customers/${route.params.id}/status`, {
      status: !customer.value.status.enabled
    })
    await refreshData()
    toast.toast(response.data.content, {
      title: null,
      noCloseButton: true,
      variant: response.data.status === 'error' ? 'danger' : 'success',
      solid: true
    })
  }
}

async function toggleAlertsStatus() {
  let result = await swal({
    text: 'Êtes-vous sur de vouloir modifier l\'état de la surveillance des tags de ce client ?',
    icon: 'warning',
    animation: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Oui !'
  })
  if (result.isConfirmed) {
    const response = await api.post(`/customers/${route.params.id}/alerts-status`, {
      status: !customer.value.alerts_enabled
    })
    await refreshData()
    toast.toast(response.data.content, {
      title: null,
      noCloseButton: true,
      variant: response.data.status === 'error' ? 'danger' : 'success',
      solid: true
    })
  }
}

async function goToVisitorsInspector() {
  router.push({ path: (`/customer/${route.params.id}/visitorsinspector`) });
}

const getCustomer = computed(() => store.state.customer);

const getStatusInfos = computed(() => {
  if (customer.value.status.enabled === 0) {
    return { variant: 'danger', label: 'Client désactivé', dateDisabled: customer.value.status.dateDisabled };
  } else {
    return { variant: 'success', label: 'Client Actif', dateDisabled: false };
  }
});

const getAlertsStatusInfos = computed(() => {
  if (customer.value.alerts_enabled === 0) {
    return { variant: 'danger', label: 'Alerte désactivée' };
  } else {
    return { variant: 'success', label: 'Alerte active' };
  }
});
const isTagStatusReady = computed(() => isActive.value !== null && isBlacklisted.value !== null && isBlacklistedDomain.value !== null);

const getVariantIsActive = computed(() => {
  if (isActive.value) {
    return 'success';
  } else {
    return 'danger';
  }
});

const getVariantIsBlacklisted = computed(() => {
  if (isBlacklisted.value) {
    return 'danger';
  } else {
    return 'secondary';
  }
});

const getVariantIsBlacklistedDomain = computed(() => {
  if (isBlacklistedDomain.value) {
    return 'danger';
  } else {
    return 'secondary';
  }
});

const getVariantIsGoogleConsent = computed(() => {
  if (googleConsent.value) {
    return 'success';
  } else {
    return 'danger';
  }
});
</script>

<template>
  <div>
    <Spinner v-if="!customer" />
    <div v-else>
      <b-row align-v="center">
        <b-col md="8" offset="2">
          <h3>
            <b-icon class="refreshDataButton" icon="arrow-counterclockwise"
              @click="refreshData()"></b-icon>&nbsp;Informations
            client
          </h3>
          <span class="subtitle">{{ customer.login }} : <a :href="customer.url" target="_blank">{{ customer.website
              }}</a></span>
          <b-row>
            <b-col md="8" class="p-3">
              <b-row class="mb-3">
                <b-col>
                  <b-button @click="toggleStatus()" :variant="getStatusInfos.variant">{{
      getStatusInfos.label
    }}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button @click="toggleAlertsStatus()" :variant="getAlertsStatusInfos.variant">{{
      getAlertsStatusInfos.label
    }}
                  </b-button>
                </b-col>
                <b-col class="subtitle" style="margin: auto;">
                  Inscrit le {{ customer.dateCreation }}
                </b-col>
                <b-col v-if="getStatusInfos.dateDisabled" class="text-right subtitle" style="margin: auto;">
                  Désactivé le {{ getStatusInfos.dateDisabled }}
                </b-col>
              </b-row>
              <b-row v-if="isTagStatusReady" class="mb-3">
                <b-col>
                  <b-badge :variant="getVariantIsActive">Active site</b-badge>
                  &nbsp;
                  <b-badge :variant="getVariantIsBlacklisted">Blacklisted site</b-badge>
                  &nbsp;
                  <b-badge :variant="getVariantIsBlacklistedDomain">Blacklisted domain</b-badge>
                  &nbsp;
                  <b-badge :variant="getVariantIsGoogleConsent">Google consent Mode v2 {{ googleConsent ? 'actif' : 'inactif' }}
                  </b-badge>
                </b-col>
              </b-row>
              <h4 style="margin-top: 20px">Outils:</h4>
              <b-row style="column-gap: 15px; margin-bottom: 20px">
                <b-col cols="auto" class="mr-auto">
                  <b-button v-b-popover.hover.bottom="'Inspecter les visiteurs suspectés de fraude au click.'"
                    title="Visitors Inspector" variant="light" @click="goToVisitorsInspector">
                    <img style="width: 32px;" src="../assets/magnifying-glass.png" />
                  </b-button>
                </b-col>
                <b-col cols="auto">
                  <b-row class="d-flex flex-row align-items-end">
                    <b-col cols="auto">
                      <div class="d-flex flex-column">
                        <label for="dropdown-devise">Devise du client</label>
                        <select class="form-control" id="dropdown-devise" style="padding:4px 10px"
                          v-model="selectedCurrency" :key="currenciesListId">
                          <option value="-1" selected disabled hidden v-if="selectedCurrency == -1">Chargement de la
                            liste...</option>
                          <option :value="c" v-for="c in currencies" :key="c" :disabled="c == customerCurrency"
                            :selected="c == customerCurrency">{{ c }}</option>
                        </select>
                      </div>
                    </b-col>
                    <b-col cols="auto">
                      <b-button class="btn btn-primary"
                        :disabled="btnCurrencyDisabled || selectedCurrency === customerCurrency"
                        @click="setCurrency">OK</b-button>
                    </b-col>
                  </b-row>

                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <h5>Derniers appels tracker</h5>
                  <b-table striped hover id="customer-table" empty-text="Aucune donnée pour ce client."
                    :items="customer.tags" :fields="fieldsCustomerTags" stacked="sm" show-empty small>
                  </b-table>
                </b-col>
                <b-col md="12">
                  <h5>Derniers appels roi</h5>
                  <b-table striped hover id="customer-table" empty-text="Aucune donnée pour ce client."
                    :items="customer.rois" :fields="fieldsCustomerRois" stacked="sm" show-empty small>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="chartLoaded" md="4" class="text-center p-3">
              <h5>Bases de données</h5>
              <pie-chart :chartData="chartData" :options="chartOptions"></pie-chart>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss">
.refreshDataButton {
  cursor: pointer;
}
</style>
