<template>
  <b-row class="vh-100 text-center" align-v="center">
    <b-col md="12">
      <div class="text-center">
        <b-button variant="primary" disabled>
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Loading'
}
</script>
