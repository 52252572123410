<script setup>
import { defineProps, ref, onMounted } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  series: {
    type: Array,
    required: true,
  },
  xaxis: {
    type: Array,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  error: {
    type: Boolean,
    required: true,
  },
  objectives: {
    type: Array,
    required: false,
  },
  symbol: {
    type: String,
    required: false,
    default: "",
  },
  max100: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const chart = ref();

let chartOptions = {
  chart: {
    type: "area",
    animations: {
      animateGradually: {
        enabled: false,
      },
    },
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  fill: {
    gradient: {
      shadeIntensity: 0.1,
      opacityFrom: 0.6,
      opacityTo: 0,
      stops: [0, 90, 100],
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: "bottom",
    itemMargin: {
      horizontal: 15,
      vertical: 20,
    },
  },
  series: props.series,
  xaxis: {
    categories: props.xaxis,
    tickAmount: 6,
  },
  yaxis: {
    decimalsInFloat: 0,
    max: props.max100 ? 100 : undefined,
  },
  tooltip: {
    theme: "dark",
    hideEmptySeries: false,
    custom: function ({ series, dataPointIndex, w }) {
      const sortedSeries = series
        .map((s, i) => {
          const name = w.globals.initialSeries[i].name;
          const serieIndex = w.globals.initialSeries.findIndex(
            (s) => s.name === name
          );
          const value = s[dataPointIndex];
          const color = w.globals.colors[serieIndex];
          return {
            name,
            value,
            color,
          };
        })
        .sort((a, b) => {
          return b.value - a.value;
        });
      const title = new Date(props.xaxis[dataPointIndex]).toLocaleDateString(
        "fr-FR",
        {
          month: "long",
          day: "numeric",
          year: "numeric",
        }
      );
      return `
            <div style="z-index: 9999; border-radius: 0.375rem;">
            <div style="font-weight: bold; padding: 10px;">
              ${title}
            </div>
            <div style="display: flex; flex-direction: column; gap: 1rem; padding: 10px;">
              ${sortedSeries
          .map((s) => {
            return `
                  <div style="display: flex; gap: 0.5rem; color: white; align-items: center;">
                    <div style="border-radius: 50%; height: 10px; width: 10px; background-color: ${s.color};"></div>
                    <div>${s.name}</div>
                    <div>${s.value} ${props.symbol}</div>
                  </div>
                `;
          })
          .join("")}
            </div>
          </div>
        `;
    },
  },
  annotations: {
    yaxis: props.objectives,
  },
};

onMounted(() => {
  const series = props.series;
  const xaxis = props.xaxis;
  if (!chart.value) return;
  chart.value.updateSeries(series);
  chart.value.updateOptions({
    xaxis: {
      categories: xaxis,
    },
  });
});
</script>

<template>
  <div class="chart-wrapper position-relative">
    <div class="dot-background"></div>
    <div v-if="!loading && !error && series.length" class="chart-container">
      <apexchart ref="chart" type="area" height="400" :options="chartOptions" :series="series" />
    </div>
    <div v-else class="spinner-wrapper">
      <b-spinner v-if="loading" label="Loading..."></b-spinner>
      <div v-else>
        <h3 v-if="error" class="text-danger">
          Une erreur est survenue lors du chargement des données
        </h3>
        <h3 v-else>Aucune donnée disponible</h3>
      </div>
    </div>
  </div>
</template>

<style scoped>
.spinner-wrapper {
  position: relative;
  height: 400px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dot-background {
  position: absolute;
  inset: 0;
  z-index: -1;

  background-image: radial-gradient(var(--text-primary-color) 1px,
      transparent 0);
  background-size: 7px 7px;
  background-position: -8.5px -8.5px;
  opacity: 40%;

  mask-image: radial-gradient(ellipse at center,
      rgba(0, 0, 0, 1),
      transparent 75%);
}
</style>
