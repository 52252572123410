<script setup>
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import store from "@/store";
import { useModal } from '@/composables/useModal';
import { useToast } from '@/composables/useToast';
import { useSwal } from "@/composables/useSwal";
import { useApi } from '@/composables/useApi';

const api = useApi();
const swal = useSwal();
const bvModal = useModal();
const bvToast = useToast();

const canDelete = ref(false);
const loading = ref(false);
const requestLogs = ref(null);
const timer = ref(null);
const filter = ref(null);
const filterOn = ref([]);
const selectedRequestStatus = ref(null);
const modal_customer = ref();
const modal_customer_disabled = ref();
const modal_request_logs = ref();

const customers = ref([]);
const requests = ref([]);
const types = ref([]);
const totalRows = ref(1);
const currentPage = ref(1);
const totalRequestRows = ref(1);
const currentRequestPage = ref(1);
const perPage = ref(20);
const log = ref(null);

const fieldsCustomers = [
  { key: 'id', label: '#', sortable: true },
  { key: 'Login', label: 'Login', sortable: true },
  { key: 'SiteName', label: 'Url' },
  { key: 'enabled', label: 'Actif' }
];

const fieldsRequests = [
  { key: 'message', label: '' },
  { key: 'cancel', label: '' },
  { key: 'id', label: '#' },
  { key: 'login', label: 'Login' },
  { key: 'type', label: 'Type' },
  { key: 'start_date', label: 'Début' },
  { key: 'end_date', label: 'Fin' },
  { key: 'created', label: 'Création' },
  { key: 'status', label: 'Status' },
  { key: 'user', label: 'Utilisateur' },
  { key: 'completed', label: 'Durée' }
];

const getCustomer = computed(() => store.state.customer);

onMounted(() => {
  fetchData();
  timer.value = setInterval(refreshRequests, 5000);
});

onBeforeUnmount(() => {
  clearInterval(timer.value);
});

async function fetchData() {
  try {
    const [customersRes, typesRes, usersRes] = await Promise.all([
      api.get('/customers'),
      api.get('/types'),
      api.get('/users/infos')
    ]);

    customers.value = customersRes?.data?.content || [];
    totalRows.value = customers.value.length;

    types.value = typesRes?.data?.content || [];

    log.value = usersRes?.data?.content?.log || null;
    canDelete.value = usersRes?.data?.content?.rank > 2;

    await refreshRequests();
  } catch (error) {
    handleError(error);
  }
}

async function refreshRequests() {
  try {
    const response = await api.get('/launcher');
    requests.value = response?.data?.content || [];
    totalRequestRows.value = requests.value.length;
  } catch (error) {
    handleError(error);
  }
}

function handleError(error) {
  if (error.response) {
    bvToast.toast(error.response.data.content, {
      title: null,
      noCloseButton: true,
      variant: 'danger',
      solid: true
    });
  }
}

function onFiltered(filteredItems) {
  totalRows.value = filteredItems.length;
  currentPage.value = 1;
}

function onRequestStatusFiltered(filteredItems) {
  totalRequestRows.value = filteredItems.length;
  currentRequestPage.value = 1;
}

function onFilterRequestsByStatus(row, selectedStatus) {
  return row?.status_id?.toString() === selectedStatus;
}

function showModalCustomer(row) {
  store.state.customer = row;
  row.enabled ? modal_customer.value.show() : modal_customer.value.hide();
}

function showModalRequestLogs(message) {
  requestLogs.value = message;
  modal_request_logs.value.show();
}

async function launcher(type) {
  try {
    const response = await api.post(`/launcher/${type}`, {
      login: getCustomer.value.Login,
      id_site: getCustomer.value.id,
      log: log.value,
      user: localStorage?.user || null
    });

    if (response) {
      bvToast.toast(response.data.content, {
        title: null,
        noCloseButton: true,
        variant: response.data.status === 'success' ? 'success' : 'danger',
        solid: true
      });
    }
  } catch (error) {
    handleError(error);
  }
}

function getLogsSize(message) {
  return `Logs ${(new Blob([message]).size / 1024).toFixed(2)}kb`;
}

async function cancelRequest(record, index) {
  const result = await swal({
    text: 'Annuler la demande ?',
    icon: 'warning',
    animation: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Je suis sûr !'
  });

  if (result.isConfirmed) {
    try {
      const response = await api.get(`/launcher/cancel/${record.id}`);
      if (response && response.data.status === 'success') {
        requests.value.splice(index, 1);
        swal({
          animation: false,
          icon: response.data.status,
          text: response.data.content,
        });
      }
    } catch (error) {
      handleError(error);
    }
  }
}

async function deleteCustomer(id) {
  const result = await swal({
    text: 'Supprimer ce client ?',
    icon: 'warning',
    animation: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Supprimer !'
  });

  if (result.isConfirmed) {
    try {
      bvModal.hide('modal-customer');
      loading.value = true;
      const response = await api.delete(`/customers/delete/${id}`);
      if (response && response.data.status === 'success') {
        filter.value = null;
        loading.value = false;
        bvToast.toast(response.data.content, {
          title: null,
          noCloseButton: true,
          variant: 'success',
          solid: true
        });
      }
    } catch (error) {
      handleError(error);
    }
  }
}
</script>

<template>
  <div class="dark-theme">
    <div>
      <Spinner v-if="loading || (customers.length <= 0 && types.length <= 0 && requests.length <= 0)" />
      <div v-else style="max-width: 2160px; margin: auto;">
        <b-row align-v="center">
          <b-col md="12">
            <b-row>
              <b-col sm="12" lg="12" md="3" xl="3">
                <b-input-group class="mb-2" size="sm">
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Rechercher un client"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Vider</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" lg="12" md="3" xl="3">
                <h5>Créer une demande</h5>
                <b-table
                    striped hover
                    id="customers-table"
                    empty-text="Aucun client n'a été récupéré"
                    :items="customers"
                    :fields="fieldsCustomers"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="sm"
                    show-empty
                    small
                    fixed
                    @filtered="onFiltered"
                    @row-clicked="showModalCustomer"
                >
                  <template #table-colgroup="scope">
                    <col :key="scope.id" :style="{ width: '60px' }" />
                    <col />
                    <col />
                    <col :key="scope.id" :style="{ width: '50px' }" />
                  </template>
                  <template #cell(Login)="data">
                    <div class="w-100 text-truncate">{{ data.item.Login }}</div>
                  </template>
                  <template #cell(SiteName)="data">
                    <div class="w-100 text-truncate">{{ data.item.SiteName }}</div>
                  </template>
                  <template #cell(enabled)="data">
                    <b-col align-self="center" v-if="data.item.enabled === 1">
                      <b-icon icon="check-circle-fill" variant="success"></b-icon>
                    </b-col>
                    <b-col v-else>
                      <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                    </b-col>
                  </template>
                </b-table>
                <b-pagination
                    align="center"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="sm"
                ></b-pagination>
              </b-col>
              <b-col sm="12" lg="12" md="9" xl="9">
                <h5>Dernières demandes</h5>
                <b-table
                    striped hover
                    id="requests-table"
                    empty-text="Chargement en cours..."
                    :fields="fieldsRequests"
                    :items="requests"
                    :per-page="perPage"
                    :current-page="currentRequestPage"
                    stacked="sm"
                    show-empty
                    small
                    :filter="selectedRequestStatus"
                    :filter-function="onFilterRequestsByStatus"
                    @filtered="onRequestStatusFiltered"
                    empty-filter-text="Aucune demande avec ce status"
                >
                  <template #cell(message)="data">
                    <div v-if="data.item.message" @click="showModalRequestLogs(data.item.message)" v-b-tooltip.lefttop :title="getLogsSize(data.item.message)">
                      <b-icon icon="exclamation-circle"></b-icon>
                    </div>
                  </template>
                  <template #cell(cancel)="data">
                    <div v-if="data.item.status_id === 1 && data.item.system === 0" @click="cancelRequest(data.item, data.index)" v-b-tooltip.lefttop title="Annuler la demande">
                      <b-icon icon="trash"></b-icon>
                    </div>
                  </template>
                  <template #cell(login)="data">
                    <div v-if="data.item.login">
                      {{ data.item.login }}
                    </div>
                    <div v-else>
                      Tous les clients
                    </div>
                  </template>
                  <template #cell(completed)="data">
                    <div v-if="data.item.status_id === 3 || data.item.status_id === 4">
                      {{ data.item.completed }}
                    </div>
                    <div v-else-if="data.item.status_id === 2">
                      <b-icon icon="clock"/>
                    </div>
                    <div v-else-if="data.item.status_id === 1">
                      <b-icon icon="hourglass-split"/>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                    align="center"
                    v-model="currentRequestPage"
                    :total-rows="totalRequestRows"
                    :per-page="perPage"
                    size="sm"
                    aria-controls="requests-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <b-modal ref="modal_customer" hide-footer size="md">
        <template #modal-title>
          <b-col md="12">
            <router-link :to="{ name: 'Customer', params: { id: getCustomer.id }}">{{ getCustomer.Login }}</router-link>
          </b-col>
          <b-col md="12" class="modal-subtitle">Choisissez une action pour ce client</b-col>
        </template>
        <div class="d-block">
          <b-row class="text-center">
            <b-col md="12">
              <router-link v-for="type in types.page" v-bind:key="type.id" :to="type.code === 'visitorsinspector' ? `customer/${getCustomer?.id ?? 0}/visitorsinspector` : type.code">
                <b-button class="m-1" :id="type.code" :variant="type.variant" v-show="!type.hidden">{{ type.label }}</b-button>
                <b-popover :target="type.code" triggers="hover" placement="bottom">
                  <template #title>{{ type.label }}</template>
                  {{ type.help }}
                </b-popover>
              </router-link>
              <span v-for="type in types.single" v-bind:key="type.id">
              <b-button class="m-1" :id="type.code" @click="launcher(type.code)" :variant="type.variant" v-show="!type.hidden">{{ type.label }}</b-button>
              <b-popover :target="type.code" triggers="hover" placement="bottom">
                <template #title>{{ type.label }}</template>
                {{ type.help }}
              </b-popover>
            </span>
              <button v-if="canDelete" type="button" class="btn btn-danger" @click="deleteCustomer(getCustomer.id)">
                Suppression du client
              </button>
            </b-col>
          </b-row>
        </div>
      </b-modal>
      <b-modal ref="modal_customer_disabled" hide-footer size="md">
        <template #modal-title>
          <b-col md="12">
            <router-link :to="{ name: 'Customer', params: { id: getCustomer.id }}">{{ getCustomer.Login }}</router-link>
          </b-col>
        </template>
        <div class="d-block">
          <b-row class="text-center">
            <b-col md="12">
              Ce client est désactivé, aucune action ne peut être effectuée.
            </b-col>
          </b-row>
        </div>
      </b-modal>
      <b-modal ref="modal_request_logs" size="lg" scrollable hide-footer title="Logs de la demande">
          <pre class="p-2" style="color: silver; background: black;">
            <code>{{ requestLogs }}</code>
          </pre>
      </b-modal>
    </div>
  </div>
</template>

<style lang="scss">
table tbody {
  tr {
    &:hover {
      cursor: pointer;
    }
  }
}

.modal-subtitle {
  font-size: 12px;
}
</style>
