<script setup>
import Vue, { ref, onMounted, computed } from 'vue';

const http = Vue.prototype.$http;

const clients = ref([]);
const search = ref('');
const gestCam = ref([]);
const gestCamsSelected = ref([]);

async function getNonConsentedCustomers() {
    const response = await http.get('/gc-consent');
    clients.value = response.data.content
    gestCam.value = [...new Set(clients.value.map(e => e.gestcam))].filter(e => e !== null);
}

const filteredClients = computed(() => {
  return clients.value.filter(client =>
      gestCamsSelected.value.length > 0
          ? gestCamsSelected.value.includes(client.gestcam) && client.Login.toLowerCase().includes(search.value.toLowerCase())
          : client.Login.toLowerCase().includes(search.value.toLowerCase())
  );
});

onMounted(() => {
    getNonConsentedCustomers();
});
</script>

<template>
    <div class="view-container">
        <div class="d-flex flex-column mb-4">
            <h2>
                Google Consent Mode v2
            </h2>
            <p class="text-secondary">
              Cette page vous permet de consulter les clients qui n'ont pas encore activé le Google Consent Mode v2.
            </p>
          <p class="text-secondary">
            Elle est mise à jour tout les matins. Pour plus d'information sur le Google Consent Mode v2, la documentation est disponible ici: <a href="https://pointorg.atlassian.net/wiki/spaces/GLOB/pages/544505857/Google+Consent+Mode+V2">Confluence</a>.
          </p>
        </div>

        <input v-model="search" class="form-control mb-4 w-25" placeholder="Recherche" />

        <multiselect class="mb-4 w-25" v-model="gestCamsSelected" :options="gestCam" :multiple="true"
                   :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                   placeholder="Choisissez un gestionnaire de campagne"/>

      <p>Total: {{ filteredClients.length }} site(s) détecté(s)</p>
        <div class="d-grid">
            <div v-for="client in filteredClients" :key="client.id" class="card">
                <div class="indicator" />
                <div>
                    <div class="header">
                        {{ client.Login }} <span class="badge">#{{ client.id }}</span>
                    </div>
                    <div class="body text-secondary">
                        {{ client.url }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.d-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.card {
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 10px;
    align-items: center;
    background-color: var(--background-color-secondary);
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.header {
    font-size: 1.15rem;
}

.body {
    font-size: 1rem;
    color: #555;
}

.indicator {
    width: 30px;
    height: 30px;
    background-color: rgb(235, 0, 0);
    border-radius: 50%;
}
</style>
