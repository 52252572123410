<script setup>
import ChartsSection from "../components/matrice/ChartsSection.vue";
import GlobalStat from "../components/matrice/GlobalStat.vue";
import { useAutoMatrix } from "@/composables/useAutoMatrix";
import { useClients } from "@/composables/useClients";
import { useGestcam, company } from "@/composables/useGestcam";
import { ref, onMounted, watch, computed } from "vue";

const dayjs = require("dayjs");

const { fetchClients, clients, loading: loadingClients } = useClients();
const { fetchGestcams, gestcams } = useGestcam();
const { fetchGlobalStats } = useAutoMatrix();

const date = ref({
  startDate: dayjs().startOf('month').subtract(2, "year").format("YYYY-MM-DD"),
  endDate: dayjs().endOf('month').format("YYYY-MM-DD"),
});
const isSelectedEndDateAboveToday = computed(() => {
  return dayjs(date.value.endDate).isAfter(dayjs());
});

const ProjectionType = [
  { id: -1, name: "Choisir un type de projection", disabled: true },
  { id: 0, name: "Depuis les 30 derniers jours" },
  { id: 1, name: "Depuis la date de début jusqu'à aujourd'hui" },
  { id: 2, name: "Comparée au mois de l'année précédente" }
];

const ProjectionAlgo = [
  { id: -1, name: "Choisir un algorithme de projection", disabled: true },
  { id: 0, name: "Projection linéaire" },
  { id: 1, name: "Projection de régression linéaire" }
];

const selectedClients = ref([]);
const selectedGestcam = ref(0);
const selectedCompany = ref(0);
const selectedProjectionType = ref(0);
const selectedProjectionAlgo = ref(0);

const params = computed(() => {
  return {
    startDate: date.value.startDate,
    endDate: date.value.endDate,
    clients: selectedClients.value,
    gestcam: selectedGestcam.value,
    company: selectedCompany.value,
    projectType: selectedProjectionType.value,
    projectAlgo: selectedProjectionAlgo.value,
  };
});

const chartsData = [
  {
    endpoint: "goal",
    symbol: "€",
    charts: [
      {
        title: "Objectif",
        series: ["goal", "maxCost"],
      }
    ],
  },
  {
    endpoint: "finance",
    symbol: "€",
    charts: [
      {
        title: "Participation au CA client",
        series: ["billableRoi", "roi"],
      },
      {
        title: "Taux de participation",
        series: ["roiRate"],
        symbol: "%",
        max100: true,
      },
      {
        title: "Graphique financier",
        series: ["commissions", "cost", "margin"],
      },
    ],
  },
  {
    endpoint: "rate",
    symbol: "%",
    objectives: [
      {
        y: 48,
        label: {
          text: "Objectif de % de marge",
        }
      }
    ],
    charts: [
      {
        title: "Evolution du pourcentage de marge",
        series: ["*"],
      },
    ],
  },
  {
    endpoint: "margin",
    symbol: "€",
    rateMode: true,
    charts: [
      {
        title: "Répartition de la marge brute par leviers",
        series: ["*"],
      },
    ],
  },
  {
    endpoint: "booking",
    symbol: "",
    rateMode: true,
    charts: [
      {
        title: "Conversions commissionables",
        series: ["billable"],
      },
      {
        title: "Conversions non commissionables",
        series: ["non_billable"],
      },
      {
        title: "Conversion par statut",
        series: ["acceptedRoi", "refusedRoi", "unqualifiedRoi"],
      }
    ],
  }
];

const globalStats = [
  {
    name: "Conversions",
    key: "conv",
    nb: 0,
  },
  {
    name: "Conversions apportées au client",
    key: "conv_billable",
    nb: 0,
  },
  {
    name: "CA total du client",
    key: "turnover",
    nb: 0,
  },
  {
    name: "CA apporté au client",
    key: "turnover_billable",
    nb: 0,
  },
  {
    name: "Dépenses",
    key: "cost",
    nb: 0,
  },
  {
    name: "Marge",
    key: "margin",
    nb: 0,
  },
]

onMounted(async () => {
  await fetchClients(selectedCompany.value, selectedGestcam.value);
  await fetchGestcams();
});

function handleSelectedClients(selectedOption) {
  if (selectedOption.id !== 0) {
    const index = selectedClients.value.findIndex((client) => client.id === 0);
    if (index !== -1) {
      selectedClients.value.splice(index, 1);
    }
  } else {
    const index = selectedClients.value.findIndex((client) => client.id !== 0);
    if (index !== -1) {
      selectedClients.value.splice(index, 1);
    }
  }
}

const loading = ref(false);
const error = ref(false);

async function fetchStats(params) {
  if (selectedClients.value.length === 0) return;
  loading.value = true;
  try {
    const globalStatsData = await fetchGlobalStats(params);
    globalStats.forEach((stat) => {
      stat.nb = globalStatsData[stat.key];
    });
    error.value = false;
    loading.value = false;
  } catch (error) {
    if (error.message !== "Canceled due to new request") {
      error.value = true;
      loading.value = false;
    }
  }
}

watch(
  () => params.value,
  async () => {
    await fetchStats(params.value);
  },
  { deep: true }
);

watch(
  () => selectedCompany.value,
  async () => {
    await fetchClients(selectedCompany.value, selectedGestcam.value);
  }
);

watch(
  () => selectedGestcam.value,
  async () => {
    await fetchClients(selectedCompany.value, selectedGestcam.value);
  }
);
</script>

<template>
  <div class="view-container">
    <div class="d-flex align-items-center">
      <h2>
        Analyse client
      </h2>
    </div>
    <b-row>
      <b-col cols="3">
        <b-form-group label="Entreprise :" label-for="input-company">
          <b-select v-model="selectedCompany" :options="company" placeholder="Choisir une entreprise" value-field="id"
            text-field="name" />
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-row>
        <b-col cols="4">
          <b-form-group label="Clients :" label-for="input-client" style="max-width: 300px">
            <multiselect v-model="selectedClients" :disabled="loadingClients" :loading="loadingClients"
              :options="clients" :multiple="true" :close-on-select="false" @select="handleSelectedClients"
              @remove="handleSelectedClients" :clear-on-select="false" :preserve-search="true"
              placeholder="Choisir un client" label="login" track-by="id" :show-labels="true" :allow-empty="true"
              select-label="Select">
              <template slot="option" slot-scope="{ option }">
                <div class="d-center">
                  <span class="ml-2">{{ option.login.length > 20 ? option.login.substring(0, 20) + "..." : option.login
                    }}</span>
                </div>
              </template>
            </multiselect>
          </b-form-group>
          <p style="font-size: 0.7rem; color: var(--text-third-color);width: 500px">
            Attention, séléctionner l'option "Tous les clients" ou plusieurs clients en même temps peut
            entraîner un temps de chargement plus long.</p>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Gestcam :" label-for="input-gestcam">
            <b-select v-model="selectedGestcam" :disabled="loadingClients" :options="gestcams"
              placeholder="Choisir une gestcam" value-field="id" text-field="name" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="mb-3">
      <b-row>
        <b-col cols="6">
          <b-form-group id="input-group-start-date" class="start-period" label="Date de début :"
            label-for="input-start-date">
            <b-form-datepicker id="input-start-date" v-model="date.startDate" placeholder="Choisir une date" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group id="input-group-end-date" class="end-period" label="Date de fin :" label-for="input-end-date">
            <b-form-datepicker id="input-end-date" v-model="date.endDate" placeholder="Choisir une date" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isSelectedEndDateAboveToday">
        <b-col cols="6 mt-4">
          <b-form-group label="Type de projection :" label-for="input-projection-type">
            <b-select v-model="selectedProjectionType" :options="ProjectionType"
              placeholder="Choisir un type de projection" value-field="id" text-field="name" />
          </b-form-group>
          <p style="font-size: 0.7rem; color: var(--text-third-color);width: 500px">
            La date de fin est supérieure à la date du jour ! Vous devrez choisir un type de projection et un algorithme
            afin de voir les
            données projetées.
          </p>
        </b-col>
        <b-col cols="6 mt-4">
          <b-form-group label="Algorithme de projection :" label-for="input-projection-algo">
            <b-select v-model="selectedProjectionAlgo" :options="ProjectionAlgo"
              placeholder="Choisir un algorithme de projection" value-field="id" text-field="name" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div v-if="selectedClients.length > 0">
      <p style="font-size: 0.8rem; margin-bottom: 8px;color: var(--text-third-color)">
        Les données sont calculées à partir des chiffres hors taxes.
      </p>
      <h6 v-if="isSelectedEndDateAboveToday">Statistiques globales ne prenant pas en compte les données projetées</h6>
      <div class="global-stats">
        <GlobalStat v-for="stat in globalStats" :key="stat.name" :title="stat.name" :nb="stat.nb" :loading="loading" />
      </div>
      <div class="position-relative" v-for="chart in chartsData" :key="chart.title">
        <ChartsSection :params="params" :chartInfos="chart" />
      </div>
    </div>
    <div v-else class="spinner-wrapper">
      <div class="dot-background"></div>
      <h3>
        Veuillez choisir un client
      </h3>
    </div>
  </div>
</template>

<style scoped>
.view-container {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.global-stats {
  gap: 1rem;
  margin-bottom: 2rem;
  display: grid;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.spinner-wrapper {
  position: relative;
  height: 400px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dot-background {
  position: absolute;
  inset: 0;

  background-image: radial-gradient(var(--text-primary-color) 1px, transparent 0);
  background-size: 7px 7px;
  background-position: -8.5px -8.5px;
  opacity: 40%;

  mask-image: radial-gradient(ellipse at center, rgba(0, 0, 0, 1), transparent 75%);
}
</style>
