<template>
  <div class="chart">
    <apexchart
        :options="chartOptions"
        :series="requestsSeries"
        height="500"
        type="area"
        class="apexchart"
    >
    </apexchart>
  </div>
</template>

<script>

export default {
  data: () => ({
    requestsCategories: [],
    requestsSeries: [],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false
        },
        type: 'area'
      },
      title: {
        text: 'Nombres de demandes (par mois)',
        style: {
          fontSize: '25px',
        }
      },
      xaxis: {
        type: "datetime",
        tickAmount: 10,
        labels: {
          format: 'MM-yyyy'
        }
      },
      tooltip: {
        x: {
          show: true,
          format: 'MM-yyyy',
          formatter: undefined,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        itemMargin: {
          vertical: 5
        },
      },
    }
  }),
  created() {
    this.getRequestsPerMonthsData();
  },
  methods: {
    getRequestsPerMonthsData: async function () {
      this.$store.state.requestsPerMonthsChartLoading = true
      const response = await this.$http.get(`/stats/requestspermonths`)
      this.requestsCategories = response.data.content.categories
      this.requestsSeries = response.data.content.series
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.requestsCategories
          }
        }
      }
      this.$store.state.requestsPerMonthsChartLoading = false
    },
  },
}
</script>

<style scoped>
.chart {
  margin: 0 auto;
  width: 100%;
}
</style>
