<template>
  <apexchart class="apexchart"
             height="350"
             :options="this.chartOption"
             :series="chartSerie"/>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "totalOfCommission",
  data: () => ({
    chartOption: {
      chart: {
        height: 350,
        type: 'pie',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'right',
        offsetX: 100,
      },
      colors: ['#00E396', '#008FFB'],
      title: {
        text: 'Total des commissions',
        align: 'left'
      },
      labels: ['360&1', 'Amenitiz'],
    },
    chartSerie: [4, 5],
  }),

  props: {
    date: {
      type: Object,
      default: () => ({
        startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      })
    }
  },

  async mounted() {
    await this.getDistributionOfCommissionsPerDay();
  },

  watch: {
    date: {
      handler: async function () {
        await this.getDistributionOfCommissionsPerDay();
      },
      deep: true
    }
  },

  methods: {
    async getDistributionOfCommissionsPerDay() {
      const response = await this.$http.post(`/amenitiz/per-day`, {
        isChart: true,
        startDate: this.date.startDate,
        endDate: this.date.endDate
      });
      if (response && response.data && response.data.content) {
        if (response.data.content.commissions360 && response.data.content.commissionsAmz) {
          const totalAmenitizPeriod = response.data.content.commissionsAmz.reduce((a, b) => a + b, 0)
          const total360Period = response.data.content.commissions360.reduce((a, b) => a + b, 0)
          this.chartSerie = [parseInt(total360Period), parseInt(totalAmenitizPeriod)]
        }
      }
    }
  },

}
</script>

<style scoped>

</style>
