<template>
  <div>
    <Spinner v-if="loading"/>
    <div v-else>
      <b-row align-v="center">
        <b-col md="12" class="mb-2">
          <b-dropdown text="Télécharger le contrat" class="mr-2">
            <b-dropdown-item @click="downloadContract('en')">Anglais</b-dropdown-item>
            <b-dropdown-item @click="downloadContract('fr')">Français</b-dropdown-item>
            <b-dropdown-item @click="downloadContract('it')">Italien</b-dropdown-item>
            <b-dropdown-item @click="downloadContract('es')">Espagnol</b-dropdown-item>
          </b-dropdown>
          <b-button v-b-modal.modal-add variant="secondary">Ajouter</b-button>
        </b-col>
        <b-col md="12">

          <b-input-group class="mb-2" size="sm">
            <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Rechercher une signature">
            </b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Vider</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="12">
          <b-progress class="mb-2" :value="this.totalRows" :max="100">
            <b-progress-bar variant="success" :value="(100 * countSigned )/ this.totalRows"
                            :label="`${(((100 * countSigned )/ this.totalRows)).toFixed(2)}%`"></b-progress-bar>
            <b-progress-bar variant="warning" :value="(100 * countWaiting )/ this.totalRows"
                            :label="`${(((100 * countWaiting )/ this.totalRows)).toFixed(2)}%`"></b-progress-bar>
            <b-progress-bar variant="danger" :value="(100 * countSignatureWaiver )/ this.totalRows"
                            :label="`${(((100 * countSignatureWaiver )/ this.totalRows)).toFixed(2)}%`"></b-progress-bar>
          </b-progress>
        </b-col>

        <b-col md="12">
          <b-table
              hover
              @filtered="onFiltered"
              selectable
              no-select-on-click
              @row-clicked=showModal($event)
              empty-text="Aucune information à afficher"
              :tbody-tr-class="isRowSigned"
              id="esign-table"
              :items="esigns"
              :fields="fieldsESign"
              :per-page="perPage" :current-page="currentPage" :filter="filter"
              :filter-included-fields="filterOn" stacked="sm" show-empty small>
            <template #thead-top>
              <b-tr>
                <b-th colspan="2">Signatures électroniques</b-th>
                <b-th colspan="1">Total : {{ totalRows }}</b-th>
                <b-th colspan="2" variant="success">
                  <b-icon icon="dot" variant="success" font-scale="2"></b-icon>
                  Signés : {{ countSigned }}
                </b-th>
                <b-th colspan="1" variant="warning">
                  <b-icon icon="dot" variant="warning" font-scale="2"></b-icon>
                  En attente : {{ countWaiting }}
                </b-th>
                <b-th colspan="3" variant="danger">
                  <b-icon icon="dot" variant="danger" font-scale="2"></b-icon>
                  Abandons : {{ countSignatureWaiver }}
                </b-th>
              </b-tr>
            </template>
            <template #cell(actions)="data">
              <b-button-group size="sm" style="display: flex; justify-content: space-around">

                <b-button v-if="!data.item.signed"
                          :variant="data.item.signature_waiver ? 'success' : 'danger'"
                          @click="signatureWaiverRow(data.item)"
                          size="sm"

                >
                  <b-icon v-b-popover.hover.top="'Abandonner le prospect'" v-if="!data.item.signature_waiver" icon="x" ></b-icon>
                  <b-icon v-b-popover.hover.top="'Rétablir le prospect'" v-if="data.item.signature_waiver" icon="check" ></b-icon>
                </b-button>
              </b-button-group>
            </template>
            <template #cell(phone)="data">
              <span class="text-left">{{ data.item.phone }}</span>
            </template>
            <template #cell(score)="data">
              <div class="text-center">
                <b-badge v-if="getScore(data.item.establishment_type, data.item.establishment_rooms, data.item.establishment_prices) === 0" variant="dark">?</b-badge>
                <b-badge v-else :variant="getVariant(data.item.establishment_type, data.item.establishment_rooms, data.item.establishment_prices)">{{ getScore(data.item.establishment_type, data.item.establishment_rooms, data.item.establishment_prices) }}</b-badge>
              </div>
            </template>
            <template #cell(hotel)="data">
              <span class="text-left">{{ data.item.hotel }}</span>
            </template>
            <template #cell(email)="data">
              <span class="text-left">{{ data.item.email }}</span>
            </template>
            <template #cell(signature_date)="data">
              <span class="text-left" v-if="data.item.signature_date">{{ data.item.signature_date }}</span>
              <span class="text-left" v-else>Non signé</span>
            </template>
          </b-table>
          <b-pagination
              align="center"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal id="modal-customer" hide-footer size="md">
      <template #modal-title>
        <b-col md="12">
          Ajout manuel du contrat
        </b-col>
        <b-col md="12" class="modal-subtitle">Utilisez <a target="_blank" href="https://drop.datavault.tech/">https://drop.datavault.tech/</a>
          pour uploader le contrat
        </b-col>
        <b-col md="12" class="modal-subtitle">Le mot de passe d'accès est <code>46[AO0k|~>nqu-{$R|zJG_R9&QZY8%UU</code>&nbsp;<b-icon
            icon="clipboard" v-b-popover.hover.top="'Copier'"
            @click="copy('46[AO0k|~>nqu-{$R|zJG_R9&QZY8%UU')"/>
        </b-col>
      </template>
      <div class="d-block">
        <b-form @submit="saveContract">
          <b-row class="text-center">
            <b-col md="12" class="mb-3">
              <b-form-input required v-model="manualContract"
                            placeholder="https://drop.datavault.tech/f.php?h=5v_4CBVi&d=1"></b-form-input>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
    <b-modal
        id="modal-detail"
        ref="modalDetail"
        centered
        scrollable
        hide-footer
        title="Détail client"
        size="xl"
    >
      <div class="d-block">
        <b-tabs card fill>
          <b-tab title="Informations">
            <b-form @submit.prevent="updateRow">
              <b-row>

                <b-col md="4">
                  <b-form-group label-for="selectedRow.id">
                    <template #label>
                      <span class="transitionCopy text-right mr-2 cursor">
                        <b-icon icon="clipboard"
                                v-b-popover.hover.top="'Copier'"
                                @click="copy(typeEstablishment[selectedRow.establishment_type])"
                        />
                      </span>
                      <span class="text-left">Type d'établissement :</span>
                    </template>
                    <b-form-input id="id"
                                  disabled
                                  type="text"
                                  v-model="typeEstablishment[selectedRow.establishment_type]"
                                  placeholder="identifiant"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label-for="selectedRow.id">
                    <template #label>
                      <span class="transitionCopy text-right mr-2 cursor">
                        <b-icon icon="clipboard"
                                v-b-popover.hover.top="'Copier'"
                                @click="copy(establishmentRooms[selectedRow.establishment_rooms])"
                        />
                      </span>
                      <span class="text-left">Nombre de chambres :</span>
                    </template>
                    <b-form-input id="id"
                                  disabled
                                  type="text"
                                  v-model="establishmentRooms[selectedRow.establishment_rooms]"
                                  placeholder="identifiant"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label-for="selectedRow.id">
                    <template #label>
                      <span class="transitionCopy text-right mr-2 cursor">
                        <b-icon icon="clipboard"
                                v-b-popover.hover.top="'Copier'"
                                @click="copy(establishmentPrices[selectedRow.establishment_prices])"
                        />
                      </span>
                      <span class="text-left">Prix minimum moyen par nuit :</span>
                    </template>
                    <b-form-input id="id"
                                  disabled
                                  type="text"
                                  v-model="establishmentPrices[selectedRow.establishment_prices]"
                                  placeholder="identifiant"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="2" id="identifiant">
                  <b-form-group label-for="selectedRow.id">
                    <template #label>
                      <span class="transitionCopy text-right mr-2 cursor">
                        <b-icon icon="clipboard"
                                v-b-popover.hover.top="'Copier'"
                                @click="copy(selectedRow.id)"
                        />
                      </span>
                      <span class="text-left">Identifiant :</span>
                    </template>
                    <b-form-input id="id"
                                  disabled
                                  type="number"
                                  v-model="selectedRow.id"
                                  placeholder="identifiant"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="8" id="hotel">
                  <b-form-group label-for="selectedRow.hotel">
                    <template #label>
                      <span class="transitionCopy text-right mr-2 cursor">
                        <b-icon icon="clipboard"
                                v-b-popover.hover.top="'Copier'"
                                @click="copy(selectedRow.hotel)"/>
                      </span>
                      <span class="text-left">Hôtel :</span>
                    </template>
                    <b-form-input id="hotel"
                                  disabled
                                  type="text"
                                  v-model="selectedRow.hotel"
                                  placeholder="Hotel"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2" id="hotelId">
                  <b-form-group label-for="selectedRow.hotel_id">
                    <template #label>
                      <span class="transitionCopy text-right mr-2 cursor">
                        <b-icon icon="clipboard"
                                v-b-popover.hover.top="'Copier'"
                                @click="copy(selectedRow.hotel_id)"/>
                      </span>
                      <span class="text-left">Hôtel ID :</span>
                    </template>
                    <b-form-input id="hotelId"
                                  disabled
                                  type="text"
                                  v-model="selectedRow.hotel_id"
                                  placeholder="Hotel"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" id="firstname">
                  <b-form-group label-for="selectedRow.firstname">
                    <template #label>
                      <span class="transitionCopy text-right mr-2 cursor">
                        <b-icon icon="clipboard"
                                v-b-popover.hover.top="'Copier'"
                                @click="copy(selectedRow.firstName)"/>
                      </span>
                      <span class="text-left">Prénom :</span>
                    </template>
                    <b-form-input id="firstname"
                                  disabled
                                  type="text"
                                  v-model="selectedRow.firstName"
                                  placeholder="Prénom"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" id="name">
                  <b-form-group label-for="selectedRow.lastName">
                    <template #label>
                      <span class="transitionCopy text-right mr-2 cursor">
                        <b-icon icon="clipboard"
                                v-b-popover.hover.top="'Copier'"
                                @click="copy(selectedRow.lastName)"/>
                      </span>
                      <span class="text-left">Nom :</span>
                    </template>
                    <b-form-input id="name"
                                  disabled
                                  type="text"
                                  v-model="selectedRow.lastName"
                                  placeholder="Nom"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" id="email">
                  <b-form-group label-for="selectedRow.email">
                    <template #label>
                      <span class="transitionCopy text-right mr-2 cursor">
                        <b-icon icon="clipboard"
                                v-b-popover.hover.top="'Copier'"
                                @click="copy(selectedRow.email)"/>
                      </span>
                      <span class="text-left">Adresse mail :</span>
                    </template>
                    <b-form-input id="email"
                                  disabled
                                  type="tel"
                                  v-model="selectedRow.email"
                                  placeholder="Adresse mail"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" id="tel">
                  <b-form-group label-for="selectedRow.phone">
                    <template #label>
                      <span class="transitionCopy text-right mr-2 cursor">
                        <b-icon icon="clipboard"
                                v-b-popover.hover.top="'Copier'"
                                @click="copy(selectedRow.phone)"/>
                      </span>
                      <span class="text-left">Téléphone :</span>
                    </template>
                    <b-form-input id="tel"
                                  disabled
                                  type="tel"
                                  v-model="selectedRow.phone"
                                  placeholder="Nom"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12" id="creationDate">
                  <b-form-group label-for="selectedRow.creation_date">
                    <template #label>
                      <span class="transitionCopy text-right mr-2 cursor">
                        <b-icon icon="clipboard"
                                v-b-popover.hover.top="'Copier'"
                                @click="copy(selectedRow.creation_date)"/>
                      </span>
                      <span class="text-left">Date de création :</span>
                    </template>
                    <b-form-input id="creationDate"
                                  disabled
                                  type="text"
                                  v-model="selectedRow.creation_date"
                                  placeholder="Date de création"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" id="signatureDate">
                  <b-form-group v-if="selectedRow.signature_date"
                                label-for="selectedRow.creation_date">
                    <p class="mb-0">
                      <b-icon class="cursor" v-b-popover.hover.top="'Signé'" icon="patch-check" variant="success"
                              font-scale="0.85">:
                      </b-icon>
                      Contrat signé
                    </p>
                    <b-form-input id="signatureDate"
                                  disabled
                                  type="text"
                                  v-model="selectedRow.signature_date"
                                  placeholder="Date de signature"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group v-if="!selectedRow.signature_date"
                                label-for="selectedRow.creation_date"
                                description="Sélectionner la langue du mail, une copie vous sera également envoyée.">
                    <p v-if="!selectedRow.signature_date" class="mb-0"><span>
                      <b-icon class="cursor" v-b-popover.hover.top="'Non signé'" icon="patch-exclamation"
                              variant="danger" font-scale="0.85">:</b-icon>
                        Contrat non signé

                      </span>


                    </p>
                    <b-dropdown variant="btn btn-danger"
                                text="Envoyer un rappel"
                    >
                      <b-dropdown-item-button variant="outline-danger"
                                              size="sm"
                                              @click="sendReminderMail(selectedRow.id, 'fr')"
                      >
                        Français
                      </b-dropdown-item-button>
                      <b-dropdown-item-button variant="outline-danger"
                                              size="sm"
                                              @click="sendReminderMail(selectedRow.id, 'en')"
                      >
                        Anglais
                      </b-dropdown-item-button>
                      <b-dropdown-item-button variant="outline-danger"
                                              size="sm"
                                              @click="sendReminderMail(selectedRow.id, 'it')"
                      >
                        Italien
                      </b-dropdown-item-button>
                      <b-dropdown-item-button variant="outline-danger"
                                              size="sm"
                                              @click="sendReminderMail(selectedRow.id, 'es')"
                      >
                        Espagnol
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </b-form-group>
                </b-col>

                <b-col md="6" id="activationKey">
                  <b-form-group v-if="selectedRow.activation_key"
                                description="Sélectionner la langue du mail, une copie vous sera également envoyée."
                                label-for="selectedRow.creation_date">
                    <p class="mb-0">
                      <b-icon class="cursor" v-b-popover.hover.top="'Non activé'" icon="patch-exclamation"
                              variant="danger" font-scale="0.85">:
                      </b-icon>
                      Compte non activé
                    </p>
                    <b-dropdown text="Envoyer un mail d'activation"
                                variant="btn btn-danger"
                    >
                      <b-dropdown-item-button variant="outline-danger"
                                              size="sm"
                                              @click="sendMailActivation(selectedRow.id, 'fr')"
                      >
                        Français
                      </b-dropdown-item-button>
                      <b-dropdown-item-button variant="outline-danger"
                                              size="sm"
                                              @click="sendMailActivation(selectedRow.id, 'en')"
                      >
                        Anglais
                      </b-dropdown-item-button>
                      <b-dropdown-item-button variant="outline-danger"
                                              size="sm"
                                              @click="sendMailActivation(selectedRow.id, 'it')"
                      >
                        Italien
                      </b-dropdown-item-button>
                      <b-dropdown-item-button variant="outline-danger"
                                              size="sm"
                                              @click="sendMailActivation(selectedRow.id, 'es')"
                      >
                        Espagnol
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </b-form-group>
                </b-col>

                <b-col md="6" id="google-analaytic">
                  <b-form-group label-for="selectedRow.ga">
                    <template #label>
                      <span class="text-left">Google Analytics :</span>
                    </template>
                    <b-form-input id="google-analaytic"
                                  v-if="selectedRow.ga == 2"
                                  disabled
                                  type="text"
                                  value="Oui"
                    >
                    </b-form-input>
                    <b-form-input id="google-analaytic"
                                  v-if="selectedRow.ga == 1"
                                  disabled
                                  type="text"
                                  value="Non"
                    ></b-form-input>
                    <b-form-input id="google-analaytic"
                                  v-if="selectedRow.ga != 1 && selectedRow.ga != 2"
                                  disabled
                                  type="text"
                                  value="Non renseigné !"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6" id="google-search">
                  <b-form-group label-for="selectedRow.gsc">
                    <template #label>
                      <span class="text-left">Google Search :</span>
                    </template>
                    <b-form-input id="google-search"
                                  v-if="selectedRow.gsc == 2"
                                  disabled
                                  type="text"
                                  value="Oui"
                    >
                    </b-form-input>
                    <b-form-input id="google-search"
                                  v-if="selectedRow.gsc == 1"
                                  disabled
                                  type="text"
                                  value="Non"
                    ></b-form-input>
                    <b-form-input id="google-search"
                                  v-if="selectedRow.gsc != 1 && selectedRow.gsc != 2"
                                  disabled
                                  type="text"
                                  value="Non renseigné !"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="12" id="sellsy">
                  <b-form-group label-for="selectedRow.hotel">
                    <template #label>
                      <span class="text-left">Synchroniser avec Sellsy :</span>
                    </template>
                    <b-button @click="uploadSellsy(selectedRow)" variant="btn btn-primary">
                      <b-icon icon="cloud-fog2"></b-icon>
                      Synchroniser
                    </b-button>
                  </b-form-group>
                </b-col>
                <b-col md="12" v-if="selectedRow.signature_date">
                  <b-form-group label="Gestion du TAG :">
                    <b-row cols="4">
                      <b-col md="2">
                        <b-button @click="activateTAG(selectedRow.hotel_id)" variant="btn btn-success">
                          <b-icon icon="patch-plus"></b-icon>
                          Activer
                        </b-button>
                      </b-col>
                      <b-col md="2">
                        <b-button @click="deactivateTAG(selectedRow.hotel_id)" variant="btn btn-danger">
                          <b-icon icon="patch-minus"></b-icon>
                          Désactiver
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-tab>

          <b-tab title="Documents">
            <b-row style="margin-top: 20px">
              <b-col md="12" class="text-center">

                <b-button v-if="selectedRow.file_id || selectedRow.manual_contract" class="btn btn-success"
                          @click="selectedRow.file_id ? download(selectedRow.file_id) : downloadManual(selectedRow.manual_contract)">
                  <b-icon icon="download" font-scale="0.85"></b-icon>
                  Télécharger le contrat
                </b-button>

                <b-button v-else class="btn btn-danger"
                          @click="showModal(selectedRow, 'modal-customer')">
                  <b-icon icon="upload" font-scale="0.85" style="margin-right: 4px"></b-icon>
                  Ajouter un contrat
                </b-button>
              </b-col>
            </b-row>
            <b-row style="margin-top: 20px">
              <b-col md="12" class="text-center">
                <b-button v-if="selectedRow.rib" @click="downloadRIB(selectedRow.rib)" class="btn btn-success">
                  <b-icon icon="download" font-scale="0.85"></b-icon>
                  Télécharger le RIB
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab :title="getTitleComments">
            <b-row style="margin-top: 20px">
              <b-col md="12" id="comment">
                <b-form-group
                    label="Ajouter un commentaire:"
                >
                  <b-form-textarea id="comment"
                                   v-model="newComment"
                                   placeholder="Aucun commentaire"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="text-center">
                <b-button class="m-3 btn btn-success" type="submit" :disabled="sending"
                          @click="saveComment()">
                  <b-icon icon="save" font-scale="0.9"></b-icon>
                  Enregistrer
                </b-button>
              </b-col>
            </b-row>
            <b-list-group flush>
              <b-list-group-item class="item comment"
                                 v-for="item in comments"
                                 :key="item.id"
              >
                <b-row>
                  <b-col md="12" class="text-left">
                    <span class="text-left">{{ item.user }}, le {{ item.day }} à {{ item.hour }}</span>
                    <b-form-textarea disabled placeholder="Pas de données" v-model="item.text"/>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
    <b-modal id="modal-add" hide-footer size="lg">
      <template #modal-title>
        <b-col md="12">
          Ajouter un élément
        </b-col>
      </template>
      <div class="d-block">
        <b-form @submit="saveElement">
          <b-row>
            <b-col md="6">
              <b-form-group
                  id="input-group-1"
                  label="Prénom :"
                  label-for="firstname"
              >
                <b-form-input type="text" id="firstname" required v-model="firstName" placeholder="Jean"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  id="input-group-2"
                  label="Nom :"
                  label-for="lastname"
              >
                <b-form-input type="text" id="lastname" required v-model="lastName" placeholder="Durand"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  id="input-group-3"
                  label="Adresse mail :"
                  label-for="email"
              >
                <b-form-input type="email" id="email" required v-model="email"
                              placeholder="jean.durand@gmail.com"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  id="input-group-4"
                  label="Téléphone :"
                  label-for="phone"
              >
                <b-form-input type="text" id="phone" required v-model="phone"
                              placeholder="+33 6 01 02 03 04"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  id="input-group-5"
                  label="Site internet :"
                  label-for="website"
              >
                <b-form-input type="text" id="website" required v-model="website"
                              placeholder="https://www.mon-hotel.fr/"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  id="input-group-6"
                  label="Hotel ID :"
                  label-for="hotelID"
              >
                <b-form-input type="text" id="hotelID" required v-model="hotelID"
                              placeholder="2fdb7515-2c8c-4fac-b686-4a8482ebed24"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  id="input-group-6"
                  label="Relevé d'identité bancaire :"
                  label-for="rib"
              >
                <b-form-file
                    v-model="rib"
                    :state="Boolean(rib)"
                    placeholder="Choisissez un fichier ou glissez le ici..."
                    drop-placeholder="Glisser ici..."
                    accept="image/*, .pdf"
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col md="12" class="text-center">
              <b-button :disabled="sending" type="submit" variant="primary">Ajouter</b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ESign',
  data: () => ({
    name: "Signatures",
    showInfoAlert: true,
    manualContract: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    hotelID: null,
    website: null,
    rib: null,
    timer: null,
    currentPage: 1,
    loading: true,
    sending: false,
    selectedRow: {
      id: null,
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      hotel: null,
      hotel_id: null,
      signed: null,
      signature_waiver: null,
      creation_date: null,
      signature_date: null,
      file_id: null,
      manual_contract: null,
      rib: null,
      created: null,
      sellsy: null,
      ga: null,
      gsc: null,
      activation_key: null,
    },
    fieldsESign: [
      {key: 'firstName', label: 'Prénom', sortable: true},
      {key: 'lastName', label: 'Nom', sortable: true},
      {key: 'phone', label: 'Téléphone'},
      {key: 'email', label: 'Mail', sortable: true},
      {key: 'score', label: 'Score', sortable: false},
      {key: 'hotel', label: 'Hotel', sortable: true},
      {key: 'creation_date', label: 'Créé le', sortable: true},
      {key: 'signature_date', label: 'Signé le', sortable: true},
      {key: 'actions', label: '', thStyle: {width: "10px"}, tdStyle: {width: "10px"}},
    ],
    totalRows: 1,
    perPage: 20,
    filter: null,
    filterOn: [],
    filteredItems: [],
    esigns: [],
    newComment: '',
    comments: [],
    countSignatureWaiver: 0,
    countSigned: 0,
    countWaiting: 0,
    typeEstablishment: {
      '1': 'Hôtel',
      '2': 'Chambre & Maison d\'hôtes',
      '3': 'Gîte',
      '4': 'Camping',
      '5': 'Location saisonnière',
      '6': 'Autre',
    },
    establishmentRooms: {
      '1': 'Moins de 10',
      '2': 'Entre 10 et 20',
      '3': 'Entre 20 et 30',
      '4': 'Entre 30 et 40',
      '5': 'Plus de 40',
    },
    establishmentPrices: {
      '1': 'Moins de 50 €',
      '2': 'Entre 50 € et 100 €',
      '3': 'Entre 100 € et 150 €',
      '4': 'Entre 150 € et 200 €',
      '5': 'Plus de 200 €',
    },
  }),
  async mounted() {
    this.loading = true;
    this.refreshesigns();
    this.timer = setInterval(this.refreshesigns, 5000);
    this.loading = false;
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  components: {},
  methods: {
    getVariant(type, rooms, prices) {
      const score = this.getScore(type, rooms, prices)
      if (score >= 0 && score <= 3)
        return 'dark'
      else if (score > 3 && score <= 6)
        return 'light'
      else if (score > 6 && score <= 9)
        return 'warning'
      else if (score > 9)
        return 'danger'
    },
    getScore(type, rooms, prices) {
      let score = 0;

      switch (type) {
        case 1:
          score += 5
          break
        case 2:
          score += 4
          break
        case 3:
          score += 2
          break
        case 4:
          score += 3
          break
        case 5:
          score += 1
          break
        default:
          break
      }

      switch (rooms) {
        case 1:
          score += 1
          break
        case 2:
          score += 2
          break
        case 3:
          score += 3
          break
        case 4:
          score += 4
          break
        case 5:
          score +=5
          break
        default:
          break
      }

      switch (prices) {
        case 1:
          score += 1
          break
        case 2:
          score += 2
          break
        case 3:
          score += 3
          break
        case 4:
          score += 4
          break
        case 5:
          score += 5
          break
        default:
          break
      }

      return score;
    },
    isRowSigned(item, type) {
      if (item && type === 'row') {
        if (item.signed) {
          if (item.created) {
            item._rowVariant = 'success'
          } else {
            item._rowVariant = 'danger'
          }
        } else if (!item.signed && !item.signature_waiver) {
          item._rowVariant = 'warning'
        } else if (item.signature_waiver) {
          item._rowVariant = 'danger'
        }
      }
    },
    getStepSellsy(signed) {
      return signed ? '575270' : '374152'
    },
    getCompanyType(signed) {
      return signed ? 'client' : 'prospect'
    },
    getLinkedType(signed) {
      return signed ? 'third' : 'prospect'
    },
    copy(text) {
      navigator.clipboard.writeText(text)
      this.$bvToast.toast('Copie réussie.', {
        title: null,
        noCloseButton: true,
        variant: 'success',
        solid: true
      })
    },
    async showModal(row, modal = 'modal-detail') {
      this.selectedRow = row
      if (modal === 'modal-detail')
        await this.getComments();
      this.$bvModal.show(modal)
    },
    async activateTAG(hotel_id) {
      try {
        await this.$http.put(`/e-sign/tag/${hotel_id}/activate`)
        this.$bvToast.toast(`TAG de l'hôtel activé avec succès !`, {
          title: null,
          noCloseButton: true,
          variant: 'success',
          solid: true
        })
      } catch (error) {
        this.$bvToast.toast(`Impossible d'activer le TAG pour cet hôtel!`, {
          title: null,
          noCloseButton: true,
          variant: 'danger',
          solid: true
        })
      }
    },
    async deactivateTAG(hotel_id) {
      try {
        await this.$http.put(`/e-sign/tag/${hotel_id}/deactivate`)
        this.$bvToast.toast(`TAG de l'hôtel désactivé avec succès !`, {
          title: null,
          noCloseButton: true,
          variant: 'success',
          solid: true
        })
      } catch (error) {
        this.$bvToast.toast(`Impossible de désactiver le TAG pour cet hôtel!`, {
          title: null,
          noCloseButton: true,
          variant: 'danger',
          solid: true
        })
      }
    },
    async uploadSellsy(row) {
      try {
        this.$bvToast.toast('Synchronisation Sellsy en cours, la fiche devrait apparaître dans quelques minutes...', {
          title: null,
          noCloseButton: true,
          variant: 'warning',
          solid: true
        })
        await this.$http.post('/zapier', {
          sellsy: 'https://hooks.zapier.com/hooks/catch/2978271/bw01xf4/',
          website: row.hotel,
          company: new URL(row.hotel.includes('https://') ? row.hotel : 'https://' + row.hotel).hostname,
          firstName: row.firstName,
          lastName: row.lastName,
          email: row.email,
          phone: row.phone,
          hotelId: row.hotel_id,
          step: this.getStepSellsy(row.signed),
          companyType: this.getCompanyType(row.signed),
          linkedType: this.getLinkedType(row.signed),
        })
      } catch (error) {
        this.$bvToast.toast(`Erreur de synchronisation Sellsy: ${error.message}!`, {
          title: null,
          noCloseButton: true,
          variant: 'danger',
          solid: true
        })
      }
    },
    async restartCreationProcess(id) {
      this.loading = true
      try {
        this.$bvToast.toast('Création du client en cours, veuillez patienter...', {
          title: null,
          noCloseButton: true,
          variant: 'warning',
          solid: true
        })
        await this.$http.post(`/e-sign`, {
          id
        })
        this.$bvToast.toast('Client créé avec succès !', {
          title: null,
          noCloseButton: true,
          variant: 'success',
          solid: true
        })
      } catch (error) {
        this.$bvToast.toast(error.response.data.content, {
          title: null,
          noCloseButton: true,
          variant: error.response.data.status === 'error' ? 'danger' : 'success',
          solid: true
        })
      }
      this.loading = false
    },

    async saveContract(event) {
      event.preventDefault()
      if (this.manualContract) {
        let reURL = new RegExp('^(?:http(s):\\/\\/)[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$')
        let reDROP = new RegExp('^https:\\/\\/drop.datavault.tech\\/[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$')
        if (reURL.test(this.manualContract)) {
          if (reDROP.test(this.manualContract)) {
            try {
              this.$bvModal.hide('modal-customer')
              this.loading = true
              this.$bvToast.toast('Ajout du contrat et création du client en cours, veuillez patienter...', {
                title: null,
                noCloseButton: true,
                variant: 'warning',
                solid: true
              })
              await this.$http.post(`/e-sign`, {
                id: this.selectedRow.id,
                manualContract: this.manualContract
              })
              this.$bvToast.toast('Contrat ajouté et client créé avec succès !', {
                title: null,
                noCloseButton: true,
                variant: 'success',
                solid: true
              })
            } catch (error) {
              this.$bvToast.toast(error.response.data.content, {
                title: null,
                noCloseButton: true,
                variant: error.response.data.status === 'error' ? 'danger' : 'success',
                solid: true
              })
            }
          } else {
            this.$bvToast.toast('Vous ne pouvez pas utiliser de service d\'hébergement tiers.', {
              title: null,
              noCloseButton: true,
              variant: 'danger',
              solid: true
            })
          }
        } else {
          this.$bvToast.toast('Vous devez indiquer un lien valide.', {
            title: null,
            noCloseButton: true,
            variant: 'danger',
            solid: true
          })
        }
      } else {
        this.$bvToast.toast('Vous devez indiquer un lien valide.', {
          title: null,
          noCloseButton: true,
          variant: 'danger',
          solid: true
        })
      }
      this.loading = false
    },

    download(file) {
      window.open(`https://api.yousign.com${file}/download?alt=media&access_token=2de3cb7e100c1feb40d8077a9e74ed73`)
    },
    downloadManual(url) {
      window.open(url)
    },

    downloadContract(lang) {
      window.open(`https://register-api.findthepax.fr/download/contract/${lang}`)
    },

    downloadRIB(file) {
      window.open(`https://register-api.findthepax.fr/download/${file}`)
    },
    onFiltered(filteredItems) {
      this.filteredItems = filteredItems
      this.countSigned = this.filteredItems.filter(elem => elem.signed).length;
      this.countWaiting = this.filteredItems.filter(elem => !elem.signed && !elem.signature_waiver).length;
      this.countSignatureWaiver = this.filteredItems.filter(elem => elem.signature_waiver).length;
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    refreshesigns() {
      this.$http.get(`/e-sign`).then((response) => {
        this.esigns = response ? response.data.content : null;
        this.countSigned = this.esigns.filter((esign) => esign.signed).length;
        this.countWaiting = this.esigns.filter((esign) => !esign.signed && !esign.signature_waiver).length;
        this.countSignatureWaiver = this.esigns.filter((esign) => esign.signature_waiver).length;
        this.totalRows = this.esigns.length;
        this.filteredItems = this.esigns;
      });

    },
    getComments() {
      this.$http.get(`/e-sign/${this.selectedRow.id}/comment`).then((response) => {
        this.comments = response ? response.data.content : null
      })
    },
    async saveComment() {
      this.sending = true;
      try {
        await this.$http.post(`/e-sign/${this.selectedRow.id}/comment`, {
          text: this.newComment
        })
        this.$bvToast.toast('Commentaire enregistré !', {
          title: 'Succès',
          noCloseButton: true,
          variant: 'success',
          solid: true
        });
        this.newComment = null;
        await this.getComments();
      } catch (error) {
        this.$bvToast.toast(error.response.data.content, {
          title: 'Erreur',
          noCloseButton: true,
          variant: error.response.data.status === 'error' ? 'danger' : 'success',
          solid: true
        })
      }
      this.sending = false;
    },

    async saveElement(event) {
      try {
        event.preventDefault()
        if (this.rib && this.firstName && this.lastName && this.phone && this.email && this.website) {
          this.$bvModal.hide('modal-add')
          this.loading = true
          this.sending = true
          let formData = new FormData()
          formData.append('rib', this.rib)
          formData.append('firstname', this.firstName)
          formData.append('lastname', this.lastName)
          formData.append('phone', this.phone)
          formData.append('email', this.email)
          formData.append('hotelID', this.hotelID)
          formData.append('website', this.website)

          this.rib = null;
          this.firstName = null;
          this.lastName = null;
          this.phone = null;
          this.email = null;
          this.hotelID = null;
          this.website = null;

          const response = await this.$http.post('/e-sign/new', formData, {
            data: {
              'Content-Type': 'multipart/form-data'
            }
          })
          setTimeout(() => {
            this.loading = false
            this.$bvToast.toast(response.data.content, {
              title: null,
              noCloseButton: true,
              variant: response.data.status === 'error' ? 'danger' : 'success',
              solid: true
            })
          }, 2500)
        } else {
          this.$bvToast.toast('Champs manquants !', {
            title: null,
            noCloseButton: true,
            variant: 'danger',
            solid: true
          })
        }
      } catch (error) {
        setTimeout(() => {
          this.loading = false
          if (error.response.data.errors && error.response.data.errors.length >= 1) {
            error.response.data.errors.forEach((error) => {
              this.$bvToast.toast(`Paramètre ${error.param} : ${error.msg} (${error.value})`, {
                title: null,
                noCloseButton: true,
                variant: 'danger',
                solid: true
              })
            })
          } else {
            this.$bvToast.toast(error.response.data.content, {
              title: null,
              noCloseButton: true,
              variant: error.response.data.status === 'error' ? 'danger' : 'success',
              solid: true
            })
          }
        }, 2500)
      }
      this.sending = false
    },

    async updateRow() {
      try {
        this.sending = true
        const formData = new FormData();
        formData.append('id', this.selectedRow.id);

        await this.$http.put(`/e-sign`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        //TODO @MB: TO REUSE
        this.$bvToast.toast('Modification enregistrée !', {
          title: null,
          noCloseButton: true,
          variant: 'success',
          solid: true
        });
      } catch (error) {
        this.$bvToast.toast(error.response.data.content, {
          title: null,
          noCloseButton: true,
          variant: error.response.data.status === 'error' ? 'danger' : 'success',
          solid: true
        })
      }
      this.sending = false
    },

    async signatureWaiverRow(row) {
      try {
        await this.$http.put(`/e-sign/${row.id}/waiver`, {
          waiver: !row.signature_waiver
        });
        this.$bvToast.toast('Modification enregistrée !', {
          title: null,
          noCloseButton: true,
          variant: 'success',
          solid: true
        });
        row.signature_waiver = !row.signature_waiver;
        this.refreshesigns();
      } catch (e) {
        this.$bvToast.toast(`Erreur l'abandon de signature n'a pas fonctionné`, {
          title: null,
          noCloseButton: true,
          variant: 'danger',
          solid: true
        })
      }
    },

    sendReminderMail(idClient, lang = 'fr') {
      this.$http.post(`/e-sign/signature-reminder/`, {
        id: idClient,
        lang,
        token: localStorage.auth
      }).then((response) => {
        this.$bvToast.toast(response.data.content, {
          title: null,
          noCloseButton: true,
          variant: response.data.status === 'error' ? 'danger' : 'success',
          solid: true
        });
      }).catch((error) => {
        this.$bvToast.toast(error.response.data.content, {
          title: null,
          noCloseButton: true,
          variant: error.response.data.status === 'error' ? 'danger' : 'success',
          solid: true
        })
      });
    },
    sendMailActivation(idClient, lang = 'fr') {
      this.$http.post(`/e-sign/activation-reminder`, {
        id: idClient,
        lang,
        token: localStorage.auth
      }).then((response) => {
        this.$bvToast.toast(response.data.content, {
          title: null,
          noCloseButton: true,
          variant: response.data.status === 'error' ? 'danger' : 'success',
          solid: true
        })
      }).catch((error) => {
        this.$bvToast.toast(error.response.data.content, {
          title: null,
          noCloseButton: true,
          variant: error.response.data.status === 'error' ? 'danger' : 'success',
          solid: true
        })
      });
    },
  },

  computed: {
    getTitleComments() {
      return `Commentaires (${this.comments.length})`
    }
  }
}
</script>

<style lang="scss" scoped>

.transitionCopy :hover {
  transform: rotate3d(0, 1, 0, 180deg);
  transition: transform 0.5s ease;
}

.cursor {
  cursor: pointer !important;
}

.cursor:hover {
  cursor: pointer !important;
}

</style>
