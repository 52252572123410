<template>
  <div class="suivi_brithotel">
    <b-row>
      <b-col>
        <h3>Horoscope Me iOS</h3>
      </b-col>
    </b-row>
    <b-row class="tools">
      <b-col>
        <PeriodSelect @Date="date => { this.date = date; }"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="1" v-for="[currency, amount] in Object.entries(turnover)" v-bind:key="currency">
        <b-card
            border-variant="primary"
            :header="currency"
            header-bg-variant="primary"
            header-text-variant="white"
            align="center"
        >
          <b-card-text class="text-black-50">{{ amount }}</b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2">
        <div>
          <b-jumbotron bg-variant="primary" text-variant="white" border-variant="dark">
            <template #header>{{ downloads }}</template>

            <template #lead>
              Téléchargement(s)
            </template>
          </b-jumbotron>
        </div>
      </b-col>
      <b-col md="2">
        <div>
          <b-jumbotron bg-variant="success" text-variant="white" border-variant="dark">
            <template #header>{{ purchases }}</template>

            <template #lead>
              Achat(s)
            </template>
          </b-jumbotron>
        </div>
      </b-col>
      <b-col md="2">
        <div>
          <b-jumbotron bg-variant="info" text-variant="white" border-variant="dark">
            <template #header>{{ updates }}</template>

            <template #lead>
              Mise(s) à jour
            </template>
          </b-jumbotron>
        </div>
      </b-col>
      <b-col md="2">
        <div>
          <b-jumbotron bg-variant="danger" text-variant="white" border-variant="dark">
            <template #header>{{ refunds }}</template>

            <template #lead>
              Remboursement(s)
            </template>
          </b-jumbotron>
        </div>
      </b-col>
      <b-col md="2">
        <div>
          <b-jumbotron bg-variant="primary" text-variant="white" border-variant="dark">
            <template #header>{{ reDownloads }}</template>

            <template #lead>
              Re téléchargement(s)
            </template>
          </b-jumbotron>
        </div>
      </b-col>
      <b-col md="2">
        <div>
          <b-jumbotron bg-variant="info" text-variant="white" border-variant="dark">
            <template #header>{{ trials }}</template>

            <template #lead>
              Période(s) d'essai
            </template>
          </b-jumbotron>
        </div>
      </b-col>
    </b-row>
    <b-row class="charts">
      <b-col>
        <b-row>
          <b-col>
            <EvolutionPerDay :date="date"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PeriodSelect from "@/components/PeriodSelectSynchronext.vue";
import EvolutionPerDay from '@/components/charts/synchronext/evolutionPerDay.vue';

import dayjs from "dayjs";

export default {
  name: 'Brithotel',
  components: {
    PeriodSelect,
    EvolutionPerDay,
  },
  data: () => ({
    name: "Suivi",
    loading: true,
    timer: null,
    date: {
      startDate: null,
      endDate: null,
    },
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    downloads: 0,
    updates: 0,
    reDownloads: 0,
    purchases: 0,
    refunds: 0,
    trials: 0,
    turnover: 0
  }),
  async mounted() {
    this.date.startDate = dayjs().subtract(2, 'days').subtract(1, 'week').format('YYYY-MM-DD');
    this.date.endDate = dayjs().subtract(2, 'days').format('YYYY-MM-DD');
  },
  methods: {
    async getData() {
      const response = await this.$http.get(`/synchronext/${this.date.startDate}/${this.date.endDate}`);
      if (response && response.data && response.data.content && response.data.content.total) {
        const {downloads, updates, reDownloads, purchases, refunds, trials, turnover} = response.data.content.total;
        this.downloads = downloads;
        this.updates = updates;
        this.reDownloads = reDownloads;
        this.purchases = purchases;
        this.refunds = refunds;
        this.trials = trials;
        this.turnover = turnover;
      }
    },
  },
  watch: {
    date: {
      handler: async function () {
        if (this.date && this.date.startDate && this.date.endDate)
          await this.getData();
      },
      deep: true
    },
  },
  computed: {
    isLoading: function () {
      return this.$store.getters.isLoading;
    }
  },
}
</script>

<style lang="scss">
.suivi_brithotel {
  padding: 0 3rem;

  > * > * {
    margin-bottom: 2rem;
  }
}

.c_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

.switchButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tools {
  align-items: center;
}

.stats {
  display: flex;
  gap: 4rem;

  span {
    font-weight: bold;

    &.negative {
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      background-color: #ff5252;
      color: white;
    }

    &.positive {
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      background-color: #4caf50;
      color: white;
    }
  }
}

.stats-groups {
  margin-left: 15px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.charts {
  > * > * {
    margin-bottom: 3rem;
  }
}

</style>
