<template>
  <div class="sidebar-section-wrapper" v-if="showSection()">
    <b-button class="sidebar-btn" v-b-toggle="'collapse-' + routeConfig.title" squared block @click="toggleCollapsed">
      <div style="display: flex; align-items: center; height: 100%; justify-content: space-between">
        <b-icon :icon="routeConfig.icon" style="margin-right: 10px"></b-icon>
        {{ routeConfig.title }}
      </div>
      <b-icon icon="caret-right-fill" v-if="collapsed"/>
      <b-icon icon="caret-down-fill" v-else/>
    </b-button>
    <b-collapse visible :id="'collapse-' + routeConfig.title">
      <b-list-group flush>
        <b-list-group-item class="item"
                           v-for="route in filteredRoutes"
                           :key="route.name"
                           @click="redirect(route.to)"
                           :active="route.to === $route.path"
                           :active-class="'active'"
        >
          {{ route.name }}
        </b-list-group-item>
      </b-list-group>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "SidebarSection",
  data() {
    return {
      collapsed: false,
    }
  },
  props: {
    routeConfig: {
      type: Object,
      required: true,
    },
    admin: {
      type: Boolean,
      required: true,
    },
    external: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    filteredRoutes() {
      return this.routeConfig.routes.filter((route) => {
        if (this.external) {
          return route.name === "Suivi";
        }
        return true;
      });
    },
  },
  methods: {
    showSection() {
      if (this.external) {
        return this.routeConfig.external;
      } else if (this.routeConfig.needAdmin) {
        return this.admin;
      } else {
        return !this.external;
      }
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    redirect (to) {
      if (this.$route.path !== to)
        this.$router.push(to);
    }
  }
}
</script>

<style scoped>
.active {
  background-color: #4d7bc7 !important;
  border: none;
  color: white;
}

.item {
  background-color: #424850;
  color: white;
  cursor: pointer;
}

.sidebar-btn {
  text-align:left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #343a40;
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}
</style>
