const logTypes = {
    "ACCOUNT": "Compte",
    "AD": "Annonce",
    "AD GROUP": "Groupe d'annonces",
    "ADGROUP": "Groupe d'annonces",
    "AD_GROUP": "Groupe d'annonces",
    "AD_GROUP_AD": "Annonce de groupe d'annonces",
    "AD_GROUP_BID_MODIFIER": "Modificateur d'enchère du groupe d'annonces",
    "AD_GROUP_CRITERION": "Critère du groupe d'annonces",
    "ASSET": "Élément",
    "CALLOUT EXTENSION": "Extension d'appel",
    "CAMPAIGN": "Campagne",
    "CAMPAIGN_BUDGET": "Budget de campagne",
    "CAMPAIGN_CRITERION": "Critère de campagne",
    "CAMPAIGN_GROUP": "Groupe de campagnes",
    "CUSTOMER_ASSET": "Élément du client",
    "KEYWORD": "Mot-clé",
    "LANGUAGE": "Langue",
    "REMARKETING LIST TARGETING ASSOCIATION": "Association de ciblage de liste de remarketing",
    "RESPONSIVE SEARCH AD": "Annonce de recherche responsive",
    "SITELINK EXTENSION": "Extension de liens annexes",
    "STRUCTURED SNIPPET EXTENSION": "Extension d'extrait structuré",
    "LINE-ITEM": "Line item",
    "LINE_ITEM": "Line item",
    "CAMPAIGN_ASSET": "Élément de campagne",
    "AUTO TARGET": "Ciblage automatique",
    "LOCATION EXTENSION": "Extension d'adresse",
    "LABEL": "Étiquette",
    "PRODUCT GROUP": "Groupe de produits",
    "PROFILE": "Profil",
    "EXCLUSION": "Exclusion",
    "NEGATIVE KEYWORD LIST": "Liste de mots-clés négatifs",
    "IN-MARKET AUDIENCE TARGETING ASSOCIATION": "Association de ciblage d'audience en marché",
}

const logTypesText = {
    "ACCOUNT": "le compte",
    "AD": "l'annonce",
    "AD GROUP": "le groupe d'annonces",
    "ADGROUP": "le groupe d'annonces",
    "AD_GROUP": "le groupe d'annonces",
    "AD_GROUP_AD": "l'annonce de groupe d'annonces",
    "AD_GROUP_BID_MODIFIER": "le modificateur d'enchère du groupe d'annonces",
    "AD_GROUP_CRITERION": "le critère du groupe d'annonces",
    "ASSET": "l'élément",
    "CALLOUT EXTENSION": "l'extension d'appel",
    "CAMPAIGN": "la campagne",
    "CAMPAIGN_BUDGET": "le budget de campagne",
    "CAMPAIGN_CRITERION": "le critère de campagne",
    "CAMPAIGN_GROUP": "le groupe de campagnes",
    "CUSTOMER_ASSET": "l'élément du client",
    "KEYWORD": "le mot-clé",
    "LANGUAGE": "la langue",
    "REMARKETING LIST TARGETING ASSOCIATION": "l'association de ciblage de liste de remarketing",
    "RESPONSIVE SEARCH AD": "l'annonce de recherche responsive",
    "SITELINK EXTENSION": "l'extension de sitelink",
    "STRUCTURED SNIPPET EXTENSION": "l'extension de snippet structuré",
    "LINE-ITEM": "le line item",
    "LINE_ITEM": "le line item",
    "LINE ITEM": "le line item",
    "CAMPAIGN_ASSET": "l'élément de campagne",
    "AUTO TARGET": "le ciblage automatique",
    "LOCATION EXTENSION": "l'extension d'adresse",
    "LABEL": "l'étiquette",
    "PRODUCT GROUP": "le groupe de produits",
    "PROFILE": "le profil",
    "EXCLUSION": "l'exclusion",
    "NEGATIVE KEYWORD LIST": "la liste de mots-clés négatifs",
    "IN-MARKET AUDIENCE TARGETING ASSOCIATION": "l'association de ciblage d'audience en marché",
}

module.exports = { logTypes, logTypesText };
