<template>
  <b-row>
    <b-col cols="6">
        <b-form-group class="start-period"
                      id="input-group-start-date"
                      label="Date de début :"
                      label-for="input-start-date">
            <b-form-datepicker id="input-start-date" placeholder="Choisir une date" v-model="startDate" :max="today" @input="emitNewDate()"/>
        </b-form-group>
    </b-col>
    <b-col cols="6">
        <b-form-group class="end-period"
                      id="input-group-end-date"
                      label="Date de fin :"
                      label-for="input-end-date">
            <b-form-datepicker id="input-end-date" placeholder="Choisir une date" v-model="endDate" :max="today" @input="emitNewDate()"/>
        </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "PeriodSelect",
  props: {
    empty: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    startDate: null,
    endDate: null,
    today: dayjs().format('YYYY-MM-DD')
  }),
  mounted() {
    if (!this.empty) {
      this.startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
      this.endDate = dayjs().format('YYYY-MM-DD')
    }
    this.$emit('Date', {startDate: this.startDate, endDate: this.endDate})
  },
  methods: {
    emitNewDate() {
      this.$emit('Date', {startDate: this.startDate, endDate: this.endDate})
    }
  }
}
</script>
