<template>
  <apexchart class="apexchart"
             height="350"
             :options="this.chartOption"
             :series="chartSerie"/>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "distributionOfCommissionsPerDay",
  data: () => ({
    request: '/amenitiz/per-day',
    chartOption: {
      chart: {
        type: 'line',
        height: 350,
        width: '98%',
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: 'Répartition des commissions par jour',
        align: 'left'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'dd-MM-yyyy'
        },
        categories: []
      },
      series: [
        {
          name: 'Commission Amenitiz',
          type: 'line',
          data: []
        },
        {
          name: 'Commission 360&1',
          type: 'line',
          data: []
        },
        {
          name: 'Dépenses',
          type: 'line',
          data: []
        }
      ],
      legend: {
        position: 'left',
        offsetY: 100,
        fontFamily: 'Helvetica, Arial',
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        opacity: 1
      },
    },
    chartSerie: [],
  }),

  props: {
    switchParent: {
      type: Boolean,
      default: false
    },
    specificClients: {
      type: Array,
      default: () => []
    },
    maxClients: {
      type: Number,
      default: 0
    },
    date: {
      type: Object,
      default: () => ({
        startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      })
    }
  },

  async mounted() {
    await this.getDistributionOfCommissions();
  },

  computed: {
    checked() {
      return this.switchParent;
    }
  },

  watch: {
    maxClients: async function (val) {
      this.maxClients = val;
      await this.getDistributionOfCommissions();
    },
    specificClients: async function (val) {
      this.specificClients = val;
      await this.getDistributionOfCommissions();
    },
    checked: async function () {
      await this.handleCheckValue();
    },

    date: {
      handler: async function () {
        await this.getDistributionOfCommissions();
      },
      deep: true
    }
  },

  methods: {
    async handleCheckValue(shouldDistributionOfCommissionsBeGenerated = true) {
      if (this.checked) {
        this.chartOption.title.text = 'Répartition des commissions par semaine';
        this.request = '/amenitiz/per-week';
      } else {
        this.chartOption.title.text = 'Répartition des commissions par jour';
        this.request = '/amenitiz/per-day';
      }
      this.chartOption = {
        ...this.chartOption
      }
      if (shouldDistributionOfCommissionsBeGenerated)
        await this.getDistributionOfCommissions();
    },

    async getDistributionOfCommissions() {
      await this.handleCheckValue(false);
      const response = await this.$http.post(this.request, {
        isChart: true,
        startDate: this.date.startDate,
        endDate: this.date.endDate,
        specificClients: this.specificClients
      });
      if (response && response.data && response.data.content && this.specificClients.length > 0) {
        this.chartOption.series[0].data = response.data.content.commissionsAmz || [];
        this.chartOption.series[1].data = response.data.content.commissions360 || [];
        if (this.maxClients > 0 && this.specificClients.length !== this.maxClients) {
          this.chartOption.series.splice(2, 1);
        } else {
          if (this.chartOption.series.length === 2) {
            this.chartOption.series.push({
              name: 'Dépenses',
              type: 'line',
            });
            this.chartOption = {
              ...this.chartOption
            };
          }
        }
        if (this.chartOption.series.length === 3)
          this.chartOption.series[2].data = response.data.content.costs || [];

        if (response.data.content.costs && response.data.content.costs.length <= 0)
          this.chartOption.series = this.chartOption.series.slice(0, 2)

        this.chartOption.xaxis.categories = response.data.content.xaxis || [];
        this.chartOption = {
          ...this.chartOption
        };
      } else {
        this.chartOption.series[0].data = [];
        this.chartOption.series[1].data = [];
        this.chartOption.series[2].data = [];
        this.chartOption.xaxis.categories = [];
        this.chartOption = {
          ...this.chartOption
        };
      }
    }
  },

}
</script>

<style scoped>

</style>
