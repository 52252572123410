<script setup>
import {ref, onMounted, computed} from "vue";
import {useApi} from '@/composables/useApi';
import {useModal} from '@/composables/useModal';
import {useToast} from '@/composables/useToast';

import dayjs from "dayjs";

const bvModal = useModal();
const bvToast = useToast();
const api = useApi();

let selectedUtm = ref(null);
let selectedName = ref(null);
const searchedReferrer = ref(null);
const clients = ref([]);
const client = ref(null);
const perPage = ref(10);
const currentPage = ref(1);
const report = ref([]);
const reportFiltered = ref([]);
const date = ref({
  startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
  endDate: dayjs().format("YYYY-MM-DD"),
});

const utmTypes = ref([
  {selected: true, text: 'utm_source', value: 1},
  {text: 'utm_medium', value: 2},
  {text: 'utm_campaign', value: 3},
  {text: 'utm_term', value: 4},
  {text: 'utm_content', value: 5}
]);
const utmType = ref(1);
const fields = ref([
  {key: "create", label: "", sortable: false},
  {key: "nb", label: "Occurence(s) sur la période", sortable: true},
  {key: "date", label: "Date de dernière apparition", sortable: true},
  {key: "utm", label: "UTM", sortable: false},
  {key: "url", label: "URL complète (dernière apparition)", sortable: false},
]);
const loading = ref(false);

onMounted(() => {
  loading.value = true;
  api.get(`/customers`)
      .then(({data}) => {
        clients.value = data.content.map(e => e.Login).sort();
      })
      .finally(() => {
        loading.value = false;
      });
});

async function loadData() {
  loading.value = true;
  const res = await api.get(`/referrer/${client.value}?utm=${utmType.value}&start=${date.value.startDate}&end=${date.value.endDate}`);
  report.value = res.data.content;
  loading.value = false;
  await filterData();
}

async function openModal(utm) {
  selectedUtm = utm;
  bvModal.show('modal-create-lever')
}

async function createLever() {
  try {
    loading.value = true;
    await api.post(`/referrer/${client.value}`, {
      utm: selectedUtm,
      libelle: selectedName.value
    });
    bvToast.toast("Succès", {
      title: "Levier créé",
      variant: 'success',
      solid: true
    });
  } catch (_) {
    bvToast.toast("Merci de contacter l'équipe technique ", {
      title: "Erreur lors de la création",
      variant: 'danger',
      solid: true
    });
  }
  bvModal.hide('modal-create-lever')
  loading.value = false;
  await loadData();
}

async function filterData() {
  if (report.value && searchedReferrer.value)
    reportFiltered.value = report.value.filter(e => e.utm.includes(searchedReferrer.value))
  else
    reportFiltered.value = report.value;
}

const totalRows = computed(() => {
  return reportFiltered.value.length;
});

</script>

<template>
  <div class="container-fluid">
    <b-row class="mb-4">
      <b-col md="12">
        <h2>Suivi des UTMs</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="my-2">
        <b-row>
          <b-col lg="3" md="12">
            <b-form-group label="Client :" label-for="input-client">
              <multiselect v-model="client" :options="clients" @input="loadData()"
                           placeholder="Choisir un client" select-label="Select"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="client" md="12" class="my-2">
        <b-row>
          <b-col lg="2" md="12">
            <b-form-group label="Choisir un type d'UTM :">
              <b-form-select v-model="utmType" :options="utmTypes" @input="loadData()"/>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="12">
            <b-form-group label="Rechercher un utm :">
              <b-form-input v-model="searchedReferrer" @input="filterData()"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="client" md="12" class="my-2">
        <b-row>
          <b-col cols="6">
            <b-form-group id="input-group-start-date" class="start-period" label="Date de début :"
                          label-for="input-start-date">
              <b-form-datepicker id="input-start-date" v-model="date.startDate" placeholder="Choisir une date"
                                 @input="loadData()"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="input-group-end-date" class="end-period" label="Date de fin :" label-for="input-end-date">
              <b-form-datepicker id="input-end-date" v-model="date.endDate" placeholder="Choisir une date"
                                 @input="loadData()"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="client && !loading" cols="12">
        <b-row class="align-items-end">
          <b-col cols="6">
            <b-row>
              <b-col>
                <b-form-group label="Par page :">
                  <b-form-select size="sm" v-model="perPage" :options="[10, 25, 100, 1000]"
                                 class="w-auto"></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div style="overflow-x: auto">
          <b-table striped class="text-monospace" style="font-size: 0.8rem" :items="reportFiltered" :fields="fields"
                   :per-page="perPage" :current-page="currentPage">
            <template #cell(url)="data">
              <span v-html="data.item.url"/>
            </template>
            <template #cell(create)="data">
              <span><b-btn v-b-popover.hover.top="'Créer le levier'" variant="primary" @click="openModal(data.item.utm)"><b-icon
                  icon="plus"/></b-btn></span>
            </template>
          </b-table>
        </div>
      </b-col>
      <b-col v-if="client && !loading" md="12">
        <div class="d-flex flex-row justify-content-center align-items-baseline">
          <b-pagination v-if="reportFiltered.length > 0" align="center" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" last-number first-number size="sm"></b-pagination>
          <b-badge pill class="ml-4"> {{ totalRows }} Résultats</b-badge>
        </div>
      </b-col>
      <b-col>
        <Spinner v-if="loading"/>
      </b-col>
    </b-row>
    <b-modal id="modal-create-lever" hide-footer size="md">
      <template #modal-title>
        <b-col md="12">Création d'un nouveau levier</b-col>
      </template>
      <div class="d-block">
        <b-row class="text-center">
          <b-col md="12">
            <b-form-input
                :disabled="loading"
                class="mb-3"
                v-model="selectedName"
                placeholder="Nom du levier"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="text-center mt-3">
          <b-col md="12">
            <b-button :disabled="loading" id="createButton" type="button" variant="primary" block @click="createLever">Créer</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
