<template>
  <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW"
         style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial; width: 100%;">
    <tbody>
    <tr>
      <td>
        <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW"
               style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
          <tbody>
          <tr>
            <td style="vertical-align: top;">
              <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW"
                     style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial; margin: 15px;">
                <tbody>
                <tr>
                  <td class="sc-TOsTZ kjYrri" style="text-align: center;"><img
                      :src="config[company].logo"
                      alt="companyLogo"
                      role="presentation" width="130" class="sc-cHGsZl bHiaRe"
                      style="width: 130px; display: block;"></td>
                </tr>
                </tbody>
              </table>
            </td>
            <td width="46">
              <div></div>
            </td>
            <td style="padding: 0px; vertical-align: middle;">
              <h3 color="#333" class="sc-fBuWsC eeihxG"
                  style="margin: 0px; font-size: 18px; color: rgb(11, 5, 59);">
                <span>{{ name }}</span></h3>
              <p color="#333" font-size="medium" class="sc-fMiknA bxZCMx"
                 style="margin: 0px; color: rgb(11, 5, 59); font-size: 14px; line-height: 22px;">
                <span>{{ title }}</span></p>
              <p color="#333" font-size="medium" class="sc-dVhcbM fghLuF"
                 style="margin: 0px; font-weight: 500; color: rgb(11, 5, 59); font-size: 14px; line-height: 22px;">
                <span>{{ team }}</span><span>{{ team !== '' ? '&nbsp;|&nbsp;' : ''}}</span><span>{{ company }}</span></p>
              <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW"
                     style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial; width: 100%;">
                <tbody>
                <tr>
                  <td height="10"></td>
                </tr>
                <tr>
                  <td color="#0c063c" direction="horizontal" height="1"
                      class="sc-jhAzac hmXDXQ"
                      style="width: 100%; border-bottom: 1px solid rgb(12, 6, 60) !important; border-left: none; display: block;">
                  </td>
                </tr>
                <tr>
                  <td height="10"></td>
                </tr>
                </tbody>
              </table>
              <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW"
                     style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                <tbody>
                <tr v-for="phone in phones" :key="phone" height="25" style="vertical-align: middle;">
                  <td width="30" style="vertical-align: middle;">
                    <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW"
                           style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                      <tbody>
                      <tr>
                        <td style="vertical-align: bottom;"><span color="#0c063c"
                                                                  width="11" class="sc-jlyJG bbyJzT"
                                                                  style="display: block;"><img
                            src="https://drop.datavault.tech/f.php?h=0b1MQmkp&p=1"
                            alt="phoneIcone"
                            color="#0c063c" width="13"
                            class="sc-iRbamj blSEcj"
                            style="display: block;"></span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="padding: 0px; color: rgb(11, 5, 59);"><a
                      color="#333" class="sc-gipzik iyhjGb"
                      style="text-decoration: none; color: rgb(11, 5, 59); font-size: 12px;"><span>{{
                      phone
                    }}</span></a></td>
                </tr>
                <tr height="25" style="vertical-align: middle;">
                  <td width="30" style="vertical-align: middle;">
                    <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW"
                           style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                      <tbody>
                      <tr>
                        <td style="vertical-align: bottom;"><span color="#0c063c"
                                                                  width="11" class="sc-jlyJG bbyJzT"
                                                                  style="display: block;"><img
                            src="https://drop.datavault.tech/f.php?h=3MqXU7mO&p=1"
                            alt="mailIcone"
                            color="#0c063c" width="13"
                            class="sc-iRbamj blSEcj"
                            style="display: block;"></span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="padding: 0px;"><a :href="emailAttribute" color="#333"
                                               class="sc-gipzik iyhjGb"
                                               style="text-decoration: none; color: rgb(11, 5, 59); font-size: 12px;"><span>{{
                      email
                    }}</span></a>
                  </td>
                </tr>
                <tr v-if="config[company].lp !== ''" height="25" style="vertical-align: middle;">
                  <td width="30" style="vertical-align: middle;">
                    <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW"
                           style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                      <tbody>
                      <tr>
                        <td style="vertical-align: bottom;"><span color="#0c063c"
                                                                  width="11" class="sc-jlyJG bbyJzT"
                                                                  style="display: block;"><img
                            src="https://drop.datavault.tech/f.php?h=2rD71sKe&p=1"
                            alt="linkIcone"
                            color="#0c063c" width="13"
                            class="sc-iRbamj blSEcj"
                            style="display: block;"></span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="padding: 0px;"><a :href="config[company].lp" color="#333"
                                               class="sc-gipzik iyhjGb"
                                               style="text-decoration: none; color: rgb(11, 5, 59); font-size: 12px;"><span>{{
                      config[company].lp
                    }}</span></a>
                  </td>
                </tr>
                <tr v-if="getCompany === '360&1'" height="25" style="vertical-align: middle;">
                  <td width="30" style="vertical-align: middle;">
                    <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW"
                           style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                      <tbody>
                      <tr>
                        <td style="vertical-align: bottom;"><span color="#0c063c"
                                                                  width="11" class="sc-jlyJG bbyJzT"
                                                                  style="display: block;"><img
                            src="https://drop.datavault.tech/f.php?h=2rD71sKe&p=1"
                            alt="phoneIcone"
                            color="#0c063c" width="13"
                            class="sc-iRbamj blSEcj"
                            style="display: block;"></span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style="padding: 0px;">
                    <a :href="config[company].help" color="#333"
                                               class="sc-gipzik iyhjGb"
                                               style="text-decoration: none; color: rgb(11, 5, 59); font-size: 12px;">
                      <span>Help Center | Centre d'aide</span>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
              <table cellpadding="0" cellspacing="0" class="sc-gPEVay eQYmiW"
                     style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;">
                <tbody>
                <tr>
                  <td height="30"></td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "sign-preview",
  data: () => ({
    emailAttribute: '',
    config: {
      '360&1': {lp: 'https://www.360and1.com/', logo: 'https://drop.datavault.tech/f.php?h=12IhwK45&p=1', help: 'https://help.360and1.com/'},
      'Full-Performance': {
        lp: 'https://www.full-performance.com/',
        logo: 'https://drop.datavault.tech/f.php?h=3uv1ocCP&p=1'
      },
      'Koorty': {lp: 'https://www.koorty.fr/', logo: 'https://drop.datavault.tech/f.php?h=1PLpyi21&p=1'},
      'Statshop': {lp: 'https://www.statshop.fr/', logo: 'https://drop.datavault.tech/f.php?h=26ao0S5P&p=1'},
      'Synchronext': {lp: 'https://www.synchronext.com/', logo: 'https://drop.datavault.tech/f.php?h=0FegT-XR&p=1'},
      'Sytsem': {lp: '', logo: 'https://drop.datavault.tech/f.php?h=3HNm4KJU&p=1'},
      'AI&QE': {lp: '', logo: 'https://drop.datavault.tech/f.php?h=0ybULTOL&p=1'}
    },
  }),

  props: {
    name: String,
    title: String,
    team: String,
    email: String,
    phones: Array,
    company: String
  },

  watch: {
    email: {
      handler: async function () {
        this.emailAttribute = 'mailto:' + this.email
      },
      deep: true
    },
  },

  computed: {
    getCompany() {
      return this.$props.company;
    }
  }
}
</script>

<style scoped>
table {
  background-color: white !important;
  border-radius: 3% !important;
  margin-right: 5px;
}

tr {
  border-color: white !important;
}

</style>
