<template>
  <div class="loading-bar">
    <progress-spinner
        :progress="progress"
        :determinate="determinate"
        color="#7579ff"
        empty-color="#324c7e"
        :size="200"
        :thickness="5"
        :empty-thickness="3"
        lineMode="in 10"
        dash="3"
        fontSize="1.5rem"
        font-color="black"
        animation="loop 700 1000"
    >
      <span slot="legend-value"> / {{ length }}</span>
      <span slot="legend-caption">TASKS DONE</span>
    </progress-spinner>
  </div>
</template>

<script>

export default {
  name: "Loader",
  props: {
    progress: Number,
    length: Number,
  },
};
</script>

<style scoped>
  .loading-bar {
    width: 95%;
    height: 95%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>