import { render, staticRenderFns } from "./Anniversaries.vue?vue&type=template&id=2a34b052&scoped=true"
import script from "./Anniversaries.vue?vue&type=script&lang=js"
export * from "./Anniversaries.vue?vue&type=script&lang=js"
import style0 from "./Anniversaries.vue?vue&type=style&index=0&id=2a34b052&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a34b052",
  null
  
)

export default component.exports