<script setup>
import { ref, onMounted, computed, watch, onBeforeUnmount } from "vue";
import { logEvents, logEventsText } from '@/utils/logEvents.js';
import { useRoute, useRouter } from "vue-router/composables";
import { logTypes, logTypesText } from '@/utils/logTypes.js';
import { logAdvertisers } from '@/utils/logAdvertisers.js';
import PeriodSelect from "@/components/PeriodSelect.vue";
import { useModal } from '@/composables/useModal';
import { useApi } from '@/composables/useApi';
import dayjs from 'dayjs';

const api = useApi();
const bvModal = useModal();
const router = useRouter();
const route = useRoute();

const admin = ref(null);
const head = ref(null);
const searchQuery = ref("");
const logs = ref([]);
const timer = ref(null);
const actionsFilter = ref([
  { value: null, text: 'Toutes' },
]);
const typesFilter = ref([
  { value: null, text: 'Toutes' },
]);
const advertisersFilter = ref([
  { value: null, text: 'Toutes' },
]);
const sitesFilter = ref([
  { value: null, text: 'Tous' },
]);
const selectedAction = ref(null);
const selectedType = ref(null);
const selectedAdvertiser = ref(null);
const selectedSite = ref(null);
const selectedLog = ref({
  new: "",
  old: "",
});
const totalRows = ref(1);
const currentPage = ref(1);
const perPage = ref(8);
const isLoading = ref(false);
const date = ref({
  startDate: null,
  endDate: null,
});

onMounted(async () => {
  isLoading.value = true;
  await api.get(`/users/infos`).then((response) => {
    admin.value = response && response.data && response.data.content && response.data.content.rank >= 2;
    head.value = response && response.data && response.data.content && response.data.content.rank >= 3;
  })
  setInterval(getLogs, 60000)
  await getFilters();
  const site = route.query.site || null;
  const advertiser = route.query.advertiser;
  if (site) selectedSite.value = sitesFilter.value.find(item => item.text === site).value;
  if (advertiser) selectedAdvertiser.value = advertisersFilter.value.find(item => item.text.toLowerCase() === advertiser).value;
  await getLogs(true);
  isLoading.value = false;
});

onBeforeUnmount(() => {
  clearInterval(timer.value);
});

async function getLogs(firstLoad = false) {
  if(firstLoad || (!firstLoad && !isLoading.value)) {
    if(!firstLoad) isLoading.value = true;
    const { data } = await api.get(`/thirdPartyHistory`, {
      params: {
        searchQuery: searchQuery.value === "" ? null : searchQuery.value,
        advertiser: selectedAdvertiser.value,
        site: selectedSite.value,
        event: selectedAction.value,
        startDate: date.value.startDate,
        endDate: date.value.endDate,
        type: selectedType.value,
        rowsPerPage: perPage.value,
        currentPage: currentPage.value,
      }
    });
    logs.value = data.content.logs;
    totalRows.value = data.content.totalRows;
    if(!firstLoad) isLoading.value = false;
  }
}

async function changeDate(newDate) {
  date.value = newDate;
  await getLogs();
}

function resetFilters() {
  selectedAction.value = null;
  selectedType.value = null;
  selectedAdvertiser.value = null;
  selectedSite.value = null;
  date.value = {
    startDate: null,
    endDate: null,
  };
  searchQuery.value = "";
  router.push({ query: null });
  getLogs();
}

function showChangesModal(oldValue, newValue) {
  selectedLog.value.new = newValue;
  selectedLog.value.old = oldValue;
  bvModal.show('changes-modal');
}

function getEvent(event) {
  return logEventsText[event] ? logEventsText[event] : event;
}

function getEventText(event) {
  return logEvents[event] ? logEvents[event] : event;
}

function getTypeText(type) {
  const adgroup = ['ADGROUP', 'AD_GROUP', 'AD-GROUP', 'AD GROUP'];
  const lineitem = ['LINEITEM', 'LINE_ITEM', 'LINE-ITEM', 'LINE ITEM'];
  if (adgroup.includes(type)) return logTypesText['AD_GROUP'];
  if (lineitem.includes(type)) return logTypesText['LINE_ITEM'];
  return logTypesText[type] ? logTypesText[type] : type;
}

function getType(type) {
  const adgroup = ['ADGROUP', 'AD_GROUP', 'AD-GROUP', 'AD GROUP'];
  const lineitem = ['LINEITEM', 'LINE_ITEM', 'LINE-ITEM', 'LINE ITEM'];
  if (adgroup.includes(type)) return logTypes['AD_GROUP'];
  if (lineitem.includes(type)) return logTypes['LINE_ITEM'];
  return logTypes[type] ? logTypes[type] : type;
}

function formatDate(date) {
  return dayjs(date).format('DD/MM/YYYY [à] HH:mm:ss');
}

async function getFilters() {
  const { data } = await api.get(`/thirdPartyHistory/filters`);
  const filters = data.content;
  let result = [];
  if (filters.events) {
    filters.events.forEach(event => {
      result.push({ value: event, text: logEvents[event] ? logEvents[event] : event });
    });
    actionsFilter.value = [...actionsFilter.value, ...result.sort((a,b)=> (a.text > b.text ? 1 : -1))];
    result = [];
  }

  if (filters.types) {
    filters.types.forEach(type => {
      result.push({ value: type, text: getType(type) });
    });
    typesFilter.value = [...typesFilter.value, ...result.sort((a,b)=> (a.text > b.text ? 1 : -1))];
    typesFilter.value = typesFilter.value.filter((item, index) => typesFilter.value.findIndex(i => i.text === item.text) === index);
    result = [];
  }

  if (filters.advertisers) {
    filters.advertisers.forEach(advertiser => {
      result.push({ value: advertiser, text: logAdvertisers[advertiser] ? logAdvertisers[advertiser] : advertiser });
    });
    advertisersFilter.value = [...advertisersFilter.value, ...result.sort((a,b)=> (a.text > b.text ? 1 : -1))];
  }

  if (filters.sites) {
    sitesFilter.value = [...sitesFilter.value, ...filters.sites.sort((a,b)=> (a.text > b.text ? 1 : -1))];
  }
}

watch(currentPage, () => {
  getLogs();
});

const isFilters = computed(() => selectedAction.value || selectedType.value || selectedAdvertiser.value || selectedSite.value || date.value.startDate || date.value.endDate);
</script>

<template>
  <div class="logs-view-container">
    <div class="d-flex align-items-center">
      <h2>
        Agrégation des logs
      </h2>
      <b-badge variant="secondary" class="ml-4 d-flex align-items-center" @click="resetFilters()" style="cursor: pointer" v-if="isFilters">
        <span>Réinitialiser les filtres</span>
        <b-icon icon="x" aria-hidden="true" class="ml-1" style="cursor: pointer; width: 16px; height: 16px" />
      </b-badge>
    </div>
    <b-row class="d-flex flex-column">
      <div class="tools d-flex justify-content-between px-3 col-md">
        <div class="d-flex gap-1 md-100 align-items-center" v-if="admin || head">
          <b-form-group label="Recherche">
            <div class="d-flex align-items-center">
              <b-form-input id="filter-input" v-model="searchQuery" autocomplete="off" @keyup.enter="getLogs()" type="search" placeholder="W2R Test, 1231, John Doe..." />
              <b-btn variant="primary" class="ml-2">
                <b-icon icon="search" aria-hidden="true" @click="getLogs()"></b-icon>
              </b-btn>
            </div>
          </b-form-group>
        </div>
        <div class="d-flex gap-1 md-100">
          <b-form-group label="Action">
            <b-form-select v-model="selectedAction" :options="actionsFilter" @change="getLogs()" />
          </b-form-group>
          <b-form-group label="Type d'action">
            <b-form-select v-model="selectedType" :options="typesFilter" @change="getLogs()" />
          </b-form-group>
          <b-form-group label="Plateforme">
            <b-form-select v-model="selectedAdvertiser" :options="advertisersFilter" @change="getLogs()" />
          </b-form-group>
          <b-form-group label="Site">
            <b-form-select v-model="selectedSite" :options="sitesFilter" @change="getLogs()" />
          </b-form-group>
        </div>
      </div>
      <div class="px-3">
        <PeriodSelect @Date="(newDate) => changeDate(newDate)" empty />
      </div>
      <div v-if="!isLoading">
        <div class="logs-container" v-if="logs.length">
          <div v-for="log in logs" class="log" :key="log.id" @click="log.oldValue || log.newValue ? showChangesModal(log.oldValue, log.newValue) : null">
            <div class="mr-2">
              <img src="../assets/logo/microsoft.png" alt="logo" width="28px" v-if="log.advertiser === 'MICROSOFT'"/>
              <img src="../assets/logo/google.png" alt="logo" width="28px" v-if="log.advertiser === 'GOOGLE'"/>
              <img src="../assets/logo/facebook.png" alt="logo" width="28px" v-if="log.advertiser === 'FACEBOOK'"/>
              <img src="../assets/logo/xandr.png" alt="logo" width="28px" v-if="log.advertiser === 'XANDR'"/>
            </div>
            <b-badge pill :variant="log.event === 'CREATE' ? 'success' : log.event === 'UPDATE' ? 'warning' : 'danger'">
              {{ getEventText(log.event) }}
            </b-badge>
            <b-badge pill variant="secondary">{{ formatDate(log.date) }}</b-badge>
            <div>
              <b>{{ log.user }}</b> a {{ getEvent(log.event) }} <b>{{ getTypeText(log.elem_type) }}</b> : {{ log.elem_name }} sur le site <b>{{ log.site_name }}</b>
            </div>
          </div>
        </div>
        <div v-else class="text-center my-5">
          Aucun log trouvé
        </div>
        <div class="d-flex justify-content-between align-items-center my-3 px-3">
          <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              align="center"
              size="sm"
              last-number
              class="mb-0"
          />
          <span class="text-muted">
            {{ totalRows }} logs
          </span>
        </div>
      </div>
      <div v-else>
        <Spinner />
      </div>
    </b-row>
    <b-modal size="md" id="changes-modal" centered scrollable hide-footer title="Changements">
      <div class="change" v-if="selectedLog.old">
        <span>
        Ancienne valeur:
        </span>
        <code>
          {{ selectedLog.old }}
        </code>
      </div>
      <br>
      <div class="change" v-if="selectedLog.new">
        <span>
        Nouvelle valeur:
        </span>
        <code>
          {{ selectedLog.new }}
        </code>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
.logs-view-container {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

#filter-input {
  width: 300px;
}

.gap-1 {
  gap: 1rem;
}

@media (max-width: 1580px) {
  .col-md {
    flex-direction: column;
  }

  .md-100 {
    width: 100%;
  }
}

.logs-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  gap: 1rem;
}

.logs-container img {
  object-fit: cover;
}

.log {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  background-color: var(--background-color-secondary);
  border: 1px solid rgba(0, 0, 0, 0.24);
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.log:hover {
  cursor: pointer;
  background-color: var(--background-color-secondary-hover);
}

.change {
  color: var(--text-primary-color);
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.change span {
  font-weight: bold;
}

.change code {
  background-color: var(--background-color-primary);
  padding: 0.5rem;
  border-radius: 5px;
  color: var(--text-primary-color);
}
</style>
