<script setup>
import { useApi } from "@/composables/useApi";
import { ref, computed } from "vue";
import dayjs from "dayjs";

const api = useApi();

const loading = ref(false);
const startDate = ref(null);
const endDate = ref(null);
const registeredAfter = ref(dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
const attributionSelected = ref(6);
const attributionOptions = ref([
  { value: 1, text: 'Premier clic' },
  { value: 2, text: 'Dernier clic' },
  { value: 3, text: 'Bases' },
  { value: 4, text: 'Linéaires' },
  { value: 5, text: 'Progressifs' },
  { value: 6, text: 'Linéaires priorisés' },
  { value: 7, text: 'Dernier clic payant' }
]);
const bookings = ref([]);
const filter = ref(null);
const fieldsBookings = ref([
  {key: 'id', label: 'ID', sortable: true},
  {key: 'login', label: 'Client', sortable: false},
  {key: 'bookings', label: 'Réservations', sortable: true},
  {key: 'bookings361', label: 'Réservations 360&1', sortable: true},
  {key: 'price', label: `CA total (TTC)`, sortable: true},
  {key: 'priceht', label: `CA total (HT)`, sortable: true},
  {key: 'price361', label: `CA apporté (TTC)`, sortable: true},
  {key: 'percent361', label: `CA apporté (TTC %)`, sortable: true},
  {key: 'price361ht', label: `CA apporté (HT)`, sortable: true},
  {key: 'percent361ht', label: `CA apporté (HT %)`, sortable: true},
  {key: 'total_paid_referencing', label: `Autre référencement payant`, sortable: true},
  {key: 'google_expenses', label: `Dépenses Google`, sortable: true},
  {key: 'bing_expenses', label: `Dépenses Microsoft`, sortable: true},
  {key: 'xandr_expenses', label: `Dépenses Xandr`, sortable: true},
  {key: 'facebook_expenses', label: `Dépenses Meta`, sortable: true},
  {key: 'total_expenses', label: `Dépenses totales`, sortable: true},
  {key: 'registered', label: `Date d'inscription`, sortable: true},
]);
const totalRows = ref(1);
const currentPage = ref(1);
const perPage = ref(10);
const options = ref([
  { value: 10, text: 10 },
  { value: 25, text: 25 },
  { value: 50, text: 50 },
  { value: 100, text: 100 },
  { value: 250, text: 250 },
  { value: 500, text: 500 },
  { value: 1000, text: 1000 },
]);

async function getData() {
  loading.value = true;
  const responseBookings = await api.post(`/hotels/activity`, {
    startDate: startDate.value,
    endDate: endDate.value,
    registeredAfter: registeredAfter.value,
    algoId: attributionSelected.value,
  });
  if (responseBookings && responseBookings.data && responseBookings.data.content) {
    bookings.value = responseBookings.data.content
    totalRows.value = bookings.value.length
  }
  loading.value = false;
}

function setRegisterAtStart() {
  registeredAfter.value = '1996-01-12';
}

function download(arr) {
  const array = [Object.keys(arr[0])].concat(arr);
  const data = array.map(it => {
    for (const key in it) {
      if (typeof it[key] === 'number' || (typeof it[key] === 'string' && it[key].match(/^\d+\.\d+$/))) {
        it[key] = it[key].toString().replace('.', ',');
      }
    }
    return Object.values(it).join(';');
  }).join('\n');

  const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
  const url = URL.createObjectURL(blob);

  const e = document.createElement('a');
  e.href = url;
  e.setAttribute('download', 'export.csv');
  e.click();
}

const getTotalBookings = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += el.bookings
  });
  return total;
});
const getTotalBookings361 = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += el.bookings361
  });
  return total;
});
const getTotalPrice = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += Number(el.price)
  });
  return total.toFixed(2);
});
const getTotalPriceht = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += Number(el.priceht)
  });
  return total.toFixed(2);
});
const getTotalPrice361 = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += Number(el.price361)
  });
  return total.toFixed(2);
});
const getTotalPrice361ht = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += Number(el.price361ht)
  });
  return total.toFixed(2);
});
const getMediumPricePercent = computed(() => {
  let total = 0;
  let elements = 0;
  bookings.value.forEach(el => {
    total += Number(el.percent361)
    elements += 1
  });
  return elements > 0 ? (total / elements).toFixed(2) + '%' : 'N/A';
});
const getMediumPricePercentht = computed(() => {
  let total = 0;
  let elements = 0;
  bookings.value.forEach(el => {
    total += Number(el.percent361ht)
    elements += 1
  });
  return elements > 0 ? (total / elements).toFixed(2) + '%' : 'N/A';
});
const getTotalPaidReferencing = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += Number(el.total_paid_referencing)
  });
  return total.toFixed(2);
});
const getTotalGoogleExpenses = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += Number(el.google_expenses)
  });
  return total.toFixed(2);
});
const getTotalFacebookExpenses = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += Number(el.facebook_expenses)
  });
  return total.toFixed(2);
});
const getTotalBingExpenses = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += Number(el.bing_expenses)
  });
  return total.toFixed(2);
});
const getTotalXandrExpenses = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += Number(el.xandr_expenses)
  });
  return total.toFixed(2);
});
const getTotalTotalExpenses = computed(() => {
  let total = 0;
  bookings.value.forEach(el => {
    total += Number(el.total_expenses)
  });
  return total.toFixed(2);
});
const getTotalCustomers = computed(() => {
  let total = 0;
  bookings.value.forEach(() => {
    total += 1
  });
  return total;
});
</script>

<template>
    <div>
        <Spinner v-if="loading"/>
        <div v-else>
            <b-row>
                <b-col lg="4" md="12">
                    <b-form-group class="start-period"
                                  id="input-group-registeredAfter-date"
                                  label="Client inscrit sur FTP après le (inclus) :"
                                  label-for="input-registeredAfter-date">
                        <b-form-datepicker id="input-registeredAfter-date" v-model="registeredAfter"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
              <b-col lg="4" md="12">
                <b-button type="button" variant="primary" @click="setRegisterAtStart()" block>Depuis le début</b-button>
              </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" class="mt-3 mb-3">
                    <h5 class="h6" style="text-decoration: underline;">
                        Choix du modèle d'attribution :
                    </h5>
                </b-col>
                <b-col lg="4" md="12">
                    <b-form-select v-model="attributionSelected" :options="attributionOptions"></b-form-select>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" class="mt-3 mb-3">
                    <h5 class="h6" style="text-decoration: underline;">
                        Ne récupérer la donnée que dans l'encadrement de date suivant (optionnel) :
                    </h5>
                </b-col>
                <b-col lg="4" md="12">
                    <b-form-group class="start-period"
                                  id="input-group-start-date"
                                  label="Date de début (inclus) :"
                                  label-for="input-start-date">
                        <b-form-datepicker id="input-start-date" v-model="startDate"/>
                    </b-form-group>
                </b-col>
                <b-col lg="4" md="12">
                    <b-form-group class="end-period"
                                  id="input-group-end-date"
                                  label="Date de fin (inclus) :"
                                  label-for="input-end-date">
                        <b-form-datepicker id="input-end-date" v-model="endDate"/>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mt-4 mb-4" cols="6" offset="3">
                    <b-button type="button" class="btn btn-primary" variant="primary" @click="getData" block>Récupérer la donnée</b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col lg="6" md="10">
                    <b-input-group class="mb-2" size="sm">
                        <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Rechercher un client"></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Vider</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col lg="6" md="2" class="text-right">
                    <b-form-select size="sm" v-model="perPage" :options="options" class="w-auto"></b-form-select>
                </b-col>
                <b-col cols="12">
                    <div>
                        <b-icon style="cursor: pointer" icon="download" @click="download(bookings)"
                                v-b-popover.hover.top="'Télécharger'"/>
                        <b-table
                                striped hover
                                id="bookings-table"
                                class="text-monospace font-size"
                                empty-text="Aucune réservation n'a été récupérée"
                                :items="bookings"
                                :fields="fieldsBookings"
                                :filter="filter"
                                :current-page="currentPage"
                                :per-page="perPage"
                                stacked="sm"
                                show-empty
                                small
                        >
                            <template #cell(price361)="data">
                                <div v-if="data.item.price361 > 0 && data.item.price361.toString().split('.')[1] === '00'">
                                    {{ data.item.price361.toString().split('.')[0] }}€
                                </div>
                                <div v-else-if="data.item.price361 > 0">
                                    {{ data.item.price361 }}€
                                </div>
                                <div v-else>
                                    0€
                                </div>
                            </template>
                            <template #cell(price361ht)="data">
                              <div v-if="data.item.price361ht > 0 && data.item.price361ht.toString().split('.')[1] === '00'">
                                {{ data.item.price361ht.toString().split('.')[0] }}€
                              </div>
                              <div v-else-if="data.item.price361ht > 0">
                                {{ data.item.price361ht }}€
                              </div>
                              <div v-else>
                                0€
                              </div>
                            </template>
                            <template #cell(price)="data">
                                <div v-if="data.item.price > 0 && data.item.price.toString().split('.')[1] === '00'">
                                    {{ data.item.price.toString().split('.')[0] }}€
                                </div>
                                <div v-else-if="data.item.price > 0">
                                    {{ data.item.price }}€
                                </div>
                                <div v-else>
                                    0€
                                </div>
                            </template>
                          <template #cell(priceht)="data">
                            <div v-if="data.item.priceht > 0 && data.item.priceht.toString().split('.')[1] === '00'">
                              {{ data.item.priceht.toString().split('.')[0] }}€
                            </div>
                            <div v-else-if="data.item.priceht > 0">
                              {{ data.item.priceht }}€
                            </div>
                            <div v-else>
                              0€
                            </div>
                          </template>
                          <template #cell(percent361)="data">
                              {{ data.item.percent361 }}%
                          </template>
                          <template #cell(percent361ht)="data">
                            {{ data.item.percent361ht }}%
                          </template>
                          <template #cell(google_expenses)="data">
                            {{ data.item.google_expenses }}€
                          </template>
                          <template #cell(bing_expenses)="data">
                            {{ data.item.bing_expenses }}€
                          </template>
                          <template #cell(xandr_expenses)="data">
                            {{ data.item.xandr_expenses }}€
                          </template>
                          <template #cell(facebook_expenses)="data">
                            {{ data.item.facebook_expenses }}€
                          </template>
                          <template #cell(total_expenses)="data">
                            {{ data.item.total_expenses }}€
                          </template>
                          <template #custom-foot>
                            <b-tr>
                              <b-th>TOTAL</b-th>
                              <b-th>{{ getTotalCustomers }}</b-th>
                              <b-th>{{ getTotalBookings }}</b-th>
                              <b-th>{{ getTotalBookings361 }}</b-th>
                              <b-th>{{ getTotalPrice }}€</b-th>
                              <b-th>{{ getTotalPriceht }}€</b-th>
                              <b-th>{{ getTotalPrice361 }}€</b-th>
                              <b-th>{{ getMediumPricePercent }}</b-th>
                              <b-th>{{ getTotalPrice361ht }}€</b-th>
                              <b-th>{{ getMediumPricePercentht }}</b-th>
                              <b-th>{{ getTotalPaidReferencing }}</b-th>
                              <b-th>{{ getTotalGoogleExpenses }}€</b-th>
                              <b-th>{{ getTotalBingExpenses }}€</b-th>
                              <b-th>{{ getTotalXandrExpenses }}€</b-th>
                              <b-th>{{ getTotalFacebookExpenses }}€</b-th>
                              <b-th>{{ getTotalTotalExpenses }}€</b-th>
                            </b-tr>
                          </template>
                        </b-table>
                        <b-pagination
                                align="center"
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                size="sm"
                        ></b-pagination>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.c_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

.switchButtonContainer {
  width: 100%;
  display: flex;
  margin-top: 1.75rem;
  align-items: center;
}

.font-size {
  font-size: .8rem;
}
</style>
