<template>
  <div class="chart">
    <apexchart
        :options="chartOptions"
        :series="treemapSeries"
        height="550"
        type="treemap"
        class="apexchart"
    >
    </apexchart>
  </div>
</template>

<script>

export default {
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
  },
  data: () => ({
    treemapSeries: [],
    chartOptions: {
      chart: {
        type: 'treemap'
      },
      title: {
        text: 'Type de demandes',
        style: {
          fontSize: '25px',
        }
      },
      dataLabels: {
        enabled: true,
      }
    }
  }),
  created() {
    this.getTreemapData();
  },
  watch: {
    startDate: function () {
      this.getTreemapData();
    },
    endDate: function () {
      this.getTreemapData();
    }
  },
  methods: {
    getTreemapData: async function () {
      this.$store.state.treeMapLoading = true
      const response = await this.$http.post(`/stats/requeststype`, {
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.treemapSeries = response.data.content
      this.$store.state.treeMapLoading = false
    },
  },
}
</script>

<style scoped>
.chart {
  margin: 0 auto;
  width: 100%;
}
</style>
