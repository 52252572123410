const logEvents = {
    "CREATE": "Création",
    "UPDATE": "Modification",
    "DELETE": "Suppression"
}

const logEventsText = {
    "CREATE": "créé",
    "UPDATE": "modifié",
    "DELETE": "supprimé"
}

const logEventsIcon = {
    "CREATE": "plus-circle",
    "UPDATE": "pencil",
    "DELETE": "trash"
}

module.exports = { logEvents, logEventsText, logEventsIcon };
