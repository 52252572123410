<script setup>
import Vue, {ref, onMounted, computed} from 'vue';
import store from "@/store";

const http = Vue.prototype.$http;

let report = ref(null);
const perPage = ref(10);
const currentPage = ref(1);
const fields = ref([
  {key: 'id', label: '#', sortable: true},
  {key: 'login', label: 'Client', sortable: true},
  {key: 'libelle', label: 'Libelle', sortable: true},
  {key: 'master_id', label: 'Master id', sortable: true},
  {key: 'master_libelle', label: 'Libelle Master', sortable: true},
  {key: 'parent_id', label: 'Parent id', sortable: true}
]);
const filter = ref(null);

onMounted(() => {
  store.commit("isLoading", true);
  http.get(`/global-stats/unlinked_lever`)
      .then(({data}) => {
        report.value = data.content;
        store.commit("isLoading", false);
      })
      .catch(() => {
        store.commit("isLoading", false);
      });
});

const totalRows = computed(() => {
  return report.value.length;
});

</script>

<template>
  <div class="container-fluid">
    <b-row class="mb-4">
      <b-col md="12">
        <h2>
          Leviers non liés
        </h2>
      </b-col>
    </b-row>
    <b-row v-if="report">
      <b-col>
        <b-form-group label="Par page :">
          <b-form-select size="sm" v-model="perPage" :options="[10, 25, 100, 1000]"
                         class="w-auto"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Recherche :">
          <b-input-group>
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Rechercher"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="report">
      <b-col cols="12">
        <div style="overflow-x: auto;">
          <b-table striped hover class="text-monospace" style="font-size: .8rem" :items="report" :fields="fields"
                   :per-page="perPage" :current-page="currentPage" stacked="sm" show-empty small :filter="filter"/>
        </div>
      </b-col>
      <b-col md="12">
        <div class="d-flex flex-row justify-content-center align-items-baseline">
          <b-pagination v-if="report.length > 0" align="center" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" last-number first-number size="sm"></b-pagination>
          <b-badge pill class="ml-4">
            {{ totalRows }} Résultat(s)
          </b-badge>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <h4>
        Pas de données (bonne nouvelle 😉)
      </h4>
    </b-row>
  </div>
</template>
