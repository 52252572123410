<template>
  <div class="chart">
    <apexchart
        :options="chartOptions"
        :series="pieChartSeconds"
        height="500"
        type="pie"
        class="apexchart"
    >
    </apexchart>
  </div>
</template>

<script>

export default {
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
  },
  data: () => ({
    pieChartData: [],
    pieChartSeconds: [],
    averageTime: null,
    chartOptions: {
      chart: {
        type: 'pie',
        toolbar: {
          show: true,
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value + ' sec';
          }
        },
      },
      title: {
        text: "Temps d'execution par types",
        style: {
          fontSize: '25px',
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (value, {seriesIndex, w}) {
          const hours = Math.floor(value / 3600);
          const seconds = w.globals.series[seriesIndex];
          const minutes = Math.floor(seconds / 60);
          const secondsLeft = seconds % 60;
          return hours > 0 ? `${hours}h ${minutes}m ${secondsLeft}s` : `${minutes}m ${secondsLeft}s`;
        }
      },
    }
  }),
  created() {
    this.getExecutionTimeData();
  },
  watch: {
    startDate: function () {
      this.getExecutionTimeData();
    },
    endDate: function () {
      this.getExecutionTimeData();
    }
  },
  methods: {
    getExecutionTimeData: async function () {
      this.$store.state.pieChartLoading = true
      const response = await this.$http.post(`/stats/averagetime`, {
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.averageTime = response.data.content
      const res = await this.$http.post(`/stats/requeststime`, {
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.pieChartData = res.data.content.data
      this.pieChartLabels = res.data.content.labels
      this.pieChartSeconds = res.data.content.seconds
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels: this.pieChartLabels,
          subtitle: {
            align: 'left',
            text: "Temps moyen d'execution : " + this.averageTime + " min",
            style: {
              fontSize: '18px',
            },
          }
        }
      }
      this.$store.state.pieChartLoading = false
    },
  },
}
</script>

<style scoped>

</style>
