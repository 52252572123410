<template>
  <div>
    <Loading v-if="!customer || loading"/>
    <b-col md="8" offset-md="2" v-else>
      <b-row align-v="center">
        <b-col cols="12">
          <h3 style="margin-bottom: 20px;">
            <b-icon class="refreshDataButton" icon="arrow-counterclockwise" @click="refreshData()"></b-icon>&nbsp;Informations
            Visiteurs
          </h3>
          <pre style="white-space: pre-line;">
        <p class="VisitorsInspector_p">Know as click fraud, receiving fraudulent clicks on your ads can be very frustrating and very expensive. Just imagine paying tens of dollars per click and watching the same person click your ad over and over again. Luckily, there is something you can do to stop people from wasting your precious budget.</p>
          <p style="text-align: right;"><a href="https://ppcprotect.com/how-to-block-an-ip-in-google-adwords/"
                                           target="_blank">www.ppcprotect.com</a></p>
        </pre>
          <label style="margin-right: 5px">Rafraîchissement automatique</label>
          <b-form-checkbox v-model="auto_refresh" switch size="lg" class="my-2" />
          <b-row>
            <b-col md="6">
              <b-form-group
                  id="input-group-start-date"
                  label="Date et heure de début:"
                  label-for="input-start-date">
                <b-form-datepicker id="input-start-date" v-model="form.start_date"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  id="input-group-end-date"
                  label="Date et heure de fin :"
                  label-for="input-end-date">
                <b-form-datepicker id="input-end-date" v-model="form.end_date"></b-form-datepicker>
              </b-form-group>
              <br>
            </b-col>
            <b-col md="4" offset-md="4" style="margin-bottom: 20px;">
              <b-button @click="refreshData()" variant="primary" block>Valider</b-button>
            </b-col>
            <b-col v-if="visitors_data.length > 0" md="12" style="margin-bottom: 20px;">
              <b-alert dismissible class="text-center" v-if="!analyzeAvailable" variant="danger" show>Whois - Budget
                dépassé&nbsp;<b-icon icon="emoji-dizzy"></b-icon>
              </b-alert>
              <b-alert dismissible class="text-center" v-else-if="countAnalyzeAvailable <= 50" variant="warning" show>
                Whois - Budget presque dépassé : {{ countAnalyzeAvailable }}&nbsp;<b-icon icon="emoji-neutral"></b-icon>
              </b-alert>
              <b-alert dismissible class="text-center" v-else variant="success" show>Whois - Budget restant :
                {{ countAnalyzeAvailable }}&nbsp;<b-icon icon="emoji-sunglasses"></b-icon>
              </b-alert>
            </b-col>
          </b-row>
          <b-col md="12">
            <b-table
                striped hover
                id="customer-table"
                empty-text="Aucune donnée pour ce client."
                :items="visitors_data"
                :fields="fieldsVisitorsData"
                stacked="sm"
                show-empty
                :current-page="currentPage"
                :per-page="perPage"
                small>
              <template #cell(whois)="data">
                <div v-if="data.item.whois" class="text-center">
                  <img v-b-popover.hover.right="'Afficher le résultat de l\'analyse'" src="../assets/domain.png"
                       alt="whois" @click="showModalWhois(data.item.whois)" style="width: 18px;">
                </div>
                <div v-else-if="data.item.ip !== 'NA'" class="text-center">
                  <img v-b-popover.hover.right="'Analyser l\'adresse IP'" src="../assets/magnifying-glass.png"
                       alt="analyze" @click="analyzeSingleData(data.item)" style="width: 18px;">
                </div>
                <div v-else class="text-center">
                  <img v-b-popover.hover.right="'Aucune adresse IP à analyser...'" src="../assets/error.png"
                       alt="not_analyzable" style="width: 18px;">
                </div>
              </template>

              <template #cell(ip)="{item}">
                <div :title="item.ip"
                     style="width: 120px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                      @click="copyToClipboard(item.ip)"
                >
                  {{ item.ip }}
                </div>
              </template>
              <template #cell(actions)="{item}">
                <b-button v-if="!item.blacklist" variant="success" size="sm" @click="addExclusionIp(item)">Oui</b-button>
                <b-button v-if="item.blacklist" variant="danger" size="sm" @click="removeExclusionIp(item)">Non</b-button>
              </template>
            </b-table>
          </b-col>
          <b-col md="4" offset-md="4" style="margin-top: 20px;">
            <b-pagination
                pills
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-row>
    </b-col>
    <b-modal id="modal-whois" hide-footer size="lg" v-if="viewWhois">
      <template #modal-title>
        <b-col md="12">
          {{ viewWhois.domainName }}
        </b-col>
        <b-col md="12" class="modal-subtitle">Date de création : {{ viewWhois.registryData.createdDate }}</b-col>
      </template>
      <div class="d-block">
        <b-row>
          <b-col md="12">
            <div v-if="viewWhois.registryData">
              <code>
                <pre style="color: var(--text-primary-color) !important;">{{ viewWhois.registryData }}</pre>
              </code>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'Help',
  data: () => ({
    timer: null,
    auto_refresh: false,
    whitelist: ['microsoft', 'bing', 'google', 'facebook'],
    loading: false,
    progressBar: false,
    viewWhois: null,
    analyzeAvailable: false,
    countAnalyzeAvailable: 0,
    form: {
      end_date: '',
      end_hour: ''
    },
    fieldsVisitorsData: [
      {key: 'ip', label: 'IP', sortable: true},
      {key: 'navigations', label: 'Clicks payants', sortable: true},
      {key: 'orders', label: 'Conversions', sortable: true},
      {key: 'spend', label: 'CA HT €', sortable: true},
      {key: 'country', label: 'Pays', sortable: true},
      {key: 'whois', label: 'Whois', sortable: false},
      {"actions": "Blacklister l'ip"},
    ],
    customer: null,
    visitors_data: null,
    visitors_nav: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 15,
    progress: 0,
  }),
  beforeDestroy() {
    clearInterval(this.timer)
  },
  created() {
    this.timer = setInterval(async () => {
      await this.refreshData(true)
    }, 5000)
  },
  async mounted() {
    await this.refreshData()
    await this.isAnalyzeAvailable()
  },
  methods: {
    showModalWhois(whois) {
      this.viewWhois = whois
      this.$bvModal.show('modal-whois')
    },
    async analyzeSingleData(item) {
      if (this.analyzeAvailable) {
        this.auto_refresh = false;
        this.loading = true
        const response = await this.$http.get('/whois/' + item.ip)
        if (response.status === 200) {
          if (
              response.data.content.registryData.registrant &&
              response.data.content.registryData.registrant.organization &&
              this.whitelist.some(element => response.data.content.registryData.registrant.organization.toLowerCase().includes(element))
          ) {
            item._rowVariant = 'success'
          } else {
            item._rowVariant = 'danger'
          }
          item.whois = response.data.content
        } else {
          this.$bvToast.toast("Impossible d'analyser cette adresse IP !", {
            title: null,
            noCloseButton: true,
            variant: 'danger',
            solid: true
          })
        }
        await this.isAnalyzeAvailable()
        this.loading = false
      } else {
        this.$bvToast.toast("Il n'y a plus de crédits disponibles !", {
          title: null,
          noCloseButton: true,
          variant: 'danger',
          solid: true
        })
      }
    },
    async isAnalyzeAvailable() {
      const response = await this.$http.get('/whois')
      this.analyzeAvailable = response.data.content.credits > 0
      this.countAnalyzeAvailable = response.data.content.credits
    },
    async refreshData(quickRefresh = false) {
      if (!quickRefresh) this.customer = null;
      else if (!this.auto_refresh) return;
      if (this.form.start_date && this.form.end_date) {
        const response = await this.$http.get(`/customers/${this.$route.params.id}`)
        const responseVisitors = await this.$http.post(`/customers/${this.$route.params.id}/visitors`, {
          'start_date': this.form.start_date,
          'end_date': this.form.end_date
        })
        this.customer = response.data.content
        this.visitors_data = responseVisitors.data.content.result
        this.totalRows = this.visitors_data.length
      } else {
        this.customer = ' ';
        this.visitors_data = [];
        this.totalRows = 1;
      }
    },
    async addExclusionIp(item) {
      try {
        await this.$http.post(`/customers/${this.$route.params.id}/exclusion-ip`, {
          'ip': item.ip
        })
        item.blacklist = true;
        this.$bvToast.toast(`L'ip ${item.ip} a bien été exclue.`, {
          title: null,
          noCloseButton: true,
          variant: 'success',
          solid: true,
          autoHideDelay: 1500,
        });
      } catch (e) {
        this.$bvToast.toast(`Impossible d'exclure cette ip.`, {
          title: 'Error',
          noCloseButton: true,
          variant: 'danger',
          solid: true,
          autoHideDelay: 1500,
        });
      }
    },

    async removeExclusionIp(item) {
      try {
        await this.$http.delete(`/customers/${this.$route.params.id}/exclusion-ip/${item.ip}`);
        item.blacklist = false;
        this.$bvToast.toast(`L'ip ${item.ip} a bien été retirée de la liste des exclusions.`, {
          title: null,
          noCloseButton: true,
          variant: 'success',
          solid: true,
          autoHideDelay: 1500,
        });
      } catch (e) {
        this.$bvToast.toast(`Impossible de retirer cette ip de la liste des exclusions.`, {
          title: 'Error',
          noCloseButton: true,
          variant: 'danger',
          solid: true,
          autoHideDelay: 1500,
        });
      }
    },

    copyToClipboard(item) {
      navigator.clipboard.writeText(item)
      this.$bvToast.toast('Copie réussie.', {
        title: null,
        noCloseButton: true,
        variant: 'success',
        solid: true
      })
    }
  },
  computed: {
    getCustomer() {
      return this.$store.getters.getCustomer
    },
  }
}
</script>

<style lang="scss">
.refreshDataButton {
  cursor: pointer;
}
</style>
