<template>
  <div class="chart">
    <apexchart
        :options="chartOptions"
        :series="requestsSeries"
        type="area"
        height="500"
        class="apexchart"
    >
    </apexchart>
  </div>
</template>

<script>
export default {
  name: "requestReports",
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
  },
  data: () => ({
    requestsCategories: [],
    requestsSeries: [],
    chartOptions: {
      title: {
        text: 'Nombre de rapport par utilisateurs',
        style: {
          fontSize: '25px',
        }
      },
      chart: {
        zoom: {
          enabled: true
        },
        type: 'area'
      },
      theme: {
        mode: 'light',
        palette: 'palette1',
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: "datetime",
        tickAmount: 1,
        labels: {
          format: 'dd-MM-yyyy'
        }
      },
      tooltip: {
        x: {
          format: 'dd-MM-yyyy'
        },
      }
    }
  }),
  created() {
    this.getRequestsData();
  },
  watch: {
    startDate: function () {
      this.getRequestsData();
    },
    endDate: function () {
      this.getRequestsData();
    }
  },
  methods: {
    getRequestsData: async function () {
      this.$store.state.requestNumberAndUsersReportsLoading = true
      const response = await this.$http.get(`/stats/report/numberAndUserByPeriod?startDate=` + this.startDate + `&endDate=` + this.endDate);
      this.requestsCategories = response.data.content.categories
      this.requestsSeries = response.data.content.series
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.requestsCategories
          }
        }
      }
      this.$store.state.requestNumberAndUsersReportsLoading = false
    },
  }
}
</script>

<style scoped>
.chart {
  margin: 0 auto;
  width: 100%;
}
</style>
