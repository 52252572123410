import Vue from "vue";
import { ref } from "vue";

export const company = [{
    id: 0,
    name: 'Toutes',
}, {
    id: 1,
    name: '360&1',
}, {
    id: 2,
    name: 'Full-Performance',
}];

export const useGestcam = () => {
    const http = Vue.prototype.$http;

    const gestcams = ref([]);

    async function fetchGestcams() {
        const response = await http.get('/gestcams')
        if (response.status === 200) {
            gestcams.value = response.data.content
            gestcams.value.unshift({
                id: 0,
                name: 'Tous les gestcams',
            })
        }
    }

    return {
        gestcams,
        fetchGestcams
    }
}