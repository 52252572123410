<template>

  <div class="multi-client-container">
    <b-col sm="10" class="p-0">
      <multiselect class="multiselect-client"
                   v-model="selected"
                   :options="options"
                   :multiple="true"
                   @input="emitSelected"
                   @open="isOpen = true"
                   @close="isOpen = false"
                   :loading="isLoading"
                   :preserve-search="true"
                   :close-on-select="false"
                   :clear-on-select="false"
                   selectLabel="Selectionné"
                   deselectLabel="Désélectionné"
                   selectedLabel="Selectionné"
                   placeholder="Selectionner un client"
                   track-by="name"
                   label="name"
      >
        <template slot="selection" slot-scope="{ values, search, isOpen }">
        <span class="multiselect__single" v-if="values.length && !isOpen">
          {{ values.length }} sélectionnés
        </span>
        </template>
        <template slot="tag">{{ '' }}</template>
      </multiselect>
    </b-col>

    <b-col sm="2" class="ml-2 p-0" align-self="center">
      <b-button class="btn-primary" v-if="selected.length" variant="outline-primary" @click="clearSelected">
        <b-icon icon="x" style="font-size: 1.5rem;"/>
      </b-button>
      <b-button class="btn-primary" v-if="selected.length == 0" variant="outline-primary" @click="selectAll">
        <b-icon icon="plus" style="font-size: 1.5rem"/>
      </b-button>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "SelectorClientAmenitiz",
  data() {
    return {
      isOpen: false,
      isLoading: true,
      index: null,
      selected: [],
      maxClients: 0,
      options: [],
    };
  },
  async mounted() {
    await this.getAllClientsAmenitiz();
    this.selected = this.options;
    this.emitSelected();
    this.emitNumberMaxClients();
  },
  methods: {
    async getAllClientsAmenitiz() {
      this.isLoading = true;
      const response = await this.$http.get('/amenitiz/clients');
      for (let i = 0; i < response.data.content.length; ++i)
        this.options.push({
          index: i,
          name: response.data.content[i].Login
        });
      this.maxClients = response.data.content.length;
      this.isLoading = false;
    },
    emitSelected() {
      this.$emit('selected', this.selected.map(e => e.name));
    },

    emitNumberMaxClients() {
      this.$emit('maxClients', this.maxClients);
    },

    clearSelected() {
      this.selected = [];
      this.emitSelected();
    },

    selectAll() {
      this.selected = this.options;
      this.emitSelected();
      this.emitNumberMaxClients();
    }
  }
}
</script>

<style scoped>
.multi-client-container {
  margin-top: 1.75rem;
  display: flex;
}

</style>
