<template>
  <b-breadcrumb :items="items"></b-breadcrumb>
</template>

<script>
export default {
	name: 'Breadcrumb',
	props: {
		items: Array
	}
}
</script>

<style lang="scss">

</style>
