<template>
  <div>
    <b-col md="8" offset-md="2">
      <h3>Configuration revue de compte</h3>
      <h5>Configurez ici les valeurs et objectifs de calibrage de vos comptes n'étant pas à la performance</h5>
      <br/>
      <b-row>
        <b-col offset="8" md="4">
          <b-form-group
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
          >
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Recherche"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h5 v-text="'Comptes configurés'"></h5>
          <b-table
              striped hover
              empty-text="Aucune donnée n'a été récupéré"
              :items="config"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              stacked="sm"
              show-empty
              small
          >
            <template #cell(dl)="data">
              <div @click="deleteRow(data.index)">
                <b-icon icon="trash"></b-icon>
              </div>
            </template>
            <template #cell(Login)="data">
              <multiselect v-model="data.item.Login" :options="siteNames" :multiple="false"
                           :close-on-select="true" :clear-on-select="false" :preserve-search="false"
                           placeholder="Choisissez un site" :preselect-first="true" :show-labels="false"/>
            </template>
            <template v-slot:cell(g_vc)="row">
              <b-form-input v-model="row.item.g_vc"/>
            </template>
            <template v-slot:cell(g_oc)="row">
              <b-form-input v-model="row.item.g_oc"/>
            </template>
            <template v-slot:cell(b_vc)="row">
              <b-form-input v-model="row.item.b_vc"/>
            </template>
            <template v-slot:cell(b_oc)="row">
              <b-form-input v-model="row.item.b_oc"/>
            </template>
            <template v-slot:cell(x_vc)="row">
              <b-form-input v-model="row.item.x_vc"/>
            </template>
            <template v-slot:cell(x_oc)="row">
              <b-form-input v-model="row.item.x_oc"/>
            </template>
            <template v-slot:cell(f_vc)="row">
              <b-form-input v-model="row.item.f_vc"/>
            </template>
            <template v-slot:cell(f_oc)="row">
              <b-form-input v-model="row.item.f_oc"/>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <b-button @click="saveConfig" type="button" variant="primary" block>Sauvegarder
          </b-button>
        </b-col>
        <b-col md="2" offset-md="8">
          <b-button style="float: right;"
                    v-b-popover.hover.bottom="'Paramétrer une nouvelle entrée de configuration.'"
                    title="Configuration"
                    variant="light"
                    @click="addRow()">
            <img style="width: 32px;" src="../assets/plus.png"/>
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    fields: [
      {key: 'dl', label: '#'},
      {key: 'Login', label: 'Client', sortable: true},
      {key: 'g_vc', label: 'Valeur calibrage Google'},
      {key: 'g_oc', label: 'Objectif calibrage Google'},
      {key: 'b_vc', label: 'Valeur calibrage Microsoft'},
      {key: 'b_oc', label: 'Objectif calibrage Microsoft'},
      {key: 'x_vc', label: 'Valeur calibrage Xandr'},
      {key: 'x_oc', label: 'Objectif calibrage Xandr'},
      {key: 'f_vc', label: 'Valeur calibrage Meta'},
      {key: 'f_oc', label: 'Objectif calibrage Meta'},
    ],
    filter: null,
    filterOn: [],
    filteredItems: [],
    config: [],
    sites: [],
    siteNames: [],
    selectedSite: null,
  }),
  async mounted() {
    await this.loadSites();
  },
  methods: {
    async loadSites() {
      const response = await this.$http.get(`/review/config`);
      this.config = response.data.content.config;
      this.sites = response.data.content.sites;
      this.siteNames = this.sites.map(e => e.Login);
      this.selectedSite = this.siteNames.length > 0 ? this.siteNames[0] : null;
    },
    addRow() {
      this.config.push({
        id: null,
        Login: this.selectedSite,
        site_id: this.sites.find(e => e.Login === this.selectedSite).id,
        g_vc: null,
        g_oc: null,
        b_vc: null,
        b_oc: null,
        x_vc: null,
        x_oc: null,
        f_vc: null,
        f_oc: null
      })
    },
    deleteRow(index) {
      this.config.splice(index, 1)
    },
    async saveConfig() {
      let error = null;
      this.config.find((elem, index) => {
        if (elem.Login === "" || !elem.Login)
          error = 'Une ligne n\'a pas de site associé';
        if (this.config.find((e, i) => i !== index && e.Login === elem.Login))
          error = 'Le site suivant a été détecté comme étant un doublon: ' + elem.Login;
        if ((elem.g_vc && !elem.g_oc) || (elem.g_oc && !elem.g_vc))
          error = 'Couple \'Valeur-Objectif\' incomplet pour Google: ' + elem.Login;
        if ((elem.b_vc && !elem.b_oc) || (elem.b_oc && !elem.b_vc))
          error = 'Couple \'Valeur-Objectif\' incomplet pour Microsoft: ' + elem.Login;
        if ((elem.x_vc && !elem.x_oc) || (elem.x_oc && !elem.x_vc))
          error = 'Couple \'Valeur-Objectif\' incomplet pour Xandr: ' + elem.Login;
        if ((elem.f_vc && !elem.f_oc) || (elem.f_oc && !elem.f_vc))
          error = 'Couple \'Valeur-Objectif\' incomplet pour Meta: ' + elem.Login;
        if (!elem.g_oc && !elem.g_vc && !elem.b_oc && !elem.b_vc && !elem.x_oc && !elem.x_vc && !elem.f_vc && !elem.f_oc)
          error = 'La configuration du site suivant est vide: ' + elem.Login;
        return error !== null;
      });
      if (error)
        this.$bvToast.toast(error, {
          title: null,
          noCloseButton: true,
          variant: 'danger',
          solid: true
        })
      else {
        this.config.forEach((value, id) => {
          try {
            this.config[id].site_id = this.sites.find(s => s.Login === this.config[id].Login).id;
            for (const index in this.config[id])
              if (index !== 'Login' && typeof this.config[id][index] === 'string' || this.config[id][index] instanceof String)
                this.config[id][index] = +this.config[id][index].replaceAll(',', '.');
          } catch (_) {
            this.$bvToast.toast(`Le traitement a échoué pour ${value.Login}, merci de le supprimer si le site n'est plus actif`, {
              title: null,
              noCloseButton: true,
              variant: 'danger',
              solid: true
            })
          }
        });
        this.$http.post(`/review/config`, this.config)
            .then(() => {
              this.$bvToast.toast("La configuration est enregistrée", {
                title: null,
                noCloseButton: true,
                variant: 'success',
                solid: true
              })
            })
            .catch((e) => {
              console.error(e)
              this.$bvToast.toast("La sauvegarde a échouée", {
                title: null,
                noCloseButton: true,
                variant: 'danger',
                solid: true
              })
            })
      }
    }
  },
}
</script>
