<template>
  <div>
    <apexchart class="apexchart"
               height="350"
               :options="this.chartOption"
               :series="chartSerie"/>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "turnoverGenerated",
  data: () => ({
    request: '/amenitiz/per-day',
    chartOption: {
      chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Chiffre d\'affaire généré par jour',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      xaxis: {
        type: 'datetime',
        categories: [],
      }
    },
    chartSerie: [],
  }),

  props: {
    switchParent: {
      type: Boolean,
      default: false
    },
    specificClients: {
      type: Array,
      default: () => []
    },
    date: {
      type: Object,
      default: () => ({
        startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      })
    }
  },

  async mounted() {
    await this.getTurnOverGenerated();
  },

  computed: {
    checked() {
      return this.switchParent;
    }
  },

  watch: {
    specificClients: async function (val) {
      this.specificClients = val;
      await this.getTurnOverGenerated();
    },

    checked: async function () {
      await this.handleCheckValue();
    },

    date: {
      handler: async function () {
        await this.getTurnOverGenerated();
      },
      deep: true
    }
  },

  methods: {
    async handleCheckValue(shouldTurnOverBeGenerated = true) {
      if (this.checked) {
        this.chartOption.title.text = 'Chiffre d\'affaire généré par semaine';
        this.request = '/amenitiz/per-week';
      } else {
        this.chartOption.title.text = 'Chiffre d\'affaire généré par jour';
        this.request = '/amenitiz/per-day';
      }
      this.chartOption = {
        ...this.chartOption
      }
      if (shouldTurnOverBeGenerated)
        await this.getTurnOverGenerated();
      if (!this.checked)
        this.$emit('switch', this.checked);
    },

    async getTurnOverGenerated() {
      await this.handleCheckValue(false);
      const response = await this.$http.post(this.request, {
        isChart: true,
        startDate: this.date.startDate,
        endDate: this.date.endDate,
        specificClients: this.specificClients
      });

      if (response && response.data && response.data.content && this.specificClients.length > 0) {
        if (response.data.content.prices)
          this.chartSerie = [{
            name: 'Chiffre d\'affaire',
            data: response.data.content.prices
          }];
        if (response.data.content.xaxis)
          this.chartOption.xaxis.categories = response.data.content.xaxis;
        this.chartOption = {
          ...this.chartOption
        };
      } else {
        this.chartSerie = [];
        this.chartOption.xaxis.categories = [];
        this.chartOption = {
          ...this.chartOption
        };
      }

    },
  },

}
</script>

<style scoped>

</style>
