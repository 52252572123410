<template>
  <div>
    <Spinner v-if="loading"/>
    <div v-else>
      <b-row align-v="center">
        <b-col md="10" offset-md="1" class="text-center">
          <apexchart
              :options="chartOptions"
              :series="chartData"
              height="860"
              type="treemap"
              class="apexchart"
          ></apexchart>
        </b-col>
        <b-col md="1">
          <b-button @click="startAnalysis()" variant="btn btn-primary" class="mb-2">Lancer l'analyse</b-button>
          <b-button @click="clearArchives()" variant="btn btn-warning">Nettoyer les archives</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Database',
  components: {},
  data: () => ({
    name: "Base de données",
    loading: true,
    chartData: [],
    chartOptions: {
      chart: {
        type: 'treemap',
        toolbar: {
          show: false,
        },
      },
      title: {
        text: 'Utilisation de la base de données',
        style: {
          fontSize: '25px',
        }
      },
      dataLabels: {
        enabled: true,
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value + ' GB';
          }
        },
      },
      subtitle: {
        align: 'left',
        text: 'Uniquement les schémas qui occupent au minimum 0.5 GB d\'espace disque',
        style: {
          fontSize: '18px',
        },
      }
    }
  }),
  async mounted() {
    const result = await this.$http.get(`/stats/treemap-schema-analysis`)
    this.chartData = result.data.content.series
    this.loading = false
  },
  methods: {
    clearArchives() {
      this.$http.post(`/launcher/cleararchives`, {
        user: localStorage && localStorage.user ? localStorage.user : null,
        log: true
      }).then((response) => {
        if (response) {
          this.$bvToast.toast(response.data.content.replace('pour le client undefined.', '.'), {
            title: null,
            noCloseButton: true,
            variant: response.data.status === 'success' ? 'success' : 'danger',
            solid: true
          })
        }
      }).catch((error) => {
        if (error.response) {
          this.$bvToast.toast(error.response.data.content, {
            title: null,
            noCloseButton: true,
            variant: 'danger',
            solid: true
          })
        }
      })
    },
    startAnalysis() {
      this.$http.post(`/launcher/schemaanalysis`, {
        user: localStorage && localStorage.user ? localStorage.user : null,
        log: true
      }).then((response) => {
        if (response) {
          this.$bvToast.toast(response.data.content.replace('pour le client undefined.', '.'), {
            title: null,
            noCloseButton: true,
            variant: response.data.status === 'success' ? 'success' : 'danger',
            solid: true
          })
        }
      }).catch((error) => {
        if (error.response) {
          this.$bvToast.toast(error.response.data.content, {
            title: null,
            noCloseButton: true,
            variant: 'danger',
            solid: true
          })
        }
      })
    },
  }
  ,
  computed: {}
}
</script>

<style lang="scss">

</style>
