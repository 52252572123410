<template>
	<b-row class="vh-100 text-center" align-v="center">
		<b-col md="12">
			<div class="text-center">
				<b-spinner style="width: 5rem; height: 5rem;" variant="primary" label="Loading.."></b-spinner>
        <b-col v-if="text" cols="12" class="mt-4 h2 text-primary font-weight-light" style="letter-spacing: 0.2rem;">
          Chargement...
        </b-col>
			</div>
		</b-col>
	</b-row>
</template>

<script>
export default {
	name: 'Spinner',
  props: {
    text: Boolean,
  },
}
</script>

<style scoped>
span, div {
  color: var(--active-color) !important;
}

.text-primary {
  margin-inline: 0.5rem;
}

</style>