<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useApi } from '@/composables/useApi';
import { useToast } from '@/composables/useToast';
import store from "@/store";

const api = useApi();
const toast = useToast();

const dashtag_configuration = ref();
const navData = ref([]);
const navDataAll = ref([]);
const navDataNoGreen = ref([]);
const isLoading = ref(false);
const timer = ref(null);
const admin = ref(false);
const showGreenStatus = ref(false);
const configuredSite = ref({
  login: null,
  mainSiteCritical: null,
  mainSiteWarning: null,
  bookingEngineCritical: null,
  bookingEngineWarning: null,
  latestConversionCritical: null,
  latestConversionWarning: null,
  latestConversionAssignedCritical: null,
  latestConversionAssignedWarning: null,
  defaultCriticalDuration: 3,
  defaultWarningDuration: 1,
  savingConfiguration: false,
  loadingData: false
});

onMounted(async () => {
  store.commit("isLoading", true);
  api.get(`/users/infos`).then((response) => {
    admin.value = response && response.data && response.data.content && response.data.content.rank === 3;
  });
  timer.value = setInterval(async () => {
    await getNavData();
  }, 5 * 60 * 1000);
  await getNavData();
  store.commit("isLoading", false);
});

onBeforeUnmount(() => {
  clearInterval(timer.value);
});

async function toggleGreenStatus(greenStatus) {
  showGreenStatus.value = greenStatus;
  if(showGreenStatus.value) navData.value = navDataAll.value;
  else navData.value = navDataNoGreen.value;
}

async function getNavData() {
  const { data } = await api.get('/customers/tag-status');
  navDataAll.value = data;
  if(showGreenStatus.value) navData.value = navDataAll.value;
  else {
    navDataNoGreen.value = navDataAll.value.filter(nav => nav.main_status !== 2);
    navData.value = navDataNoGreen.value;
  }
}

async function disableAlertStatus(siteId) {
  if(!admin.value) return;
  const response = await api.post(`/customers/${siteId}/alerts-status`, {
    status: 0
  });
  navData.value = navData.value.filter(nav => nav.id !== siteId);
  toast.toast(response.data.content, {
    title: null,
    noCloseButton: true,
    variant: response.data.status === 'error' ? 'danger' : 'success',
    solid: true
  });
}

async function getSiteConfiguration() {
  configuredSite.loadingData = true;
  const response = await api.get(`/customers/tag-configuration/${configuredSite.login}`);
  if (response.status === 200 && response.data && Object.keys(response.data).length > 0) {
    configuredSite.value.mainSiteCritical = response.data.main_site_critical;
    configuredSite.value.mainSiteWarning = response.data.main_site_warning;
    configuredSite.value.bookingEngineCritical = response.data.booking_engine_critical;
    configuredSite.value.bookingEngineWarning = response.data.booking_engine_warning;
    configuredSite.value.latestConversionCritical = response.data.latest_conversion_critical;
    configuredSite.value.latestConversionWarning = response.data.latest_conversion_warning;
    configuredSite.value.latestConversionAssignedCritical = response.data.latest_conversion_assigned_critical;
    configuredSite.value.latestConversionAssignedWarning = response.data.latest_conversion_assigned_warning;
  } else {
    configuredSite.value.mainSiteCritical = configuredSite.value.defaultCriticalDuration;
    configuredSite.value.mainSiteWarning = configuredSite.value.defaultWarningDuration;
    configuredSite.value.bookingEngineCritical = configuredSite.value.defaultCriticalDuration;
    configuredSite.value.bookingEngineWarning = configuredSite.value.defaultWarningDuration;
    configuredSite.value.latestConversionCritical = configuredSite.value.defaultCriticalDuration;
    configuredSite.value.latestConversionWarning = configuredSite.value.defaultWarningDuration;
    configuredSite.value.latestConversionAssignedCritical = configuredSite.value.defaultCriticalDuration;
    configuredSite.value.latestConversionAssignedWarning = configuredSite.value.defaultWarningDuration;
  }
  configuredSite.loadingData = false;
}

async function configureAlert(login) {
  configuredSite.login = login;
  await getSiteConfiguration();
  dashtag_configuration.value.show();
}

async function exemptAlert(login) {
  api.get(`/customers/tag-configuration/exempt/${login}`).then(() => {
    const index = navData.value.findIndex(n => n.login === login);
    if (index > -1) {
      navData.value.splice(index, 1);
    }
    toast.toast('Les alertes seront ignorées pendant une semaine.', {
      title: null,
      noCloseButton: true,
      variant: 'success',
      solid: true
    });
  }).catch((e) => {
    toast.toast(e, {
      title: null,
      noCloseButton: true,
      variant: 'danger',
      solid: true
    });
  });
}

function getStatusBadge(value) {
  return value === 0 ? 'danger' : value === 1 ? 'warning' : 'success';
}

function displayDate(value, critical) {
  return value ? value : `> ${critical} jours`;
}

function valueOrDefault(value = null, defaultValue = null) {
  return value ? value : defaultValue;
}

function niceifyText(text, length = 21) {
  if (text.length > length) {
    return text.slice(0, length) + '...';
  } else {
    return text;
  }
}

async function saveSiteConfiguration() {
  configuredSite.value.savingConfiguration = true;
  const response = await api.post(`/customers/tag-configuration/${configuredSite.login}`, {
    mainSiteCritical: valueOrDefault(configuredSite.value.mainSiteCritical, configuredSite.value.defaultCriticalDuration),
    mainSiteWarning: valueOrDefault(configuredSite.value.mainSiteWarning, configuredSite.value.defaultWarningDuration),
    bookingEngineCritical: valueOrDefault(configuredSite.value.bookingEngineCritical, configuredSite.value.defaultCriticalDuration),
    bookingEngineWarning: valueOrDefault(configuredSite.value.bookingEngineWarning, configuredSite.value.defaultWarningDuration),
    latestConversionCritical: valueOrDefault(configuredSite.value.latestConversionCritical, configuredSite.value.defaultCriticalDuration),
    latestConversionWarning: valueOrDefault(configuredSite.value.latestConversionWarning, configuredSite.value.defaultWarningDuration),
    latestConversionAssignedCritical: valueOrDefault(configuredSite.value.latestConversionAssignedCritical, configuredSite.value.defaultCriticalDuration),
    latestConversionAssignedWarning: valueOrDefault(configuredSite.value.latestConversionAssignedWarning, configuredSite.value.defaultWarningDuration),
  });
  toast.toast(null, {
    title: null,
    noCloseButton: true,
    variant: response.status === 'error' ? 'danger' : 'success',
    solid: true
  });
  if (response.data && Object.keys(response.data).length > 0) {
    const currentNavData = navData.value.findIndex(n => n.login === configuredSite.login);
    navData.value[currentNavData] = response.data;
  }
  configuredSite.value.savingConfiguration = false;

  dashtag_configuration.value.hide();
}
</script>

<template>
  <div class="logs-view-container">
    <div class="d-flex align-items-center">
      <h2 class="flex-fill">DashTag</h2>
    </div>
    <b-row class="d-flex flex-column">
      <div v-if="!isLoading">
        <div class="text-center my-5" v-if="!navDataNoGreen.length">
          <div class="status-badge success mb-2"/>
          <div>Aucune alerte en cours</div>
          <b-button class="mt-2" variant="primary" @click="toggleGreenStatus(!showGreenStatus)">{{ showGreenStatus ? 'Voir moins' : 'Voir plus' }}</b-button>
        </div>
        <div class="nav-data-container">
          <div v-for="row in navData" class="nav-card d-flex" :class="[row.main_status === 0 ? 'card-border-danger' : row.main_status === 1 ? 'card-border-warning' : 'card-border-success']" :key="row.login">
            <div class="card__header">
              <div class="card__header-content flex-fill">
                <p class="title">{{ niceifyText(row.login) }}</p>
                <p class="subtitle">{{ niceifyText(row.url) }}</p>
                <p class="creation-date">Créé le {{ row.site_creation_date }}</p>
              </div>
              <div v-if="admin" class="card__header-icon" @click="disableAlertStatus(row.id)" v-b-tooltip.top="'Désactiver les alertes pour le client'">
                <b-icon scale="0.8" icon="eye-slash-fill"></b-icon>
              </div>
              <div v-if="admin" class="card__header-icon" @click="configureAlert(row.login)" v-b-tooltip.top="'Configurer l\'alerte'">
                <b-icon scale="0.8" icon="tools"></b-icon>
              </div>
              <div v-if="admin" class="card__header-icon" @click="exemptAlert(row.login)" v-b-tooltip.top="'Exempter pendant une semaine'">
                <b-icon scale="0.8" icon="flag-fill"></b-icon>
              </div>
            </div>
            <div class="card__content">
              <table>
                <tr>
                  <th>Site principal</th>
                  <th v-if="row.has_booking_engine">Moteur de réservation</th>
                </tr>
                <tr>
                  <td><div class="status-badge" :class="[getStatusBadge(row.main_site_status)]"></div></td>
                  <td v-if="row.has_booking_engine"><div class="status-badge" :class="[getStatusBadge(row.booking_sites_status)]"></div></td>
                </tr>
                <tr>
                  <td>{{ displayDate(row.main_site, row.main_site_critical) }}</td>
                  <td v-if="row.has_booking_engine">{{ displayDate(row.booking_sites, row.booking_engine_critical) }}</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th>Dernière conversion</th>
                  <th>Dernière conversion attribuée</th>
                </tr>
                <tr>
                  <td><div class="status-badge" :class="[getStatusBadge(row.last_roi_status)]"></div></td>
                  <td><div class="status-badge" :class="[getStatusBadge(row.last_roi_company_status)]"></div></td>
                </tr>
                <tr>
                  <td>{{ displayDate(row.last_roi, row.latest_conversion_critical) }}</td>
                  <td>{{ displayDate(row.last_roi_company, row.latest_conversion_assigned_critical) }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <Spinner />
      </div>
    </b-row>
    <b-modal size="lg" ref="dashtag_configuration" centered scrollable hide-footer title="Configuration">
      <h3>{{ configuredSite.login }}</h3>
      <div v-if="!configuredSite.loadingData">
        <table class="text-left">
          <tr>
            <th></th>
            <th class="p-2 text-center"><div class="status-badge danger"></div> Délai maximum critique (jours)</th>
            <th class="p-2 text-center"><div class="status-badge warning"></div> Délai maximum préventif (jours)</th>
          </tr>
          <tr>
            <th class="p-2">Site principal</th>
            <td class="p-2"><b-form-input v-model="configuredSite.mainSiteCritical" type="number"></b-form-input></td>
            <td class="p-2"><b-form-input v-model="configuredSite.mainSiteWarning" type="number"></b-form-input></td>
          </tr>
          <tr>
            <th class="p-2">Moteur de réservation</th>
            <td class="p-2"><b-form-input v-model="configuredSite.bookingEngineCritical" type="number"></b-form-input></td>
            <td class="p-2"><b-form-input v-model="configuredSite.bookingEngineWarning" type="number"></b-form-input></td>
          </tr>
          <tr>
            <th class="p-2">Dernière conversion</th>
            <td class="p-2"><b-form-input v-model="configuredSite.latestConversionCritical" type="number"></b-form-input></td>
            <td class="p-2"><b-form-input v-model="configuredSite.latestConversionWarning" type="number"></b-form-input></td>
          </tr>
          <tr>
            <th class="p-2">Dernière conversion attribuée</th>
            <td class="p-2"><b-form-input v-model="configuredSite.latestConversionAssignedCritical" type="number"></b-form-input></td>
            <td class="p-2"><b-form-input v-model="configuredSite.latestConversionAssignedWarning" type="number"></b-form-input></td>
          </tr>
        </table>
        <b-button class="btn btn-success mt-2" block :disabled="configuredSite.savingConfiguration" @click="saveSiteConfiguration()">
          <b-icon icon="save" font-scale="0.9"></b-icon> Enregistrer
        </b-button>
      </div>
      <div v-else>
        Chargement de la configuration...
      </div>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">
.status-badge {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 auto;

  &.success {
    background-color: var(--color-success);
  }
  &.warning {
    background-color: var(--color-warning);
  }
  &.danger {
    background-color: var(--color-danger);
  }
}
.nav-data-container {
  display: grid;
  margin: 15px;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

@media (min-width: 2201px) {
  .nav-data-container {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 2200px) {
  .nav-data-container {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 2000px) {
  .nav-data-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1600px) {
  .nav-data-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1300px) {
  .nav-data-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 900px) {
  .nav-data-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.nav-card {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-secondary);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  row-gap: 20px;
  overflow: hidden;
}
.nav-card p {
  margin: 0;
  line-height: 1;
}
.card__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 10px;

  .card__header-content {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
  .card__header-icon {
    font-size: 23px;
    cursor: pointer;
    background-color: var(--background-color-primary-hover);
    padding:  1px 7px;
    border-radius: 8px;
    transition: 0.3s background-color;

    &:hover {
      background-color: var(--background-color-secondary-hover);
    }
  }
}
.card__header .title {
  font-size: 20px;
  font-weight: bold;
}
.card__header .subtitle {
  font-size: 15px;
  font-weight: bold;
  color: var(--text-third-color);
}
.card__header .creation-date {
  font-size: 13px;
  font-weight: bold;
  color: var(--text-third-color);
}
.card__content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.card-border-success {
  border: 3px solid var(--color-success);
}
.card-border-warning {
  border: 3px solid var(--color-warning);
}
.card-border-danger {
  border: 3px solid var(--color-danger);
}

table {
  background-color: var(--background-color-primary-hover);
  text-align: center;
  width: 100%;

  tr {
    border-color: var(--color-table-border);
    border-style: solid;
    border-width: 0 2px 0 2px;
  }
  tr:last-of-type {
    border-width: 0 2px 2px 2px;
  }
  tr:first-of-type {
    border-width: 2px;
  }
  th {
    padding: 8px 0;
    font-size: 16px;
  }
  td {
    padding: 5px 7px;
    text-align: center;
  }
}
</style>
