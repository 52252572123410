<template>
  <div>
    <Spinner v-show="isStatsLoading"/>
    <b-col lg="4">
      <b-button @click="screenshot" variant="light">
        <img style="width: 32px;" src="../assets/screenshot.png" alt="screenshot"/>
      </b-button>
    </b-col>

    <b-row align-h="center" class="date-group" md="12">
      <b-col lg="4">
        <b-form-group
            id="input-group-start-date"
            label="Date de début :"
            label-for="input-start-date">
          <b-form-datepicker id="input-start-date" v-model="startDate"></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group
            id="input-group-end-date"
            label="Date de fin :"
            label-for="input-end-date">
          <b-form-datepicker id="input-end-date" v-model="endDate"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>

    <b-tabs pills card lazy justified>
      <b-tab title="Statistiques des demandes">
        <b-row class="chart" md="12">
          <b-col md="6">
            <lineChart :startDate="startDate" :endDate="endDate"></lineChart>
          </b-col>
          <b-col md="6">
            <treeMap :startDate="startDate" :endDate="endDate"></treeMap>
          </b-col>

        </b-row>
        <b-row class="chart" md="12">
          <b-col md="6">
            <requestsPerMonthsChart></requestsPerMonthsChart>
          </b-col>
          <b-col md="6">
            <pieChart :startDate="startDate" :endDate="endDate"></pieChart>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Statistiques des rapports">
        <b-row class="chart" md="12">
          <b-col md="6">
            <lineNumberAndTypeReportsChart :startDate="startDate" :endDate="endDate"></lineNumberAndTypeReportsChart>
          </b-col>

          <b-col md="6">
            <lineNumberAndUserReportsChart :startDate="startDate" :endDate="endDate"></lineNumberAndUserReportsChart>
          </b-col>
        </b-row>

        <b-row class="chart" md="12">
          <b-col md="6">
            <pieTypesReportsChart :startDate="startDate" :endDate="endDate"></pieTypesReportsChart>
          </b-col>
          <b-col md="6">
            <lineNumberReportsChart :startDate="startDate" :endDate="endDate"></lineNumberReportsChart>
          </b-col>
        </b-row>

      </b-tab>
    </b-tabs>


  </div>
</template>

<script>
import requestsPerMonthsChart from "@/components/charts/requestsPerMonthsChart";
import pieChart from "@/components/charts/pieChart";
import lineChart from "@/components/charts/requestChart"
import treeMap from "@/components/charts/treeMap";
import lineNumberReportsChart from "@/components/charts/requestNumberReports";
import lineNumberAndUserReportsChart from "@/components/charts/requestNumberAndUsersReports";
import lineNumberAndTypeReportsChart from "@/components/charts/requestNumberAndTypeReports";
import pieTypesReportsChart from "@/components/charts/requestTypesReports";
import dayjs from "dayjs";
import html2canvas from 'html2canvas';

export default {
  name: 'Stats',

  data: () => ({
    name: "Statistiques",
    isAdmin: null,
    startDate: null,
    endDate: null,
    requestsSeries: null,
    requestsCategories: null,
    requestsType: null,
  }),
  created() {
    this.endDate = dayjs().format('YYYY-MM-DD')
    this.startDate = dayjs(this.endDate).subtract(1, 'month').format('YYYY-MM-DD')
  },
  methods: {
    screenshot: () => {
      html2canvas(document.body).then(canvas => {
        const dataURL = canvas.toDataURL('image/png');
        const anchor = document.createElement('a');
        anchor.href = dataURL;
        anchor.download = 'screenshot.png';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      });
    }
  },
  computed: {
    isStatsLoading: function () {
      return this.$store.getters.isStatsLoading
    }
  },
  components: {
    lineChart,
    treeMap,
    requestsPerMonthsChart,
    pieChart,
    lineNumberReportsChart,
    lineNumberAndUserReportsChart,
    pieTypesReportsChart,
    lineNumberAndTypeReportsChart
  }
}
</script>

<style scoped>
.chart {
  margin: 20px 20px 0 20px;
}
</style>
