<template>
  <div>
    <Spinner v-if="loading"/>
    <div v-else>
      <b-row>
        <b-col sm="6">
          <PeriodSelect @Date="date => {
                  this.date = date;
                  this.getData()
                }"/>
        </b-col>

        <b-col sm="4" style="padding-right: 0; padding-top: 1px">
          <SelectorClientAmenitiz
              @maxClients="maxClients => {
                this.maxClients = maxClients;
              }"
              @selected="selected => {
                this.specificClients = selected;
                this.loading = true;
                this.getData();
                this.loading = false;
            }"/>
        </b-col>

        <b-col sm="2" style="padding: 5px 0 0 0">
          <div class="switchButtonContainer">
            <span v-if="!checked" class="font-weight-bold" style="margin-right: 10px">Jour</span>
            <span v-else class="text-muted font-weight-bold" style="margin-right: 10px">Jour</span>
            <b-form-checkbox v-model="checked" name="check-button" switch size="md"/>
            <span v-if="checked" class="font-weight-bold">Semaine</span>
            <span v-else class="text-muted font-weight-bold">Semaine</span>
          </div>
        </b-col>
      </b-row>

      <b-tabs pills card lazy justified>
        <b-tab title="Réservations et prospects">
          <b-row>
            <b-col>
              <div>
                <b-icon style="cursor: pointer" icon="download" @click="download(bookings)"
                        v-b-popover.hover.top="'Télécharger'"/>
                <b-table
                    striped hover
                    id="bookings-table"
                    empty-text="Aucune réservation n'a été récupérée"
                    :items="bookings"
                    :fields="fieldsBookings"
                    :current-page="currentPage"
                    :per-page="perPage"
                    stacked="sm"
                    show-empty
                    small
                >
                  <template #cell(url)="data">
                    <div class="c_ellipsis" v-b-popover.hover.top="data.item.url">{{ data.item.url }}</div>
                  </template>
                  <template #cell(roi_ref)="data">
                    <div class="c_ellipsis" v-b-popover.hover.top="data.item.roi_ref">{{ data.item.roi_ref }}</div>
                  </template>
                  <template #cell(price_ex_vat)="data">
                    {{ data.item.price_ex_vat }}€
                  </template>
                  <template #cell(price)="data">
                    {{ data.item.price }}€
                  </template>
                  <template #cell(commission)="data">
                    {{ data.item.commission }}€
                  </template>
                  <template #cell(commission_amz)="data">
                    {{ data.item.commission_amz }}€
                  </template>
                  <template #cell(commission_360)="data">
                    {{ data.item.commission_360 }}€
                  </template>
                </b-table>
                <b-pagination
                    align="center"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="sm"
                ></b-pagination>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <Prospects :date="date" :switchParent="checked"/>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Chiffre d'affaire">
          <b-row>
            <b-col>
              <DistributionOfCommissions :maxClients="maxClients" :specificClients="specificClients" :date="date"
                                         :switchParent="checked"/>
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="8">
              <TurnoverGenerated :specificClients="specificClients" :date="date" :switchParent="checked"/>
            </b-col>
            <b-col lg="4">
              <TotalOfCommission :date="date"/>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

    </div>
  </div>
</template>

<script>
import PeriodSelect from "@/components/PeriodSelect";
import SelectorClientAmenitiz from '@/components/SelectorClientAmenitiz';

import DistributionOfCommissions from '@/components/charts/distributionOfCommissions';
import TurnoverGenerated from '@/components/charts/turnoverGenerated';
import TotalOfCommission from '@/components/charts/totalOfCommission';
import Prospects from "@/components/charts/prospects";

import dayjs from "dayjs";

export default {
  name: 'Amenitiz',
  components: {
    PeriodSelect,
    SelectorClientAmenitiz,
    DistributionOfCommissions,
    Prospects,
    TurnoverGenerated,
    TotalOfCommission
  },
  data: () => ({
    name: "Suivi",
    loading: true,
    timer: null,
    bookings: [],
    specificClients: [],
    maxClients: 0,
    checked: false,
    dataChartsPerDay: [],
    dataChartsPerWeek: [],
    perDay: [],

    date: {
      startDate: null,
      endDate: null,
    },
    fieldsBookings: [
      {key: 'url', label: 'Client', sortable: true},
      {key: 'roi_ref', label: '#', sortable: false},
      {key: 'price', label: 'TTC', sortable: true},
      {key: 'commission', label: 'Commission', sortable: true},
      {key: 'commission_amz', label: 'Amenitiz', sortable: true},
      {key: 'commission_360', label: '360&1', sortable: true},
      {key: 'date', label: 'Date', sortable: true},
    ],
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
  }),

  async mounted() {
    this.loading = true
    this.date.startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
    this.date.endDate = dayjs().format('YYYY-MM-DD');

    await this.getData()
    this.loading = false
  },

  methods: {
    async getData() {
      const responseBookings = await this.$http.post(`/amenitiz/`, {
        startDate: this.date.startDate,
        endDate: this.date.endDate,
        specificClients: this.specificClients
      });
      if (responseBookings && responseBookings.data && responseBookings.data.content) {
        if (this.specificClients.length === 0) {
          this.bookings = [];
          this.totalRows = 0;
        } else {
          this.bookings = responseBookings.data.content
          this.totalRows = this.bookings.length
        }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    download(arr) {
      const array = [Object.keys(arr[0])].concat(arr)
      const data = array.map(it => {
        return Object.values(it).toString()
      }).join('\n');

      const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
      const url = URL.createObjectURL(blob);

      const e = document.createElement('a');
      e.href = url;
      e.setAttribute('download', 'export.csv');
      e.click();
    },
  },
  watch: {
    startDate: function (newValue) {
      if (dayjs(newValue).isAfter(this.endDate)) {
        this.endDate = dayjs(newValue).add(1, 'month').format('YYYY-MM-DD');
      }
    },
    endDate: function (newValue) {
      if (dayjs(newValue).isBefore(this.startDate)) {
        this.startDate = dayjs(newValue).subtract(1, 'month').format('YYYY-MM-DD');
      }
    }
  },
  computed: {},
  beforeDestroy() {
    // clearInterval(this.timer)
  },
}
</script>

<style lang="scss" scoped>
.c_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

.switchButtonContainer {
  width: 100%;
  display: flex;
  margin-top: 1.75rem;
  align-items: center;
}

</style>
