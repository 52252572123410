import Vue from "vue";
import { ref } from "vue";

export const useClients = () => {
    const http = Vue.prototype.$http;

    const clients = ref([]);
    const loading = ref(false);

    async function fetchClients(company, gestcam) {
        loading.value = true;
        const response = await http.get(`/clients?company=${company}&gestcam=${gestcam}`)
        if (response.status === 200) {
            clients.value = response.data.content.map(client => {
                return {
                    id: client.id,
                    login: client.login,
                }
            })
            clients.value.unshift({
                id: 0,
                login: 'Tous les clients',
            })
        }
        loading.value = false;
    }

    return {
        loading,
        clients,
        fetchClients
    }
}