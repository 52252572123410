<template>
  <div class="chart">
    <apexchart
        :options="chartOptions"
        :series="requestsSeries"
        height="550"
        type="area"
        class="apexchart"
    >
    </apexchart>
  </div>
</template>

<script>


export default {
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
  },
  data: () => ({
    requestsCategories: [],
    requestsSeries: [],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false
        },
        type: 'area'
      },
      title: {
        text: 'Stats des demandes sur la période',
        style: {
          fontSize: '25px',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        itemMargin: {
          vertical: 5
        },
      },
      xaxis: {
        type: "datetime",
        tickAmount: 10,
        labels: {
          format: 'dd-MM'
        }
      }
    }
  }),
  created() {
    this.getRequestsData();
  },
  watch: {
    startDate: function () {
      this.getRequestsData();
    },
    endDate: function () {
      this.getRequestsData();
    }
  },
  methods: {
    getRequestsData: async function () {
      this.$store.state.requestChartLoading = true
      const response = await this.$http.post(`/stats/requests`, {
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.requestsCategories = response.data.content.categories
      this.requestsSeries = response.data.content.series
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.requestsCategories
          }
        }
      }
      this.$store.state.requestChartLoading = false
    },
  },
}
</script>

<style scoped>
.chart {
  margin: 0 auto;
  width: 100%;
}
</style>
