<template>
  <div v-if="env === 'production' && todayAnniversary" class="p-1 text-center text-uppercase label-indicator anniversaries">
    Souhaitons un bon anniversaire à <u>{{ todayAnniversary.name }}</u> (le {{ todayAnniversary.day.format('DD/MM') }})
  </div>
</template>

<script>
import dayjs from "dayjs";
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export default {
  data: () => ({
    env: process.env.VUE_APP_ENV,
    today: dayjs(),
    limitMin: dayjs().subtract(2, 'days'),
    limitMax: dayjs().add(2, 'days'),
    anniversaries: [
      {
        name: 'Guillaume',
        day: dayjs('1996-01-12')
      },
      {
        name: 'Arthur',
        day: dayjs('2021-07-27')
      },
      {
        name: 'Fabrice',
        day: dayjs('2023-03-24')
      },
      {
        name: 'Leila',
        day: dayjs('2000-09-30')
      },
      {
        name: 'Linda',
        day: dayjs('2023-10-28')
      },
      {
        name: 'Domitille',
        day: dayjs('2023-04-09')
      },
      {
        name: 'Marta',
        day: dayjs('2023-07-11')
      },
      {
        name: 'Allen',
        day: dayjs('1996-03-09')
      },
      {
        name: 'Dino',
        day: dayjs('1993-06-07')
      },
      {
        name: 'Yves',
        day: dayjs('2023-03-23')
      },
      {
        name: 'Théo',
        day: dayjs('2023-12-29')
      },
      {
        name: 'Olivier',
        day: dayjs('1970-09-01')
      },
      {
        name: 'Florine',
        day: dayjs('1990-10-22')
      },
      {
        name: 'Hugo',
        day: dayjs('2001-01-08')
      },
      {
        name: 'Mathieu',
        day: dayjs('1998-04-05')
      },
      {
        name: 'Mohammed',
        day: dayjs('2023-11-15')
      }
    ],
    todayAnniversary: null,
  }),
  mounted() {
    this.getAnniversaries();
  },
  methods: {
    getAnniversaries() {
      for (const anniversary of this.anniversaries) {
        if (anniversary.day.isBetween(this.limitMin, this.limitMax, 'day', '[]')) {
          this.todayAnniversary = anniversary;
        }
      }
    }
  }
};
</script>

<style scoped>
.anniversaries {
  background: rgb(63,94,251);
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
}

.label-indicator {
  font-size: 8pt;
  letter-spacing: 0.25pt;
  color: white;
  font-weight: 500;
}
</style>
