<script setup>
import Vue, {ref, onMounted, computed} from 'vue';

const http = Vue.prototype.$http;

let reports = ref([]);
let report = ref(null);
let selectedReport = ref(0);
const perPage = ref(10);
const currentPage = ref(1);
const fields = ref([
  {key: 'site_id', label: '#', sortable: true},
  {key: 'company', label: 'Société', sortable: true},
  {key: 'login', label: 'Client', sortable: true},
  {key: 'url', label: 'URL', sortable: true},
  {key: 'ca', label: 'Chiffre d\'affaire (€)', sortable: true}
]);
const loading = ref(false);

onMounted(() => {
  loading.value = true;
  http.get(`/global-stats/list`)
      .then(({data}) => {
        reports.value = data.content;
        selectedReport.value = reports.value[0].value;
        loading.value = false;
        loadReport();
      })
      .catch(() => {
        loading.value = false;
      });
});

async function loadReport() {
  loading.value = true;
  const url = `/global-stats/data?id=${selectedReport.value}`;
  const {data} = await http.get(url);
  report.value = data.content
  loading.value = false;
}

function download(arr) {
  const array = [Object.keys(arr[0])].concat(arr);
  const data = array.map(it => {
    for (const key in it) {
      if (typeof it[key] === 'number')
        it[key] = it[key].toString().replace('.', ',');
      if (key === 'site_id')
        it[key] = 'W' + ('0000' + it[key]).slice(-4)
    }
    return Object.values(it).join(';');
  }).join('\n');

  const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
  const url = URL.createObjectURL(blob);

  const e = document.createElement('a');
  e.href = url;
  e.setAttribute('download', 'export.csv');
  e.click();
}

const totalRows = computed(() => {
  return report.value.length;
});

</script>

<template>
  <div class="container-fluid">
    <b-row class="mb-4">
      <b-col md="12">
        <h2>
          Chiffre d'affaire global
        </h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group label="Période sélectionnée :">
          <b-select v-model="selectedReport" :options="reports" @input="loadReport()">
            <template #first>
              <b-form-select-option :value="0" disabled>Choisir une période</b-form-select-option>
            </template>
          </b-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Par page :">
          <b-form-select size="sm" v-model="perPage" :options="[10, 25, 100, 1000]"
                         class="w-auto"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="6" class="text-right" style="margin-top: 50px">
        <b-icon style="cursor: pointer" icon="download" @click="download(report)"
                v-b-popover.hover.top="'Télécharger'"/>
      </b-col>
    </b-row>
    <b-row v-if="report">
      <b-col cols="12">
        <div style="overflow-x: auto;">
          <b-table striped hover class="text-monospace" style="font-size: .8rem" :items="report" :fields="fields"
                   :per-page="perPage" :current-page="currentPage" stacked="sm" show-empty small>
            <template #cell(site_id)="{ item }">
              {{ 'W' + ('0000' + item.site_id).slice(-4) }}
            </template>
            <template #cell(ca)="{ item }">
              {{ item.ca.toLocaleString('fr-FR') }}
            </template>
          </b-table>
        </div>
      </b-col>
      <b-col md="12">
        <div class="d-flex flex-row justify-content-center align-items-baseline">
          <b-pagination v-if="report.length > 0" align="center" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" last-number first-number size="sm"></b-pagination>
          <b-badge pill class="ml-4">
            {{ totalRows }} Résultat(s)
          </b-badge>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
