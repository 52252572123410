<script setup>
import { defineProps, ref, watch, onMounted } from "vue";
import { useAutoMatrix } from "@/composables/useAutoMatrix";
const { fetchFromEndpoint } = useAutoMatrix();
import Chart from "./Chart.vue";

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  chartInfos: {
    type: Object,
    required: true,
  },
});

const timeframeEnum = {
  0: 'Par an',
  1: 'Par mois',
  2: 'Par semaine',
  3: 'Par jour',
};

const lockedTimeframeList = ["Objectif mensuel"];

const selectedTimeframe = ref(1);

const loading = ref(false);
const error = ref(false);

const charts = ref(props.chartInfos.charts.map((chart) => {
  return {
    title: chart.title,
    series: [],
    xaxis: [],
    symbol: chart.symbol || props.chartInfos.symbol,
    max100: chart.max100,
  };
}));

const rateMode = ref(false);

async function fetchData(params) {
  loading.value = true;
  try {
    const endpointData = await fetchFromEndpoint(props.chartInfos.endpoint, params, selectedTimeframe.value, rateMode.value);
    charts.value.forEach((chart, index) => {
      chart.series = endpointData.series.filter((serie) => {
        return props.chartInfos.charts[index].series.includes("*") || props.chartInfos.charts[index].series.includes(serie.key) ||
          serie.key.startsWith(props.chartInfos.charts[index].series[0]);
      });
      chart.xaxis = endpointData.xaxis;
    });
    error.value = false;
    loading.value = false;
  } catch (e) {
    if (e.message !== "Canceled due to new request") {
      error.value = true;
      loading.value = false;
    }
  }
}

watch(
  () => props.params,
  async () => {
    await fetchData(props.params);
  },
  { deep: true }
);

watch(
  () => rateMode.value,
  async () => {
    await fetchData(props.params);
  },
);

watch(
  () => selectedTimeframe.value,
  async () => {
    await fetchData(props.params);
  },
  { deep: true }
);

onMounted(async () => {
  await fetchData(props.params);
});
</script>

<template>
  <div class="d-flex flex-column justify-center items-center">
    <div v-for="chart in charts" :key="chart.title">
      <h3>{{ chart.title }}</h3>
      <div v-if="!lockedTimeframeList.includes(chart.title)" class="timeformat-wrapper">
        <b-icon icon="arrow-up" class="timeformat-icon h3"
          @click="selectedTimeframe === 0 ? selectedTimeframe = 0 : selectedTimeframe--"
          :class="{ disabled: selectedTimeframe === 0 }" />
        <b-icon icon="arrow-down" class="timeformat-icon h3"
          @click="selectedTimeframe === 3 ? selectedTimeframe = 3 : selectedTimeframe++"
          :class="{ disabled: selectedTimeframe === 3 }" />
        <span class="mx-2">{{ timeframeEnum[selectedTimeframe] }}</span>
        <b-form-checkbox v-model="rateMode" switch v-if="chartInfos.rateMode">
          Afficher taux
        </b-form-checkbox>
      </div>
      <Chart :series="chart.series" :xaxis="chart.xaxis" :loading="loading" :title="chart.title" :error="error"
        :symbol="rateMode ? '%' : chart.symbol || chartInfos.symbol" :objectives="chartInfos.objectives"
        :max100="chart.max100" />
    </div>
  </div>
</template>

<style scoped>
.timeformat-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.timeformat-icon {
  cursor: pointer;
  border-radius: 50%;
  padding: 0.3rem;
  margin-bottom: 0;
  background-color: var(--accent-color);
  color: var(--text-secondary-color);

  &:hover {
    background-color: var(--text-secondary-color);
    color: #fff;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: var(--text-secondary-color);
    color: #fff;
    opacity: 0.2;
  }
}
</style>
