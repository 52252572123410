<template>
  <div>
    <b-row style="margin-top: 60px" align-v="center">
      <b-col md="8" offset="2">
        <b-row>
          <b-col>
            <h3>Générateur de signature</h3>
            <h5>Cet outil a pour but de vous fournir votre ou vos signature(s) et cela peu importe votre compte mail
              associé</h5>
            <p>Pour cela veuillez remplir les champs, sélectionnez l'entreprise, la marque ou le produit lié (champ
              'Template') et le tour est joué!</p>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col md="5">
            <b-form-group label="Nom Prénom">
              <b-form-input type="text" v-model="name" trim></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Titre">
              <b-form-input type="text" v-model="title" trim></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Équipe">
              <b-form-input type="text" v-model="team" trim></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Email">
              <b-form-input type="email" v-model="email" trim></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Numéro de téléphone">
              <b-form-tags type="tel" v-model="phones" placeholder="Taper le numéro puis presser entrée"
                           trim></b-form-tags>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Template">
              <b-form-select v-model="company" :options="companies"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row style="margin-top: 20px">
          <b-col md="5" style="min-width: 400px">
            <div id="toCopy">
              <sign-preview :name="name" :title="title" :team="team" :email="email" :phones="phones"
                            :company="company"/>
            </div>
            <div style="margin-top: 10px; margin-bottom: 10px">
              <b-button variant="primary" v-b-popover.hover.top="'Télécharger la signature'" @click="download()">
                <b-icon style="cursor: pointer" icon="download"/>
              </b-button>
            </div>
          </b-col>
          <b-col md="5">
            <video-player :options="videoOptions" :src="selected" v-if="selected === 'Gmail'" />
            <video-player :options="videoOptions" :src="selected" v-else />
            <div style="margin-top: 10px; margin-bottom: 10px">
              <b-form-select v-model="selected" :options="mailOptions"></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SignPreview from "@/components/Sign-preview";
import VideoPlayer from "@/components/VideoPlayer";

export default {
  name: 'sign-tool',
  data: () => ({
    name: "John Doe",
    title: "Stagiaire Senior",
    team: "Équipe technique",
    email: "john.doe@example.com",
    phones: ["(+33) 7XXXXXXXX"],
    companies: ['360&1', 'AI&QE', 'Full-Performance', 'Koorty', 'Statshop', 'Synchronext', 'Sytsem'],
    company: '360&1',
    selected: "Gmail",
    mailOptions: ["Gmail", "Thunderbird"],
    videoOptions: {
      autoplay: true,
      controls: true,
      fluid: true,
      sources: [
        {
          src: 'https://drop.datavault.tech/f.php?h=3iAuaIzs&p=1',
          type: 'video/mp4'
        },
        {
          src: 'https://drop.datavault.tech/f.php?h=1p6PrCrb&p=1',
          type: 'video/mp4'
        }
      ]
    },
  }),
  methods: {
    download: async () => {
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(document.getElementById("toCopy").innerHTML));
      element.setAttribute('download', 'signature.html');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
  components: {SignPreview, VideoPlayer}
}
</script>

<style scoped>

select {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
}

</style>
