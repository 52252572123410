<template>
  <div>
    <div v-if="!isLoading">
      <EnvIndicator/>
    </div>
    <div v-if="!isLoading">
      <Anniversaries/>
    </div>
    <b-container fluid id="app">
      <b-row class="mt-2" v-if="!isLoading">
        <b-col cols="2" v-if="getLocalStorage.auth">
          <Sidebar />
        </b-col>
        <b-col v-if="getBcItems && getBcItems.length > 0" cols="8">
          <Breadcrumb :items="getBcItems"/>
        </b-col>
        <b-col cols="auto" class="ml-auto">
          <ThemeButton/>
        </b-col>
      </b-row>
      <Spinner v-show="isLoading" v-bind:text="true"/>
      <router-view v-show="!isLoading" />
    </b-container>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.subtitle {
  font-size: 14px;
  font-weight: bold;
}

:root {
  --background-color-primary: #ffffff;
  --background-color-primary-hover: #ececec;
  --background-color-secondary: #f3f3f3;
  --background-color-secondary-hover: #e9e9e9;
  --accent-color: #cacaca;
  --text-primary-color: #131313;
  --text-primary-color-opacity: rgba(19,19,19,0.25);
  --text-secondary-color: #000000;
  --text-third-color: #595959;
  --text-white: white;
  --element-size: 4rem;
  --background-color-success: #c3e6cb;
  --background-color-warning: #ffe8a1;
  --background-color-danger: #f5c6cb;
  --background-color-info: #BEE5EB;
  --background-color-orange: rgba(255, 159, 64, 0.4);
  --background-color-gray: rgba(0, 0, 0, 0.1);
  --active-color: #007bff;
  --color-success: #2abc5b;
  --color-warning: #fcb813;
  --color-danger: #e33b3b;
  --color-table-border: #dddddd;
}

/* Define styles for the root window with dark - mode preference */
:root.dark-theme {
  --background-color-primary: #22272E !important;
  --background-color-primary-hover: #3C434D !important;
  --background-color-secondary: #2D333B !important;
  --background-color-secondary-hover: #464f5a !important;
  --accent-color: #3f3f3f;
  --text-primary-color: #ddd;
  --text-primary-color-opacity: rgba(221,221,221,0.25);
  --text-secondary-color: #BB86FC;
  --text-third-color: #c4c4c4;
  --text-white: white;
  --background-color-success: #346751;
  --background-color-warning: #c59846;
  --background-color-danger: #950101;
  --background-color-info: #06B2DC66;
  --background-color-orange: rgba(255, 159, 64, 0.4);
  --background-color-gray: rgba(179, 178, 178, 0.1);
  --active-color: #b153f1;
  --color-success: #1d9445;
  --color-warning: #e8ad1f;
  --color-danger: #a41c1c;
  --color-table-border: #252525;
}

body {
  background-color: var(--background-color-primary) !important;
  color: var(--text-primary-color);
}

select {
  cursor: pointer;
}

table {
  background-color: var(--background-color-secondary);
  color: var(--text-primary-color) !important;
  border: none !important;
}

table td,
table th {
  border: none !important;
}

tr:hover {
  color: var(--text-primary-color) !important;
}

table tbody tr {
  border-bottom: 0.1px solid var(--accent-color);
}

table tbody tr:last-child {
  border-bottom: none !important;
}

button, span.page-link {
  border: none !important;
}

.btn.btn-primary.collapsed > svg {
  color: white !important;
}

.btn.m-1.btn-primary {
  color: white !important;
}

button[type="button"].btn.btn-secondary {
  color: white !important;
}

.apexcharts-grid > rect:nth-child(2n) {
  fill: var(--background-color-secondary) !important;
}

a {
  color: var(--active-color) !important;
}

a:link {
  color: var(--active-color) !important;
}

.breadcrumb {
  background-color: var(--background-color-secondary) !important;
  color: var(--text-primary-color) !important;
}

.form-control, .custom-select, .custom-file-input, .custom-file-label {
  background: var(--background-color-secondary) !important;
  color: var(--text-primary-color) !important;
  border: 0.1px solid var(--text-primary-color-opacity) !important;
}

.custom-file-label::after {
  border-left: 0.1px solid var(--text-primary-color-opacity) !important;
  cursor: pointer;
  color: var(--text-primary-color) !important;
  background: var(--background-color-secondary) !important;
}

.modal-header .close {
  color: var(--text-primary-color) !important;
}

.btn-primary {
  color: var(--text-white);
  background-color: var(--active-color) !important;
}

.btn-primary:focus, .btn-primary:hover {
  color: var(--text-primary-color);
  box-shadow: none !important;
}

.btn-warning {
  color: var(--text-primary-color);
  background-color: var(--background-color-warning) !important;
}

.btn-warning:focus, .btn-warning:hover, .btn-warning:active {
  color: var(--text-primary-color);
  box-shadow: none !important;
}

.btn-danger.dropdown-toggle {
  color: var(--text-primary-color) !important;
}

.btn-danger {
  color: var(--text-primary-color);
  background-color: var(--background-color-danger) !important;
}

.btn-danger:focus, .btn-danger:hover, .btn-danger:active {
  color: var(--text-primary-danger);
  box-shadow: none !important;
}

.btn-success {
  color: var(--text-primary-color);
  background-color: var(--background-color-success) !important;
}

.btn-warning svg {
  color: var(--text-primary-color) !important;
}

.btn-success:focus, .btn-success:hover, .btn-success:active {
  color: var(--text-primary-danger);
  box-shadow: none !important;
}

#sidebar > header > button > svg {
  color: white !important;
}

.list-group-item.item.active {
  background-color: var(--active-color) !important;
  color: white !important;
}

.page-item.active .page-link {
  background-color: var(--active-color);
  box-shadow: none !important;
}

.page-item:not(.active) .page-link:hover {
  background-color: var(--text-primary-color-opacity);
  color: var(--text-primary-color) !important;
  box-shadow: none !important;
  border: none !important;
}

.btn-light {
  background-color: var(--background-color-secondary) !important;
  color: var(--text-primary-color) !important;
}

.multiselect, .multiselect__tags,
.multiselect__tags-wrap, .multiselect__single,
.multiselect__placeholder, .multiselect__content-wrapper,
.multiselect__option, .multiselect__content {
  background-color: var(--background-color-secondary);
  color: var(--text-primary-color) !important;
  border-radius: 0.25rem;
  border: none !important;
}

.multiselect__option {
  border-radius: 0 !important;
}

.multiselect {
  cursor: pointer;
  border: 0.1px solid var(--text-primary-color-opacity) !important;
}

.multiselect--active {
  border-radius: 0.25rem 0.25rem 0 0 !important;
}

.nav-tabs .nav-link {
  border: 0;
}

.nav-tabs .nav-link.active {
  background-color: var(--background-color-primary);
}

.multiselect__content-wrapper {
  overflow-x: hidden;
  border-radius: 0 0 0.25rem 0.25rem !important;
  border: 0.1px solid var(--text-primary-color-opacity) !important;
}

.multiselect__input, .multiselect__input::placeholder {
  background-color: var(--background-color-secondary) !important;
  color: var(--text-primary-color) !important;
}

span.multiselect__option--selected {
  background-color: #1e6b1e;
  color: white !important;
}

#input-end-date__dialog_ {
  background-color: var(--background-color-secondary) !important;
  color: var(--text-primary-color) !important;
  border: none !important;
}

#__BVID__30__calendar-grid_ > div.b-calendar-grid-body {
  color: var(--active-color) !important;
}

.dropdown-menu {
  background-color: var(--background-color-secondary) !important;
  color: var(--text-primary-color) !important;
}

.dropdown-item {
  background-color: var(--background-color-secondary) !important;
  color: var(--text-primary-color) !important;
}

nav .nav-pills > ul > li > a {
  background-color: var(--background-color-secondary) !important;
}

.nav-pills > li > a {
  background-color: var(--background-color-secondary) !important;
  color: var(--text-primary-color) !important;
}

.nav-pills > li > .active {
  background-color: var(--active-color) !important;
  color: var(--text-third-color) !important;
  box-shadow: none !important;
}

label.form-control {
  border: 0 !important;
}

.b-calendar-grid {
  box-shadow: none !important;
}

.b-calendar-grid-body > .row > div > span {
  color: var(--text-primary-color) !important;
}

.b-calendar-grid-body > .row > div > span:hover {
  color: var(--active-color) !important;
}

.b-calendar-grid-help {
  background-color: var(--background-color-secondary) !important;
  color: var(--text-primary-color) !important;
}

.badge-orange {
  background-color: var(--background-color-orange) !important;
  color: var(--text-primary-color) !important;
}

.badge-gray {
  background-color: var(--background-color-gray) !important;
  color: var(--text-primary-color) !important;
}

div .custom-switch > input[type="checkbox"] + label::before {
  box-shadow: none !important;
}

div .custom-switch > input[type="checkbox"]:checked + label::before {
  background-color: var(--active-color) !important;
  color: var(--text-primary-color) !important;
  box-shadow: none !important;
}

.table-success {
  background-color: var(--background-color-success) !important;
}

.table-success tbody + tbody, .table-success td, .table-success th, .table-success thead th {
  background-color: var(--background-color-success) !important;
}

.table-danger, .table-danger > td, .table-danger > th {
  background-color: var(--background-color-danger) !important;
}

.table-info, .table-info > td, .table-info > th {
  background-color: var(--background-color-info) !important;
}

.table-warning, .table-warning > td, .table-warning > th {
  background-color: var(--background-color-warning) !important;
}

.table-orange, .table-orange > td, .table-orange > th {
  background-color: var(--background-color-orange) !important;
}

.table-gray, .table-gray > td, .table-gray > th {
  background-color: var(--background-color-gray) !important;
}

.table-striped > tbody > tr:nth-child(odd) {
  opacity: 0.9;
}

.apexchart {
  background-color: var(--background-color-primary);
}

.apexcharts-title-text {
  fill: var(--text-primary-color);
}

.apexcharts-subtitle-text {
  fill: var(--text-primary-color);
}

.apexcharts-legend-text {
  color: var(--text-primary-color) !important;
}

.apexcharts-menu-item {
  color: #000000;
}

.apexcharts-text tspan {
  fill: var(--text-primary-color) !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  color: #000000;
}

.b-toast-danger.b-toast-solid .toast {
  background-color: var(--background-color-danger);
  color: var(--text-primary-color);
}

.b-toast-success.b-toast-solid .toast {
  background-color: var(--background-color-success);
  color: var(--text-primary-color);
}

.modal-content {
  background-color: var(--background-color-secondary);
  color: var(--text-primary-color) !important;
}

.popover-header {
  color: #000000;
}

.page-link {
  background-color: var(--background-color-secondary);
  color: var(--text-primary-color);
}

.page-item.disabled .page-link {
  background-color: var(--background-color-secondary);
  color: var(--text-primary-color);
}

.VisitorsInspector_p {
  color: var(--text-primary-color);
}

.comment {
  background-color: var(--background-color-secondary);
  color: var(--text-primary-color);
}

.dark-mode-button {
  margin-right: 10px !important;
  padding: 2rem 4rem;
  height: 30px;
  width: 20px;
  text-align: center;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  background-color: var(--background-color-secondary);
}

.progress-bar.bg-success {
  color: var(--text-primary-color);
  font-weight: bold;
  background-color: var(--background-color-success) !important;
}

.progress-bar.bg-warning {
  color: var(--text-primary-color);
  font-weight: bold;
  background-color: var(--background-color-warning) !important;
}

.progress-bar.bg-danger {
  color: var(--text-primary-color);
  font-weight: bold;
  background-color: var(--background-color-danger) !important;
}

.indicator-staging {
  background-color: var(--background-color-warning);
}

.indicator-development {
  background-color: var(--background-color-success) !important;
}

.b-form-btn-label-control.form-control > .btn {
  color: var(--text-primary-color);
}

.btn-primary.disabled, .btn-primary:disabled {
  cursor: not-allowed;
}
</style>

<script>
import ThemeButton from "@/components/ThemeButton.vue"
import EnvIndicator from "@/components/EnvIndicator"
import Sidebar from "./components/layout/Sidebar.vue";
import Anniversaries from "@/components/Anniversaries.vue";

export default {
  components: {
    Anniversaries,
    Sidebar,
    ThemeButton,
    EnvIndicator
  },
  computed: {
    isLoading () {
      return this.$store.getters.isLoading
    },
    getBcItems () {
      return this.$route.meta.bcItems
    },
    getLocalStorage () {
      return localStorage
    }
  },
}
</script>
