import { getCurrentInstance } from "vue";

export function useSwal() {
  const app = getCurrentInstance();

  if (app) {
    return app.proxy.$root.$swal;
  }
}

