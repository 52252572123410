<script setup>
import PeriodSelect from "@/components/PeriodSelect.vue";
import { useApi } from "@/composables/useApi";
import { ref, watch } from "vue";

const api = useApi();
const search = ref("");
const bookings = ref([]);
const fieldsBookings = ref([
  {key: 'ref', label: 'Référence', sortable: false},
  {key: 'login', label: 'Login', sortable: true},
  {key: 'pht', label: 'HT', sortable: true},
  {key: 'pttc', label: 'TTC', sortable: true},
  {key: 'check_in', label: 'Checkin', sortable: true},
  {key: 'check_out', label: 'Checkout', sortable: true},
  {key: 'date', label: 'Date', sortable: true},
]);
const totalRows = ref(1);
const currentPage = ref(1);
const perPage = ref(10);
const isLoading = ref(false);
const date = ref({
  startDate: null,
  endDate: null,
});
const advancedSearch = ref(false);

async function getBookings() {
  if (search.value === "") return;
  isLoading.value = true;
  await api.get(
      '/bookingsSearch/' + search.value + '?' +
      (date.value.startDate ? '&startDate=' + date.value.startDate : '') +
      (date.value.endDate ? '&endDate=' + date.value.endDate : '')
  ).then((response) => {
    bookings.value = response.data.content;
    totalRows.value = response.data.content.length;
  })
      .catch((error) => {
        console.log(error);
      })
  isLoading.value = false;
}

watch(advancedSearch, () => {
  date.value = {
    startDate: null,
    endDate: null,
  }
});
</script>

<template>
  <div class="bookings-search">
    <h2 class="mb-4">
      Recherche de réservations
    </h2>
    <div class="d-flex flex-column">
      <div class="d-flex align-content-center">
        <b-form-input
            id="filter-input"
            v-model="search"
            placeholder="Rechercher (548-745-123, test_res121, 548745123, etc...)"
            type="search"
            @keyup.enter="getBookings"
        />
        <b-btn class="ml-2" variant="primary" @click="getBookings">Rechercher</b-btn>
      </div>
      <div class="d-flex align-content-center my-4">
        <b-form-checkbox v-model="advancedSearch" name="check-button" switch size="lg" />
        <span class="font-weight-bold" @click="advancedSearch = !advancedSearch" style="margin-top: 3px; cursor: pointer">Recherche avancée</span>
      </div>
      <div class="mb-4" v-if="advancedSearch">
        <PeriodSelect @Date="newDate => { date = newDate; }" empty />
      </div>
    </div>
    <b-row class="d-flex justify-content-center">
      <b-col lg="12" v-if="!isLoading">
        <b-table
            id="bookings-table" :current-page="currentPage"
            :fields="fieldsBookings"
            :items="bookings"
            :per-page="perPage"
            empty-text="Aucune réservation n'a été récupérée"
            hover
            show-empty
            striped
        >
          <template #cell(date)="data">
            <b-badge variant="info">
              {{ data.item.date }}
            </b-badge>
          </template>
          <template #cell(check_in)="data">
            <b-badge v-if="!data.item.check_in" variant="secondary">
              ?
            </b-badge>
            <b-badge v-else variant="secondary">
              {{ data.item.check_in }}
            </b-badge>
          </template>
          <template #cell(check_out)="data">
            <b-badge v-if="!data.item.check_out" variant="secondary">
              ?
            </b-badge>
            <b-badge v-else variant="secondary">
              {{ data.item.check_out }}
            </b-badge>
          </template>
        </b-table>
        <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            align="center"
            size="sm"
        />
      </b-col>
      <b-spinner class="custom-spinner" label="Loading.." v-else />
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
.bookings-search {
  padding: 0 2rem;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.custom-spinner {
  color: var(--active-color) !important;
  width: 5rem;
  height: 5rem;
  margin: 5rem 0;
}
</style>
