<template>
  <div>
    <Spinner v-if="isLoading"/>
    <div v-else>
      <b-row align-v="center">
        <b-col md="2">
          <button type="button" class="btn m-1 btn-primary" @click="regenerate()">Regénérer un rapport</button>
        </b-col>
      </b-row>
      <b-row align-v="center" class="mt-3">
        <b-col md="2">
          <b-form-group label="Plateforme">
            <b-form-select v-model="selectedType" :options="types" @change="loadReports()"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col v-if="reports.length > 0" md="4">
          <b-form-group label="Rapport">
            <b-form-select v-model="selectedReport" :options="reports" @change="loadPerfs()"></b-form-select>
          </b-form-group>
        </b-col>

        <b-col v-if="reports.length > 0" md="1.25">
          <b-form-group label="Coût minimum">
            <b-form-input v-model="inputFilterCost" placeholder="Entrer un coût minimum"
                          @change="applyCostFilter()"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col v-if="reports.length > 0" md="2">
          <b-form-group label="Gestionnaire">
            <b-form-select v-model="selectedGestCam" :options="gestCam" @change="loadGestCam()"></b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-button style="float: right;"
                    v-b-popover.hover.bottom="'Configurer les coûts cibles des comptes'"
                    title="Configuration"
                    variant="light"
                    @click="goToConfigurator">
            <img style="width: 32px;" src="../assets/tools.png"/>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
          ></b-pagination>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-for="filter-input"
          >
            <b-input-group>
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Filtrer"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-checkbox
              v-model="switchFilter"
              name="log"
              value="1"
              unchecked-value="0"
              switch
              @change="onSwitchChange">
            Uniquement les non performants
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h5 v-text="'Analyse' + (titleReport ? ' ' + titleReport : '')"></h5>
          <b-table
              striped
              id="perfs-table"
              empty-text="Aucune donnée n'a été récupéré"
              :items="perfs"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              stacked="sm"
              show-empty
              small
              @filtered="onFiltered"
          >
            <template slot="bottom-row">
              <td/>
              <td/>
              <td/>
              <td/>
              <td/>
              <td>{{ Number((total.conv).toFixed(2)) }}</td>
              <td>{{ Number((total.cout).toFixed(2)) }}</td>
              <td>{{ Number((total.impr).toFixed(2)) }}</td>
              <td>{{ Number((total.clics).toFixed(2)) }}</td>
              <td/>
              <td/>
            </template>
            <template #cell(actions)="data">
              <a :href="`/agg-logs?site=${data.item.client}&advertiser=${selectedType}`" target="_blank">
                <b-button size="sm" v-if="admin || head" variant="secondary">Logs</b-button>
              </a>
            </template>
            <template #cell(name)="data">
              <b-badge variant="secondary">{{ data.item.name }}</b-badge>
            </template>
            <template #cell(advice)="data">
              <b-badge v-if="data.item.advice === 1" variant="success">Performant</b-badge>
              <b-badge v-else variant="danger">Non performant</b-badge>
            </template>
            <template #cell(cpa)="data">
              <b-badge v-if="data.item.cpa < data.item.target_cost" variant="success">{{ data.item.cpa }}</b-badge>
              <b-badge v-else variant="danger">{{ data.item.cpa ?? 0 }}</b-badge>
            </template>
            <template #cell(client)="data">
              <b-badge variant="light">{{ data.item.client }}</b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data: () => ({
    name: "Revue de comptes",
    isLoading: true,
    fields: [
      {key: 'advice', label: '', sortable: true},
      {key: 'client', label: 'Client', sortable: true},
      {key: 'name', label: 'Campagne', sortable: true},
      {key: 'target_cost', label: 'CPA cible', sortable: true},
      {key: 'cpa', label: 'CPA réel', sortable: true},
      {key: 'conversions', label: 'Conversions', sortable: true},
      {key: 'cost', label: 'Coût', sortable: true},
      {key: 'impressions', label: 'Impressions', sortable: true},
      {key: 'clicks', label: 'Clics', sortable: true},
      {key: 'ctr', label: 'CTR', sortable: true}
    ],
    totalRows: 1,
    currentPage: 1,
    perPage: 50,
    filter: null,
    filterOn: [],
    filteredItems: [],
    perfs: [],
    perfsSave: [],
    perfsBad: [],
    types: [],
    selectedType: null,
    reports: [],
    selectedReport: null,

    gestCam: [],
    selectedGestCam: null,

    inputFilterCost: null,
    titleReport: null,
    total: {
      conv: 0,
      cout: 0,
      impr: 0,
      clics: 0
    },
    switchFilter: 0,
    init: false,
    shouldCheckState: true,
    admin: false,
    head: false
  }),
  created() {
    this.$http.get(`/users/infos`).then((response) => {
      this.admin = response && response.data && response.data.content && response.data.content.rank === 3;
      this.head = response && response.data && response.data.content && response.data.content.rank === 2;
    })
  },
  async mounted() {
    await this.loadTypes();
    if (this.admin || this.head) {
      this.fields.push({key: 'actions', label: '', sortable: false})
    }
  },
  methods: {
    goToConfigurator() {
      this.$router.push({path: (`/review-config`)});
    },
    async loadTypes() {
      const response = await this.$http.get(`/review/types`);
      this.types = response.data.content;
      this.selectedType = this.types[0];
      await this.loadReports();
    },
    async loadReports() {
      const response = await this.$http.get(`/review/list/${this.selectedType}`);
      this.reports = response.data.content;
      this.selectedReport = this.reports.length > 0 ? this.reports[0].value : null;
      this.titleReport = null;
      await this.loadPerfs();
    },
    async loadPerfs() {
      if (this.selectedReport) {
        this.init = true;
        this.isLoading = true;
        this.generateTitleReport();
        const response = await this.$http.get(`/review/data/${this.selectedReport}`);

        this.perfsSave = response.data.content;
        this.perfsSave.forEach(element => {
          if (element.gestcam == 'null') {
            element.gestcam = 'Aucun';
          }
        });
        this.perfsBad = this.perfsSave.filter(elem => elem.advice === 0);
        this.gestCam = response.data.content.map(item => item.gestcam && item.gestcam != 'Aucun' ? item.gestcam : 'Aucun').filter((v, i, a) => a.indexOf(v) === i);
        this.gestCam.sort((a, b) => {
          return a > b ? 1 : -1;
        });
        this.gestCam.unshift(this.gestCam.splice(this.gestCam.indexOf('Aucun'), 1)[0]);
        this.gestCam.unshift('Tout le monde');
        this.selectedGestCam = this.gestCam[0];
        this.onSwitchChange();
        this.totalRows = this.perfs.length
        this.isLoading = false;
        this.init = false;
      }
    },

    async loadGestCam() {
      this.checkSwitchState(this.applyCostFilter);
      if (this.selectedGestCam && this.selectedGestCam !== 'Tout le monde')
        this.perfs = this.perfs
            .filter(elem => elem.gestcam === (this.selectedGestCam === 'Aucun' ? 'Aucun' : this.selectedGestCam));
    },

    async applyCostFilter() {
      this.checkSwitchState(this.loadGestCam);
      if (this.inputFilterCost && !isNaN(this.inputFilterCost))
        this.perfs = this.perfs.filter(elem => elem.cost >= this.inputFilterCost);
    },

    generateTitleReport() {
      this.titleReport = this.reports.find(e => e.value === this.selectedReport).title;
    },
    onFiltered(filteredItems) {
      this.total = {
        conv: 0,
        cout: 0,
        impr: 0,
        clics: 0
      }
      for (const item of filteredItems) {
        this.total.conv += item.conversions;
        this.total.cout += item.cost;
        this.total.impr += item.impressions;
        this.total.clics += item.clicks;
      }
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    //If needed additionalFilter can be in the future an array of filter functions (not just one)
    checkSwitchState(additionalFilter = null) {
      if (this.shouldCheckState) {
        if (this.switchFilter === "1")
          this.perfs = this.perfsBad;
        else
          this.perfs = this.perfsSave;
        if (additionalFilter) {
          this.shouldCheckState = false;
          additionalFilter();
          this.shouldCheckState = true;
        }
      }
    },
    onSwitchChange() {
      this.checkSwitchState()
      this.colorTable();
      if (!this.init) {
        this.shouldCheckState = false;
        this.applyCostFilter(false);
        this.loadGestCam(false);
        this.shouldCheckState = true;
      }
    },
    colorTable() {
      this.perfs.forEach(val => {
        this.total.conv += val.conversions;
        this.total.cout += val.cost;
        this.total.impr += val.impressions;
        this.total.clics += val.clicks;
      });
    },
    regenerate() {
      this.isLoading = true;
      this.$http.post(`/launcher/data_collector`, {
        log: 1,
        user: "mathieu-bousse"
      })
          .then(() => {
            this.$bvToast.toast("La demande est visible sur l'accueil", {
              title: null,
              noCloseButton: true,
              variant: 'success',
              solid: true
            })
          })
          .catch((e) => {
            console.error(e)
            this.$bvToast.toast("La demande a échoué", {
              title: null,
              noCloseButton: true,
              variant: 'danger',
              solid: true
            })
          })
          .finally(() => {
            this.isLoading = false;
          })
    }
  },
}
</script>
