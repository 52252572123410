<template>
  <multiselect class="login-selector" v-model="login" :options="options" :multiple="false"
               :close-on-select="false" :clear-on-select="false" :preserve-search="true"
               placeholder="Choisissez un client" @input="emitLogin"/>
</template>

<script>
export default {
  name: "LoginSelector",
  data: () => ({
    login: null,
    options: [],
  }),
  async created() {
    this.$http.get(`/customers/`).then((response) => {
      this.options = response.data.content.map(e => e.Login);
    })
  },
  mounted() {
    this.$emit('login', Array.isArray(this.login) ? this.login[0] : this.login);
  },
  methods: {
    emitLogin() {
      this.$emit('login', Array.isArray(this.login) ? this.login[0] : this.login);
    },
  }
};
</script>

<style scoped>
.login-selector {
  width: 35%;
}
</style>