<template>
  <div v-if="chartSerie.length > 0">
    <apexchart class="apexchart"
               height="350"
               :options="this.chartOption"
               :series="chartSerie"/>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "GCMV2Chart",

  props: {
    titleParam: {
      type: String,
      default: null
    },
    date: {
      type: Object,
      default: () => ({
        startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      })
    },
    login: {
      type: String,
      default: null
    },
    chartParam: {
      type: Array,
      default: null
    },
    xaxisParam: {
      type: Object,
      default: null
    },
  },

  data: () => ({
    chartOption: {
      chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: true
        },
        toolbar: {
          show: true
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: '',
        align: 'left'
      },
      xaxis: {},
      yaxis: {
        max: 100,
        labels: {
          formatter: function(val) {
            return val + '%'
          }
        }
      }
    },
    chartSerie: [],
  }),

  created() {
    this.chartOption.title.text = this.titleParam;
    this.chartSerie = this.chartParam;
    this.chartOption.xaxis = this.xaxisParam;
  },
  watch: {
    chartParam: {
      handler: function () {
        this.chartSerie = this.chartParam;
      },
      deep: true
    },

    xaxisParam: {
      handler: function () {
        this.chartOption.xaxis = this.xaxisParam;
      },
      deep: true
    }
  },
}
</script>