<template>
	<div>
		<b-row class="vh-100" align-v="center" align-h="center">
			<b-jumbotron class="error" bg-variant="danger" text-variant="white">
				<template #header>404</template>
				<template #lead>
					La page que vous recherchez n'existe pas !
				</template>
				<hr class="my-4">
				<p>
					Retournez sur la page d'accueil en <router-link to="/">cliquant ici</router-link>
				</p>
			</b-jumbotron>
		</b-row>
	</div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>

<style lang="scss" scoped>
.error {
	border-radius: 0;
}
</style>