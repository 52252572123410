<template>
  <b-col md="8" offset-md="2">
    <b-row align-v="center">
      <b-col md="12">
        <h3>Demande de récupération des dépenses</h3>
        <span class="subtitle">Pour le client <b>{{ getCustomer.Login }}</b> : <b>{{ getCustomer.SiteName }}</b></span>
        <hr class="my-4">
        <b-form @submit="onSubmit">
          <b-row>
            <b-col md="2">
              <b-form-group label="Choisir un annonceur" md="3">
                <b-form-select v-model="form.campaign_type" :options="optionsGestion"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                  id="input-group-start-date"
                  label="Date de début :"
                  label-for="input-start-date">
                <b-form-datepicker id="input-start-date" v-model="form.start_date"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  id="input-group-end-date"
                  label="Date de fin :"
                  label-for="input-end-date">
                <b-form-datepicker id="input-end-date" v-model="form.end_date"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col md="4" offset-md="4">
              <b-button type="submit" variant="primary" block>Valider</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  name: 'MissinCosts',
  data: () => ({
    items: [],
    log: null,
    form: {
      campaign_type: 'google',
      start_date: '',
      end_date: ''
    },
    optionsGestion: [
      {value: "google", text: "Google"},
      {value: "bing", text: "Microsoft"},
      {value: "appnexus", text: "Xandr"},
      {value: "facebook", text: "Meta"}
    ],
  }),
  mounted() {
    this.items = [
      {
        text: 'Home',
        to: {name: 'Home'}
      },
      {
        text: this.getCustomer.Login,
        active: true
      },
      {
        text: 'Dépenses manquantes',
        to: {name: 'MissingCosts'},
        active: true
      }
    ]
  },
  created() {
    this.$http.get(`/users/infos`).then((response) => {
      this.log = response ? response.data.content.log : null
    })
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.$http.post(`/launcher/missingcosts`, {
        log: this.log,
        login: this.getCustomer.Login,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
        campaign_type: this.form.campaign_type,
        user: localStorage && localStorage.user ? localStorage.user : null
      })
          .then((response) => {
            this.$bvToast.toast(response.data.content, {
              title: response.data.status === 'success' ? 'Succès' : 'Erreur',
              noCloseButton: true,
              variant: response.data.status === 'success' ? 'success' : 'danger',
              solid: true
            })
          })
          .catch((error) => {
            if (error.response) {
              this.$bvToast.toast(error.response.data.content, {
                title: null,
                noCloseButton: true,
                variant: 'danger',
                solid: true
              })
            }
          })
    }
  },
  computed: {
    getCustomer() {
      return this.$store.getters.getCustomer
    }
  }
}
</script>

<style lang="scss">

</style>
