<template>
  <div class="period-container">
    <b-form-group class="start-period"
                  id="input-group-start-date"
                  label="Date de début :"
                  label-for="input-start-date">
      <b-form-datepicker id="input-start-date" v-model="startDate" @input="emitNewDate()" :date-disabled-fn="dateDisabled"/>
    </b-form-group>
    <b-form-group class="end-period"
                  id="input-group-end-date"
                  label="Date de fin :"
                  label-for="input-end-date">
      <b-form-datepicker id="input-end-date" v-model="endDate" @input="emitNewDate()" :date-disabled-fn="dateDisabled"/>
    </b-form-group>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "PeriodSelect",
  data: () => ({
    startDate: dayjs().subtract(2, 'days').subtract(1, 'week').format('YYYY-MM-DD'),
    endDate: dayjs().subtract(2, 'days').format('YYYY-MM-DD'),
  }),
  mounted() {
    this.$emit('Date', {startDate: this.startDate, endDate: this.endDate})
  },
  methods: {
    emitNewDate() {
      this.$emit('Date', {startDate: this.startDate, endDate: this.endDate})
    },
    dateDisabled(ymd, date) {
      const today = dayjs();
      const maxDate = dayjs().subtract(2, 'days');
      const minDate = today.subtract(365, 'days');

      return date < minDate || date > maxDate;
    }
  }
}
</script>

<style scoped>
.period-container {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;;
}

.start-period {
  width: 35%;
}

.end-period {
  width: 35%;
}

@media screen and (max-width: 1230px) {
  .period-container {
    flex-direction: column;
  }

  .start-period {
    width: 100%;
  }

  .end-period {
    width: 100%;
  }

}

</style>
