import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

import Home from '../views/Home.vue'
import Help from '../views/Help.vue'
import DashTag from '../views/DashTag.vue'
import AnalyseClient from '../views/AnalyseClient.vue'
import Reattribution from '../views/Reattribution.vue'
import Statistics from '../views/Statistics.vue'
import Statistics_V2 from '../views/Statistics_V2.vue'
import ForceAttribution from '../views/ForceAttribution.vue'
import TransferLever from '../views/TransferLever.vue'
import Customer from '../views/Customer.vue'
import visitorsinspector from '../views/VisitorsInspector.vue'
import FacebookLeads from '../views/FacebookLeads.vue'
import NotFound from '../views/NotFound.vue'
import Login from '../views/Login';
import Admin from '../views/Admin';
import Review from '../views/Review';
import Stats from '../views/Stats';
import Review_config from '../views/Review_config';
import Database from '../views/Database';
import ESign from '../views/ESign';
import Amenitiz from '../views/Amenitiz';
import Sign_tool from "@/views/Sign_tool";
import UnknownOrigins from "@/views/UnknownOrigins";
import Synchronext from "@/views/Synchronext.vue";
import Hotels from "@/views/Hotels.vue";
import SearchBookings from "@/views/SearchBookings.vue";
import AggLogs from "@/views/AggLogs.vue";
import Performances from "@/views/Performances.vue";
import Referrer from "@/views/Referrer.vue";
import GlobalStats from "@/views/globalStats.vue";
import GoogleConsent from "@/views/GoogleConsent.vue";
import GoogleConsentStats from "@/views/GoogleConsentStats.vue";
import MissingCosts from "@/views/MissingCosts.vue";
import UnknownOrigin from "@/views/UnknownOrigin.vue";
import UnlinkedLevers from "@/views/UnlinkedLevers.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
      path: '/analyse-client',
      name: 'AnalyseClient',
      component: AnalyseClient
    },
    {
        path: '/global-stats',
        name: 'GlobalStats',
        component: GlobalStats
    },
    {
        path: '/unlinked-levers',
        name: 'UnlinkedLevers',
        component: UnlinkedLevers
    },
    {
        path: '/reattribution',
        name: 'Reattribution',
        component: Reattribution,
        meta: {
            bcItems: [
                {
                    text: 'Home',
                    to: {name: 'Home'}
                },
                {
                    text: 'Réattribution',
                    to: {name: 'Reattribution'},
                    active: true
                }
            ]
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters.getCustomer) {
                next('/')
            } else {
                next()
            }
        }
    },
    {
        path: '/unknownorigin',
        name: 'UnknownOrigin',
        component: UnknownOrigin,
        meta: {
            bcItems: [
                {
                    text: 'Home',
                    to: {name: 'Home'}
                },
                {
                    text: 'Origine inconnue',
                    to: {name: 'UnknownOrigin'},
                    active: true
                }
            ]
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters.getCustomer) {
                next('/')
            } else {
                next()
            }
        }
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: Statistics,
        meta: {
            bcItems: [
                {
                    text: 'Home',
                    to: {name: 'Home'}
                },
                {
                    text: 'Statistiques',
                    to: {name: 'Statistics'},
                    active: true
                }
            ]
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters.getCustomer) {
                next('/')
            } else {
                next()
            }
        }
    },
    {
        path: '/missingcosts',
        name: 'Dépenses manquantes',
        component: MissingCosts,
        meta: {
            bcItems: [
                {
                    text: 'Home',
                    to: {name: 'Home'}
                },
                {
                    text: 'Dépenses manquantes',
                    to: {name: 'MissingCosts'},
                    active: true
                }
            ]
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters.getCustomer) {
                next('/')
            } else {
                next()
            }
        }
    },
    {
        path: '/statisticstrafic',
        name: 'Statistics_v2',
        component: Statistics_V2,
        meta: {
            bcItems: [
                {
                    text: 'Home',
                    to: {name: 'Home'}
                },
                {
                    text: 'Statistiques trafic',
                    to: {name: 'Statistics'},
                    active: true
                }
            ]
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters.getCustomer) {
                next('/')
            } else {
                next()
            }
        }
    },
    {
        path: '/forceattribution',
        name: 'ForceAttribution',
        component: ForceAttribution,
        meta: {
            bcItems: [
                {
                    text: 'Home',
                    to: {name: 'Home'}
                },
                {
                    text: 'Attribution forcée',
                    to: {name: 'ForceAttribution'},
                    active: true
                }
            ]
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters.getCustomer) {
                next('/')
            } else {
                next()
            }
        }
    },
    {
        path: '/transferlever',
        name: 'TransferLever',
        component: TransferLever,
        meta: {
            bcItems: [
                {
                    text: 'Home',
                    to: {name: 'Home'}
                },
                {
                    text: 'Transfert de levier Google',
                    to: {name: 'TransferLever'},
                    active: true
                }
            ]
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters.getCustomer) {
                next('/')
            } else {
                next()
            }
        }
    },
    {
        path: '/customer/:id',
        name: 'Customer',
        component: Customer,
        meta: {
            bcItems: [
                {
                    text: 'Accueil',
                    to: {name: 'Home'}
                },
                {
                    text: 'Customer',
                    to: {name: 'Customer'}
                }
            ],
        }
    },
    {
        path: '/customer/:id/visitorsinspector',
        name: 'VisitorsInspector',
        meta: {
            bcItems: [
                {
                    text: 'Accueil',
                    to: {name: 'Home'}
                },
                {
                    text: 'Customer',
                    to: {name: 'Customer'}
                },
                {
                    text: 'VisitorsInspector',
                    to: {name: 'VisitorsInspector'}
                }
            ]
        },
        component: visitorsinspector
    },
    {
        path: '/facebookleads',
        name: 'Meta Leads',
        component: FacebookLeads,
        beforeEnter: (to, from, next) => {
            if (!store.getters.getCustomer) {
                next('/')
            } else {
                next()
            }
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/esign',
        name: 'ESign',
        component: ESign
    },
    {
        path: '/performances',
        name: 'Performances',
        component: Performances
    },
    {
        path: '/referrer',
        name: 'Referrer',
        component: Referrer
    },
    {
        path: '/hotels/activity',
        name: 'Hotels',
        component: Hotels
    },
    {
        path: '/hotels/bookings/search',
        name: 'SearchBookings',
        component: SearchBookings
    },
    {
        path: '/amenitiz',
        name: 'Amenitiz',
        component: Amenitiz
    },
    {
        path: '/synchronext',
        name: 'Synchronext',
        component: Synchronext
    },
    {
        path: '/help',
        name: 'Help',
        component: Help
    },
    {
        path: '/dashtag',
        name: 'DashTag',
        component: DashTag
    },
    {
        path: '/admin',
        name: 'Admin',
        meta: {
            needAdmin: true
        },
        component: Admin
    },
    {
        path: '/review',
        name: 'Review',
        component: Review
    },
    {
        path: '/review-config',
        name: 'Review_config',
        meta: {
            bcItems: [
                {
                    text: 'Accueil',
                    to: {name: 'Home'},
                },
                {
                    text: 'Revue de comptes',
                    to: {name: 'Review'}
                },
                {
                    text: 'Configuration de la revue de comptes',
                    to: {name: 'Review_config'},
                    active: true
                }
            ]
        },
        component: Review_config,
    },
    {
        path: '/sign-tool',
        name: 'Sign-Tool',
        component: Sign_tool
    },
    {
        path: '/google-consent',
        name: 'GoogleConsent',
        component: GoogleConsent
    },
    {
        path: '/google-consent-stats',
        name: 'GoogleConsentStats',
        component: GoogleConsentStats
    },
    {
        path: '/stats',
        name: 'Stats',
        component: Stats
    },
    {
        path: '/database',
        name: 'Database',
        component: Database
    },
    {
        path: '/unkownOrigins',
        name: 'UnkownOrigins',
        component: UnknownOrigins
    },
    {
        path: '/agg-logs',
        name: 'Agrégation des logs',
        meta: {
            external: false
        },
        component: AggLogs
    },
    {
        path: '/logout',
        name: 'Logout',
        beforeEnter: (to, from, next) => {
            delete localStorage.auth
            delete localStorage.home
            next({name: 'Login'})
        }
    },
    {
        path: '/404',
        component: NotFound
    },
    {
        path: '*',
        redirect: '/404'
    },
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    store.state.toPath = to.name !== 'Login' ? to.path : store.state.toPath
    if (to.name !== 'Login' && !localStorage.auth)
        next({name: 'Login'})
    else {
        if (to.name !== 'Amenitiz' && localStorage.auth)
            Vue.prototype.$http.get(`/users/infos`).then((response) => {
                const external = response && response.data && response.data.content && response.data.content.rank == 0;
                const admin = response && response.data && response.data.content && response.data.content.rank == 3
                if (!admin && to.meta?.needAdmin) next({name: 'Home'})
                if (external && !to.meta?.external) next({name: 'Home'})
            });
        next()
    }
})

export default router
