<template>
  <video ref="videoPlayer" class="video-js vjs-default-skin"></video>
</template>

<script>
import videojs from 'video.js';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options)
    this.$refs.videoPlayer.setAttribute('src', this.options.sources[0].src)
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  watch: {
    src: {
      handler(mailer) {
        if (mailer === "Gmail") {
          this.$refs.videoPlayer.setAttribute('src', this.options.sources[0].src)
        } else if (mailer === "Thunderbird") {
          this.$refs.videoPlayer.setAttribute('src', this.options.sources[1].src)
        }
      },
    },
    options: {
      handler(options) {
        if (this.player) {
          this.player.pause()
          this.$refs.videoPlayer.setAttribute('src', options.sources[0].src)
          this.player.play()
        }
      },
      immediate: true
    },
  }
}
</script>

<style>
.video-js {
  background: white;
}
</style>
