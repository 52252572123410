import Vue, { ref } from "vue";

function formatSerie(serie, name, key) {
  return {
    name: name,
    key: key,
    data: serie.map(value => ({ x: value.date, y: value.nb }))
  };
}

function createCacheKey(endpoint, params, timeFormat, rateMode) {
  const selectedClients = isClientAllSelected(params.clients) ? [] : params.clients.filter(client => client.id !== 0);
  let cacheKey = `${endpoint}-${params.startDate}-${params.endDate}-${selectedClients.map(client => client.login).join(",")}-${params.gestcam}-${params.company}-${timeFormat}-${rateMode}`;
  if (params.projectType !== -1) cacheKey += `-${params.projectType}`;
  if (params.projectAlgo !== -1) cacheKey += `-${params.projectAlgo}`;
  return cacheKey;
}

function isClientAllSelected(clients) {
  return clients.includes({ id: 0, login: "Tous les clients" });
}

function createQueryString(params, timeFormat, rateMode) {
  const selectedClients = isClientAllSelected(params.clients) ? [] : params.clients.filter(client => client.id !== 0);
  let queryString = `?startDate=${params.startDate}&endDate=${params.endDate}&clients=${selectedClients.map(client => client.login).join(",")}&gestcam=${params.gestcam}&company=${params.company}&timeFormat=${timeFormat}&rateMode=${rateMode}`;
  if (params.projectType !== -1) queryString += `&projectType=${params.projectType}`;
  if (params.projectAlgo !== -1) queryString += `&projectAlgo=${params.projectAlgo}`;
  return queryString;
}

const seriesConfig = {
  goal: [
    { seriesKey: "goal", seriesTitle: "Valeur de commission cible" },
    { seriesKey: "maxCost", seriesTitle: "Autorisation de dépense" },
  ],
  finance: [
    { seriesKey: "billableRoi", seriesTitle: "CA apporté au client" },
    { seriesKey: "commissions", seriesTitle: "Commissions" },
    { seriesKey: "roiRate", seriesTitle: "Taux de participation au CA" },
    { seriesKey: "cost", seriesTitle: "Dépenses" },
    { seriesKey: "margin", seriesTitle: "Marge" },
    { seriesKey: "roi", seriesTitle: "CA total du client" },
  ],
  booking: [
    { seriesKey: "roiCountByLever", seriesTitle: "Nombre de réservations apportées au client par levier" },
    { seriesKey: "acceptedRoi", seriesTitle: "Acceptée" },
    { seriesKey: "refusedRoi", seriesTitle: "Refusée" },
    { seriesKey: "unqualifiedRoi", seriesTitle: "Non qualifiée / A valider" },
  ],
  rate: [
    { seriesKey: "mobileMeanPosMargin", seriesTitle: "Moyenne mobile du % de marge bénéficiaire" },
    { seriesKey: "posMargin", seriesTitle: "Taux de marge bénéficiaire" },
  ],
  margin: [
    { seriesKey: "rawMargins", seriesTitle: "Répartition de la marge brute par leviers" },
  ]
};

export const useAutoMatrix = () => {
  const http = Vue.prototype.$http;

  const cache = new Map();

  const bookings = ref({ xaxis: [], series: [] });
  const finances = ref({ xaxis: [], series: [] });
  const rates = ref({ xaxis: [], series: [] });
  const margins = ref({ xaxis: [], series: [] });
  const goals = ref({ xaxis: [], series: [] });

  async function fetchData(endpoint, params, dataRef, seriesData, timeFormat, rateMode) {

    const cacheKey = createCacheKey(endpoint, params, timeFormat, rateMode);
    if (cache.has(cacheKey)) {
      dataRef.value = cache.get(cacheKey);
      return dataRef.value;
    }

    const queryString = createQueryString(params, timeFormat, rateMode);
    const response = await http.get(`/auto-matrix/${endpoint}${queryString}`);
    if (response.status == 200) {
      const seriesContent = response.data.content;

      const axis = seriesContent.axis;
      const series = [];

      seriesData.forEach(({ seriesKey, seriesTitle }) => {
        if (endpoint === 'margin') {
          Object.entries(seriesContent[seriesKey]).forEach(([sourceName, margins]) => {
            series.push(formatSerie(margins, sourceName, sourceName));
          });
        } else {
          if (seriesKey.includes("ByLever")) {
            Object.entries(seriesContent[seriesKey]).forEach(([key, value]) => {
              Object.entries(value).forEach(([subKey, subValue]) => {
                series.push(formatSerie(subValue, `${subKey}`, key === "billable" ? `billable${subKey}` : `non_billable${subKey}`));
              });
            });
          } else {
            series.push(formatSerie(seriesContent[seriesKey], seriesTitle, seriesKey));
          }
        }
      });

      dataRef.value = { xaxis: axis, series: series.filter(serie => serie.data.length > 0) };

      cache.set(cacheKey, dataRef.value);

      return dataRef.value;
    }
  }

  async function fetchGlobalStats(params) {
    const queryString = createQueryString(params, '');
    const response = await http.get(`/auto-matrix/stats${queryString}`);
    if (response.status == 200) return response.data.content;
  }

  async function fetchFromEndpoint(endpoint, params, timeFormat, rateMode) {
    const dataRef = { goal: goals, finance: finances, booking: bookings, rate: rates, margin: margins }[endpoint];
    const seriesData = seriesConfig[endpoint];

    if (seriesData) {
      return fetchData(endpoint, params, dataRef, seriesData, timeFormat, rateMode);
    }
  }

  return {
    bookings,
    finances,
    goals,
    rates,
    margins,
    fetchGlobalStats,
    fetchFromEndpoint
  };
};
