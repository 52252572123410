<template>
  <div v-if="env !== 'production'" :class="getEnv.class" class="p-1 text-center text-uppercase label-indicator">
    {{ getEnv.text }}
  </div>
</template>

<script>
export default {
  data: () => ({
    env: process.env.VUE_APP_ENV,
  }),
  computed: {
    getEnv: () => {
      switch (process.env.VUE_APP_ENV) {
        case 'development':
          return {
            variant: 'primary',
            class: 'indicator-development',
            text: 'development'
          }
        case 'staging':
          return {
            variant: 'warning',
            class: 'indicator-staging',
            text: 'staging'
          }
      }
    }
  }
};
</script>

<style scoped>
.label-indicator {
  font-size: 8pt;
  letter-spacing: 0.25pt;
  color: white;
  font-weight: 500;
}
</style>
