<template>
  <apexchart class="apexchart"
             height="350"
             :options="this.chartOption"
             :series="chartSerie"/>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "evolutionPerDay",
  data: () => ({
    request: '/synchronext',
    chartOption: {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      xaxis: {
        type: 'datetime',
        categories: []
      },
      series: [
        {
          name: 'Téléchargement(s)',
          type: 'line',
          data: []
        },
        {
          name: 'Re téléchargement(s)',
          type: 'line',
          data: []
        },
        {
          name: 'Achat(s)',
          type: 'line',
          data: []
        },
        {
          name: 'Remboursement(s)',
          type: 'line',
          data: []
        },
        {
          name: 'Période(s) d\'essai',
          type: 'line',
          data: []
        },
        {
          name: 'Mise(s) à jour',
          type: 'line',
          data: []
        }
      ],
      legend: {
        position: 'left',
        offsetY: 100,
        fontFamily: 'Helvetica, Arial',
      },
      stroke: {
        curve: 'smooth',
      },
      fill: {
        opacity: 1
      },
    },
    chartSerie: [],
  }),

  props: {
    date: {
      type: Object,
      default: () => ({
        startDate: dayjs().subtract(2, 'days').subtract(1, 'week').format('YYYY-MM-DD'),
        endDate: dayjs().subtract(2, 'days').format('YYYY-MM-DD')
      })
    }
  },

  async mounted() {
    if (this.date && this.date.startDate && this.date.endDate) {
      await this.getData();
    }
  },

  watch: {
    date: {
      handler: async function () {
        if (this.date && this.date.startDate && this.date.endDate) {
          await this.getData();
        }
      },
      deep: true
    },
  },

  methods: {
    async getData() {
      this.$store.commit("isLoading", true);
      const response = await this.$http.get(this.request + `/${this.date.startDate}/${this.date.endDate}`);
      const { content } = response.data || {};
      console.log(content)
      if (content) {
        const series = [{ name: 'Téléchargement(s)', data: content.perDay.map((element) => element.downloads) || [] }];
        series.push({ name: 'Achat(s)', data: content.perDay.map((element) => element.purchases) || [] });
        series.push({ name: 'Mise(s) à jour', data: content.perDay.map((element) => element.updates) || [] });
        series.push({ name: 'Re téléchargement(s)', data: content.perDay.map((element) => element.reDownloads) || [] });
        series.push({ name: 'Remboursement(s)', data: content.perDay.map((element) => element.refunds) || [] });
        series.push({ name: 'Période(s) d\'essai', data: content.perDay.map((element) => element.trials) || [] });
        this.chartOption = {
          ...this.chartOption,
          series,
          xaxis: { categories: content.dates || [] }
        };
      }
      this.$store.commit("isLoading", false);
    }
  },
}
</script>
