<template>
  <div>
    <b-row align-v="center">
      <b-col md="8" offset-md="2">
        <h3>Forcer une attribution de conversion</h3>
        <span class="subtitle">Pour le client <b>{{ getCustomer.Login }}</b> : <b>{{ getCustomer.SiteName }}</b></span>
        <hr class="my-4">
        <b-row>
            <b-col md="12">
                <b-alert variant="warning" show>
                    <b-form-checkbox v-model="accepted">Forcer une attribution est une <b>fonctionnalité critique</b> qui peut impacter grandement la <b>facturation</b>. En utilisant cet outil vous engagez pleinement votre <b>responsabilité</b> et devez être en mesure de <b>prouver la réflexion</b> qui vous a menée à utiliser cet outil à tout moment.</b-form-checkbox>
                </b-alert>
            </b-col>
        </b-row>
        <div v-if="accepted">
            <b-form @submit="loadRoi">
                <b-row>
                    <b-col md="6">
                        <b-form-group
                                id="input-group-start-date"
                                label="Date de début :"
                                label-for="input-start-date">
                            <b-form-datepicker id="input-start-date" v-model="start_date"></b-form-datepicker>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                                id="input-group-end-date"
                                label="Date de fin :"
                                label-for="input-end-date">
                            <b-form-datepicker id="input-end-date" v-model="end_date"></b-form-datepicker>
                        </b-form-group>
                    </b-col>
                    <b-col md="4" offset-md="4">
                        <b-button type="submit" variant="primary" block>Charger les conversions</b-button>
                    </b-col>
                </b-row>
            </b-form>
            <hr class="my-4">
            <b-row v-if="actualize">
                <b-col md="8" offset-md="2" class="mb-3">
                    <b-button type="button" variant="info" block @click="actualizeData">Actualiser la donnée sur Statshop</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4" v-if="rois.length > 0">
                    <b-form-input
                            class="mb-3"
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Rechercher une conversion"
                    ></b-form-input>
                    <b-table
                            striped hover
                            id="rois-table"
                            empty-text="Aucune conversion récupérée!"
                            :items="rois"
                            :fields="fieldsRois"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :filter="filter"
                            @row-clicked="selectRoi"
                            @filtered="onFiltered"
                            stacked="sm"
                            show-empty
                            small
                            fixed
                    >
                        <template #table-colgroup="scope">
                            <col
                                    :key="scope.roi_id"
                                    :style="{ width: '60px' }"
                            >
                        </template>
                    </b-table>
                    <b-pagination
                            align="center"
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            size="sm"
                    ></b-pagination>
                </b-col>
                <b-col md="8" v-if="selected">
                    <b-form-input
                            class="mb-3"
                            id="filter-input"
                            v-model="filterTvs"
                            type="search"
                            placeholder="Rechercher un levier"
                    ></b-form-input>
                    <b-table
                            striped hover
                            id="rois-table"
                            empty-text="Aucune conversion récupérée!"
                            :items="tvs"
                            :fields="fieldsTvs"
                            :current-page="currentPageTvs"
                            :per-page="perPageTvs"
                            :filter="filterTvs"
                            @row-clicked="selectTv"
                            @filtered="onFilteredTvs"
                            stacked="sm"
                            show-empty
                            small
                            fixed
                    >
                        <template #table-colgroup="scope">
                            <col
                                    :key="scope.id"
                                    :style="{ width: '60px' }"
                            >
                        </template>
                    </b-table>
                    <b-pagination
                            align="center"
                            v-model="currentPageTvs"
                            :total-rows="totalRowsTvs"
                            :per-page="perPageTvs"
                            size="sm"
                    ></b-pagination>
                </b-col>
            </b-row>
        </div>
      </b-col>
    </b-row>
    <b-modal id="modal-tv" hide-footer size="md">
      <template #modal-title>
        <b-col md="12">{{ selected.roi_ref }}</b-col>
        <b-col md="12" class="modal-subtitle">Attribuer la conversion au levier &laquo; {{tv.libelle}} &raquo;</b-col>
      </template>
      <div class="d-block">
        <b-row class="text-center">
          <b-col md="12">
            <b-button type="button" variant="success" block @click="confirmTv">Confirmer</b-button>
            <b-button type="button" variant="warning" block @click="cancelTv">Annuler</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: 'ForceAttribution',
  data: () => ({
    actualize: false,
    start_date: '',
    end_date: '',
    rois: [],
    fieldsRois: [
      {key: 'roi_id', label: '#', sortable: true},
      {key: 'roi_ref', label: 'Référence', sortable: true},
      {key: 'roi_date', label: 'Date', sortable: true}
    ],
    fieldsTvs: [
      {key: 'id', label: '#', sortable: true},
      {key: 'libelle', label: 'Label', sortable: true},
      {key: 'master', label: 'Master', sortable: true},
      {key: 'campaign', label: 'Campaign', sortable: true}
    ],
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    filter: null,
    selected: null,
    tvs: [],
    tv: null,
    filterTvs: null,
    perPageTvs: 10,
    currentPageTvs: 1,
    totalRowsTvs: 1,
    accepted: false
  }),
  mounted() {
    this.start_date = dayjs().subtract(7, 'days').format('YYYY-MM-DD')
    this.end_date = dayjs().format('YYYY-MM-DD')
  },
  created() {
    this.$http.get(`/users/infos`).then((response) => {
      this.log = response ? response.data.content.log : null
    })
  },
  methods: {
    async loadRoi(event) {
      event.preventDefault()
      const result = await this.$http.post(`/customers/${this.getCustomer.Login}/conversions`, {
        start_date: this.start_date,
        end_date: this.end_date
      })
      this.rois = result ? result.data : []
      this.totalRows = this.rois.length
    },
    async selectRoi(row) {
      this.rois.forEach(row => row._rowVariant = null)
      row._rowVariant = 'warning'
      this.selected = row
      const result = await this.$http.get(`/customers/${this.getCustomer.Login}/tvs`)
      this.tvs = result ? result.data : []
      this.totalRowsTvs = this.tvs.length
    },
    async selectTv(row) {
      this.tv = row
      this.$bvModal.show('modal-tv')
    },
    async confirmTv() {
      await this.$http.post(`/customers/${this.getCustomer.Login}/tvs`, {
        roi_id: this.selected.roi_id,
        tv_id: this.tv.id,
        user: localStorage && localStorage.user ? localStorage.user : null
      })
      this.$bvModal.hide('modal-tv')
      this.$bvToast.toast('Modification réussie, une synchronisation est nécessaire pour actualiser la donnée.', {
        title: null,
        noCloseButton: true,
        variant: 'success',
        solid: true
      })
      this.actualize = true
    },
    async actualizeData() {
      await this.$http.post(`/launcher/reattribution`, {
        log: true,
        login: this.getCustomer.Login,
        start_date: this.start_date,
        end_date: this.end_date,
        user: localStorage && localStorage.user ? localStorage.user : null
      })
      this.$bvToast.toast('Actualisation demandée, vous pouvez suivre son avancement sur la page d\'accueil.', {
        title: null,
        noCloseButton: true,
        variant: 'success',
        solid: true
      })
    },
    cancelTv() {
      this.$bvModal.hide('modal-tv')
    },
    onFiltered(filteredRois) {
      this.totalRows = filteredRois.length
      this.currentPage = 1
    },
    onFilteredTvs(filteredTvs) {
      this.totalRowsTvs = filteredTvs.length
      this.currentPageTvs = 1
    }
  },
  computed: {
    getCustomer() {
      return this.$store.getters.getCustomer
    }
  }
}
</script>
