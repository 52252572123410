<template>
  <b-container fluid>
    <b-row>
      <b-col cols="6" offset="3" md="2" offset-md="5" class="text-center">
        <img src="../assets/logo.png" alt="Avatar" class="avatar">
      </b-col>
    </b-row>
    <b-form @submit="onSubmit">
      <b-row align-v="center">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="4" offset-md="4" class="mt-2">
              <b-form-input id="email" type="text" size="md" placeholder="Adresse email" v-model="email"></b-form-input>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="4" offset-md="4" class="mt-2">
              <b-form-input id="password" type="password" size="md" placeholder="Mot de passe"
                            v-model="password"></b-form-input>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="4" offset-md="4" class="mt-4">
              <b-button id="submit" type="submit" variant="primary" block>Se connecter</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    <b-row class="mt-5">
      <b-col md="6" offset-md="3">
        <div>
          <b-alert show variant="warning">
            <h4 class="alert-heading">Nouveautés</h4>
            <p>
              L'outil de suivi des UTMs est maintenant disponible! 🔍
            </p>
            <p>
              Vous pouvez donc désormais examiner les activités externes de vos clients.
            </p>
            <p>
              N'hésitez pas à nous contacter si vous souhaitez certaines améliorations de fonctionnalités!
            </p>
          </b-alert>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data: () => ({
    email: null,
    password: null
  }),
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.$http.post(`/auth/login`, {
        email: this.email,
        password: this.password
      })
          .then((response) => {
            if (response.data.status === 'success') {
              localStorage.auth = response.data.content.token
              localStorage.user = response.data.content.user
              localStorage.home = response.data.content.home
              this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.content.token
              this.$bvToast.toast(response.data.content.message, {
                title: null,
                noCloseButton: true,
                variant: 'success',
                autoHideDelay: 2500,
                solid: true
              })
              this.$store.commit('isLoading', true)
              setTimeout(() => {
                let redirect = localStorage.home
                if (['/dashtag'].includes(this.$store.state.toPath)) {
                  redirect = this.$store.state.toPath
                }
                this.$router.push({path: redirect})
                this.$store.commit('isLoading', false)
              }, 3000)
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$bvToast.toast(error.response.data.content, {
                title: null,
                noCloseButton: true,
                variant: 'danger',
                solid: true
              })
            }
          })
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading
    }
  }
}
</script>

<style lang="scss" scoped>
img.avatar {
  width: 100%;
  border-radius: 50%;
}

.container {
  padding: 16px;
}
</style>
