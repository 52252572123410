<script setup>
import { ref, onMounted, computed } from "vue";
import { useApi } from '@/composables/useApi';

import dayjs from "dayjs";

const api = useApi();

const modal_details = ref();
const modal_reactivation = ref();
const modal_bulk_reactivation = ref();

const reports = ref([]);
const pieOptions = ref({
  chart: {
    width: 380,
    type: "pie",
  },
  legend: {
    show: false,
  },
  labels: ["CA BM (TTC)", "Dépenses"],
  colors: ["#20b649", "#c90505"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
        },
      },
    },
  ],
});
const selectedReport = ref(0);
const selectedCampaigns = ref([]);
const statuses = ref(0);
const loadingCampaignsStatuses = ref(false);
const loadableCampaignsStatuses = ref(true);
const searchedCampaign = ref(null);
const margin = ref(0);
const clients = ref(["Tous"]);
const client = ref("Tous");
const comment = ref(null);
const company = ref(0);
const notExplicitComment = ref(false);
const performing = ref(2);
const perPage = ref(10);
const viewDetails = ref(null);
const reactivation = ref(null);
const currentPage = ref(1);
const advertiser = ref(0);
const sending = ref(false);
const deactivated = ref(2);
const reactivated = ref(2);
const report = ref(null);
const activeCampaigns = ref({});
const original = ref(null);
const pendingCampaignsStatus = ref([]);
const gestcams = ref([
  {
    value: -1,
    text: "Aucun",
  },
  {
    value: 0,
    text: "Tous",
  },
]);
const gestcam = ref(0);
const fields = ref([
  { key: "selected", label: "", sortable: false },
  { key: "site_id", label: "#", sortable: true },
  { key: "treated", label: "Traitée", sortable: true },
  { key: "login", label: "Login", sortable: true },
  { key: "source_type", label: "Plateforme", sortable: true },
  { key: "tracking_label", label: "Campagne", sortable: true },
  { key: "gestcam", label: "Gestcam", sortable: true },
  { key: "status", label: "Active", sortable: false },
  { key: "active_since", label: "Actif depuis", sortable: true },
  { key: "quantity", label: "Quantité", sortable: true },
  { key: "turnover_customer_ht", label: "CA apporté (HT)", sortable: true },
  { key: "turnover_customer_ttc", label: "CA apporté (TTC)", sortable: true },
  { key: "turnover_ht", label: "Commissions (HT)", sortable: true },
  { key: "turnover_ttc", label: "Commissions (TTC)", sortable: true },
  { key: "total_cost", label: "Dépenses", sortable: true },
  { key: "margin", label: "Marge (€)", sortable: true },
  { key: "margin_percent", label: "Marge (%)", sortable: true },
  { key: "advice", label: "Performant", sortable: true },
  { key: "deactivated", label: "Désactivé", sortable: true },
  { key: "reactivated", label: "Réactivé", sortable: true },
  { key: "comment", label: "Commentaire", sortable: true },
  { key: "action", label: "Action", sortable: false },
  { key: "reactivated_planned", label: "Réactivation planifiée", sortable: true },
]);
const loading = ref(false);

onMounted(() => {
  loading.value = true;
  api
    .get(`/performances`)
    .then(({ data }) => {
      reports.value = data.reports;
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
    });
});

const selectableTable = ref(null);

const selectAllRows = () => {
  selectableTable.value.selectAllRows();
};

const onRowSelected = (items) => {
  selectedCampaigns.value = items.filter(
    (i) => i.deactivated === 1 && i.reactivated === 0
  );
};

async function loadReport(withLoader = false, resetFilters = true) {
  totalEconomy.value = 0;
  if (resetFilters) {
    margin.value = 0;
    client.value = "Tous";
    comment.value = null;
    notExplicitComment.value = false;
    performing.value = 2;
    statuses.value = 0;
    perPage.value = 10;
    viewDetails.value = null;
    reactivation.value = null;
    currentPage.value = 1;
    advertiser.value = 0;
    sending.value = false;
    deactivated.value = 2;
    reactivated.value = 2;

    gestcams.value = [
      {
        value: -1,
        text: "Aucun",
      },
      {
        value: 0,
        text: "Tous",
      },
    ];
    gestcam.value = 0;
  }

  if (withLoader) loading.value = true;

  let url = `/performances/report/${selectedReport.value}/${company.value > 0 ? company.value : ""
    }?gestcam=${gestcam.value}`;
  const { data } = await api.get(url);

  report.value = data.report;
  original.value = data.report;
  viewDetails.value = report.value[0];
  reactivation.value = report.value[0];

  clients.value = [...new Set(original.value.map((e) => e.login))].sort();
  clients.value = ["Tous", ...clients.value];
  for (const campaigns of data.report) {
    if (
      campaigns.gestcam &&
      !gestcams.value.find((g) => g.value === campaigns.gestcam_user_id)
    )
      gestcams.value.push({
        value: campaigns.gestcam_user_id,
        text: campaigns.gestcam,
      });
  }
  updateAchievableSavings();

  if (withLoader) loading.value = false;
}

function getTotalEconomy() {
  let total = 0;
  const currentReport = reports.value.find((r) => r.value === selectedReport.value);
  const dates = currentReport.text.split("(")[0].split(" - ");
  const startDate = dayjs(dates[0]);
  const endDate = dayjs(dates[1]);
  const dayInReport = dayjs(endDate).diff(startDate, "day");
  const endOfMonth = dayjs(endDate).endOf("month");
  const prorata = dayjs(endDate).diff(endOfMonth, "day");
  for (const campaign of filterReport.value) {
    const averageCostPerDay =
      dayInReport < campaign.active_since
        ? campaign.total_cost / dayInReport
        : campaign.total_cost / campaign.active_since;
    if (campaign.deactivated === 1 && activeCampaigns.value[campaign.id] === 0) {
      total += averageCostPerDay * prorata;
    }
  }
  return total.toFixed(2) * -1;
}

function getAchievableSavings() {
  let total = 0;
  for (const campaign of report.value) {
    if (campaign.active_since <= 15) continue;
    if (campaign.margin_percent < -10 || (campaign.margin_percent >= -10 && campaign.margin_percent <= 0)) {
      total += campaign.total_cost;
    }
  }
  return total.toFixed(2);
}

const totalEconomy = ref(0);
const achievableSavings = ref(0);

function updateTotalEconomy() {
  totalEconomy.value = getTotalEconomy();
}

function updateAchievableSavings() {
  achievableSavings.value = getAchievableSavings();
}

async function getCampaignsStatus() {
  loadingCampaignsStatuses.value = true;
  await Promise.all(
    filterReport.value.map(async (r) => {
      if (loadableCampaignsStatuses.value) {
        const {
          id: reportId,
          customer_id: customerId,
          account_id: accountId,
          campaign_id: campaignId,
          source_type: sourceType,
        } = r;

        if (sourceType !== 2) {
          activeCampaigns.value[reportId] = 3;
          return;
        }

        if ([customerId, accountId, campaignId].includes(null)) return;
        togglePendingCampaignStatus(reportId, true);
        const { data } = await api.post("/performances/campaign-status", {
          customerId,
          accountId,
          campaignId,
        });
        activeCampaigns.value[reportId] = data.campaignStatus ? 1 : 0;
        togglePendingCampaignStatus(reportId, false);
      }
    })
  );
  updateTotalEconomy();
  loadingCampaignsStatuses.value = false;
}

function togglePendingCampaignStatus(reportId, booleanValue) {
  pendingCampaignsStatus.value[reportId] = booleanValue;
}

async function sendReactivation() {
  if (comment.value && comment.value.length > 10) {
    sending.value = true;
    await api.post("/performances", {
      infoId: reactivation.value.performance_customer_data_info_id,
      comment: comment.value,
      customerId: reactivation.value.customer_id,
      accountId: reactivation.value.account_id,
      campaignId: reactivation.value.campaign_id,
    });
    await loadReport(false, false);
    modal_reactivation.value.hide();
    comment.value = null;
    sending.value = false;
  } else {
    notExplicitComment.value = true;
  }
}

async function sendBulkReactivation() {
  if (comment.value && comment.value.length > 10) {
    sending.value = true;
    const deactivatedCampaigns = selectedCampaigns.value.filter(
      (c) => c.deactivated === 1
    );
    await Promise.all(
      deactivatedCampaigns.map(async (r) => {
        const {
          performance_customer_data_info_id: infoId,
          customer_id: customerId,
          account_id: accountId,
          campaign_id: campaignId,
        } = r;
        await api.post("/performances", {
          infoId,
          comment: comment.value,
          customerId,
          accountId,
          campaignId,
        });
      })
    );
    await loadReport(false, false);
    modal_bulk_reactivation.value.hide();
    comment.value = null;
    sending.value = false;
    selectedCampaigns.value = [];
  } else {
    notExplicitComment.value = true;
  }
}

function closeReactivation() {
  comment.value = null;
  notExplicitComment.value = false;
  sending.value = false;
  modal_reactivation.value.hide();
}

function closeBulkReactivation() {
  comment.value = null;
  notExplicitComment.value = false;
  sending.value = false;
  modal_bulk_reactivation.value.hide();
}

function download(arr) {
  const array = [Object.keys(arr[0])].concat(arr);
  const data = array
    .map((it) => {
      for (const key in it) {
        if (typeof it[key] === "number") {
          it[key] = it[key].toString().replace(".", ",");
        }
      }
      return Object.values(it).join(";");
    })
    .join("\n");

  const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const e = document.createElement("a");
  e.href = url;
  e.setAttribute("download", "export.csv");
  e.click();
}

function openModalViewDetails(item) {
  viewDetails.value = item;
  modal_details.value.show();
}

function openModalReactivation(item) {
  reactivation.value = item;
  modal_reactivation.value.show();
}

function openModalBulkReactivation() {
  modal_bulk_reactivation.value.show();
}

function formatReason(reason) {
  switch (reason) {
    case "GIE_TURNOVER_0":
      return "Aucun chiffre d'affaire généré pour le groupe";
    case "MARGIN_REQUIREMENT_NOT_MEET":
      return "Marge minimale requise non atteinte";
    case "CUSTOMER_TURNOVER_0":
      return "Le client n'a produit aucun chiffre d'affaire";
    default:
      return "Aucune alerte remontée";
  }
}

function formatState(advice, deactivated, reactivated) {
  if (advice && deactivated && reactivated) {
    return "Désactivé automatiquement, puis réactivé manuellement";
  } else if (advice && deactivated && !reactivated) {
    return "Désactivé automatiquement";
  } else if (advice && !deactivated) {
    return "N'a pas pu être désactivé automatiquement";
  } else if (!advice && !deactivated) {
    return "Aucune mesure automatique n'a été prise";
  }
}

function formatComment(comment) {
  if (comment) {
    return comment;
  } else {
    return "Aucun commentaire n'a été ajouté.";
  }
}

function formatReactivated(reactivated, email) {
  if (reactivated && email) {
    return `Réactivation manuelle par ${email}`;
  } else if (reactivated) {
    return `Réactivation manuelle`;
  } else {
    return `N'a pas été réactivé`;
  }
}

function getGestcamFirstLetter(gestcam) {
  const [firstName, lastName] = gestcam.split(" ");
  return `${firstName[0]}${lastName[0]}`;
}

const filterReport = computed(() => {
  const items = original.value.filter((item) => {
    let passFilter1 =
      performing.value === 2 || item.advice === performing.value;
    let passFilter2 =
      deactivated.value === 2 || item.deactivated === deactivated.value;
    let passFilter3 =
      advertiser.value === 0 || item.source_type === advertiser.value;
    let passFilter4 = client.value === "Tous" || item.login === client.value;
    let passFilter5 =
      reactivated.value === 2 || item.reactivated === reactivated.value;
    let passFilter6 = false;
    let passFilter7 = true;
    let passFilter8 = true;
    if (searchedCampaign.value) {
      passFilter7 = item.tracking_label
        .toLowerCase()
        .includes(searchedCampaign.value.toLowerCase());
    }

    switch (margin.value) {
      case 0:
        passFilter6 = true;
        break;
      case 1:
        passFilter6 = item.margin_percent > 0;
        break;
      case 2:
        passFilter6 = item.margin_percent >= 0 && item.margin_percent <= 5;
        break;
      case 3:
        passFilter6 = item.margin_percent >= 5 && item.margin_percent <= 15;
        break;
      case 4:
        passFilter6 = item.margin_percent >= 15 && item.margin_percent <= 30;
        break;
      case 5:
        passFilter6 = item.margin_percent >= 30 && item.margin_percent <= 50;
        break;
      case 6:
        passFilter6 = item.margin_percent >= 50;
        break;
      case 7:
        passFilter6 = item.margin_percent <= 0;
        break;
      case 8:
        passFilter6 = item.margin_percent <= 0 && item.margin_percent >= -5;
        break;
      case 9:
        passFilter6 = item.margin_percent <= 0 && item.margin_percent >= -10;
        break;
      case 10:
        passFilter6 = item.margin_percent <= -5 && item.margin_percent >= -15;
        break;
      case 11:
        passFilter6 = item.margin_percent <= -15 && item.margin_percent >= -30;
        break;
      case 12:
        passFilter6 = item.margin_percent <= -30 && item.margin_percent >= -50;
        break;
      case 13:
        passFilter6 = item.margin_percent <= -50;
        break;
    }

    switch (statuses.value) {
      case 0:
        passFilter8 = true;
        break;
      case 1:
        passFilter8 =
          item.margin_percent > 0 &&
          item.active_since &&
          item.active_since > 15;
        break;
      case 2:
        passFilter8 =
          item.margin_percent < -10 &&
          item.active_since &&
          item.active_since > 15;
        break;
      case 3:
        passFilter8 =
          item.total_cost === 0 &&
          item.margin === 0 &&
          item.active_since &&
          item.active_since > 15;
        break;
      case 4:
        passFilter8 =
          !item.active_since ||
          (item.active_since && item.active_since && item.active_since <= 15);
        break;
      case 5:
        passFilter8 =
          item.margin_percent >= -10 &&
          item.margin_percent <= 0 &&
          item.active_since &&
          item.active_since > 15;
        break;
    }

    return (
      passFilter1 &&
      passFilter2 &&
      passFilter3 &&
      passFilter4 &&
      passFilter5 &&
      passFilter6 &&
      passFilter7 &&
      passFilter8
    );
  });
  items.forEach((item) => {
    item.treated === 1 ? (item.treated = true) : (item.treated = false);
    if (item.active_since <= 15) {
      item._rowVariant = "info";
    } else if (item.margin_percent > 0) {
      item._rowVariant = "success";
    } else if (item.margin_percent < -10) {
      item._rowVariant = "danger";
    } else if (item.total_cost === 0 && item.margin === 0) {
      item._rowVariant = "gray";
    } else if (item.margin_percent >= -10 && item.margin_percent <= 0) {
      item._rowVariant = "orange";
    }
  });
  return items;
});

const totalRows = computed(() => {
  return filterReport.value.length;
});

const hasDetailsSet = computed(() => {
  return viewDetails.value !== null;
});

function generateLabel(item) {
  if (item.customer_id && item.account_id && item.campaign_id) {
    return `${item.customer_id}/${item.account_id}/${item.campaign_id}`;
  } else if (item.account_id && item.campaign_id) {
    return `${item.account_id}/${item.campaign_id}`;
  } else {
    return `${item.campaign_id}`;
  }
}

async function toggleTreated(id) {
  const item = filterReport.value.find((i) => i.id === id);
  await api.post("/performances/treat", { id, state: !item.treated });
  await loadReport(false, false);
}

async function toggleReactivated(id) {
  const item = filterReport.value.find((i) => i.id === id);
  await api.post("/performances/reactivate", { id, state: !item.reactivated_planned });
  await loadReport(false, false);
}

const showReactivatedPlanned = computed(() => {
  if (report.value) {
    const currentReport = reports.value.find((r) => r.value === selectedReport.value);
    const dates = currentReport.text.split("(")[0].split(" - ");
    const startDate = dayjs(dates[0]);
    const endDate = dayjs(dates[1]);
    const today = dayjs();
    return startDate.isSame(today, "month") && endDate.isSame(today, "month");
  }
  return false;
});

</script>

<template>
  <div class="container-fluid">
    <b-row class="mb-4">
      <b-col md="12">
        <h2>Suivi des performances</h2>
      </b-col>
    </b-row>
    <b-row v-if="report">
      <b-col md="12" class="mb-4 d-flex" style="gap: 0.5rem">
        <div>
          <b-badge variant="success">Marge (%) &gt; 0%</b-badge>
        </div>
        <div>
          <b-badge variant="danger">Marge (%) &lt; -10%</b-badge>
        </div>
        <div>
          <b-badge variant="gray">Dépenses (€) = 0 &amp; Marge (€) = 0</b-badge>
        </div>
        <div>
          <b-badge variant="info">Actif depuis &le; 15 jours</b-badge>
        </div>
        <div>
          <b-badge variant="orange">-10% &le; Marge (%) &le; 0%</b-badge>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-row>
          <b-col lg="4" md="12">
            <b-form-group label="Période sélectionnée :">
              <b-select v-model="selectedReport" :options="reports" @input="loadReport(true)">
                <template #first>
                  <b-form-select-option :value="0" disabled>Choisir une période</b-form-select-option>
                </template>
              </b-select>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="12" v-if="report">
            <b-form-group label="Entreprise :">
              <b-select @input="loadReport(true)" v-model="company" :options="[
      {
        value: 0,
        text: 'Toutes',
      },
      {
        value: 1,
        text: '360&1',
      },
      {
        value: 2,
        text: 'Full-Performance',
      },
    ]" />
            </b-form-group>
          </b-col>
          <b-col lg="2" md="12" v-if="report">
            <b-form-group label="Gestcam :">
              <b-select v-model="gestcam" :options="gestcams" @input="loadReport(true, false)" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" class="my-2" v-if="report">
        <b-row>
          <b-col lg="2" md="12">
            <b-form-group label="Client :">
              <b-select v-model="client" :options="clients" />
            </b-form-group>
          </b-col>
          <b-col lg="2" md="12">
            <b-form-group label="Performant :">
              <b-select v-model="performing" :options="[
      {
        value: 2,
        text: 'Tous',
      },
      {
        value: 0,
        text: 'Oui',
      },
      {
        value: 1,
        text: 'Non',
      },
    ]" />
            </b-form-group>
          </b-col>
          <b-col lg="2" md="12">
            <b-form-group label="Désactivé :">
              <b-select v-model="deactivated" :options="[
      {
        value: 2,
        text: 'Tous',
      },
      {
        value: 1,
        text: 'Oui',
      },
      {
        value: 0,
        text: 'Non',
      },
    ]" />
            </b-form-group>
          </b-col>
          <b-col lg="2" md="12">
            <b-form-group label="Réactivé :">
              <b-select v-model="reactivated" :options="[
      {
        value: 2,
        text: 'Tous',
      },
      {
        value: 1,
        text: 'Oui',
      },
      {
        value: 0,
        text: 'Non',
      },
    ]" />
            </b-form-group>
          </b-col>
          <b-col lg="2" md="12">
            <b-form-group label="Plateforme :">
              <b-select v-model="advertiser" :options="[
      {
        value: 0,
        text: 'Toutes',
      },
      {
        value: 2,
        text: 'Google',
      },
      {
        value: 17,
        text: 'Xandr',
      },
      {
        value: 34,
        text: 'Meta',
      },
      {
        value: 35,
        text: 'Microsoft',
      },
    ]" />
            </b-form-group>
          </b-col>
          <b-col lg="2" md="12">
            <b-form-group label="Marge :">
              <b-select v-model="margin" :options="[
      {
        value: 0,
        text: 'Toutes',
      },
      {
        value: 1,
        text: 'Plus de 0%',
      },
      {
        value: 2,
        text: 'Entre 0% et 5%',
      },
      {
        value: 3,
        text: 'Entre 5% et 15%',
      },
      {
        value: 4,
        text: 'Entre 15% et 30%',
      },
      {
        value: 5,
        text: 'Entre 30% et 50%',
      },
      {
        value: 6,
        text: 'Plus de 50%',
      },
      {
        value: 7,
        text: 'Moins de 0%',
      },
      {
        value: 8,
        text: 'Entre 0% et -5%',
      },
      {
        value: 9,
        text: 'Entre 0% et -10%',
      },
      {
        value: 10,
        text: 'Entre -5% et -15%',
      },
      {
        value: 11,
        text: 'Entre -15% et -30%',
      },
      {
        value: 12,
        text: 'Entre -30% et -50%',
      },
      {
        value: 13,
        text: 'Moins de -50%',
      },
    ]" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" class="my-2" v-if="report">
        <b-row>
          <b-col lg="4" md="12">
            <b-form-group label="Rechercher une campagne :">
              <b-form-input v-model="searchedCampaign" />
            </b-form-group>
          </b-col>
          <b-col lg="2" md="12">
            <b-form-group label="Status :">
              <b-select v-model="statuses" :options="[
      {
        value: 0,
        text: 'Tous',
      },
      {
        value: 1,
        text: 'Marge (%) > 0%',
      },
      {
        value: 2,
        text: 'Marge (%) < -10%',
      },
      {
        value: 3,
        text: 'Dépenses (€) = 0 & Marge (€) = 0',
      },
      {
        value: 4,
        text: 'Actif depuis ≤ 15 jours',
      },
      {
        value: 5,
        text: '-10% ≤ Marge (%) ≤ 0%',
      },
    ]" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-if="report">
        <b-row class="align-items-end">
          <b-col cols="6">
            <b-row>
              <b-col>
                <b-form-group label="Par page :">
                  <b-form-select size="sm" v-model="perPage" :options="[10, 25, 100, 1000]"
                    class="w-auto"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Action :">
                  <b-btn :disabled="loadingCampaignsStatuses" @click="getCampaignsStatus" variant="primary">Charger le
                    statut
                    des campagnes</b-btn>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" class="text-right">
            <b-icon style="cursor: pointer" icon="download" @click="download(report)"
              v-b-popover.hover.top="'Télécharger'" />
          </b-col>
        </b-row>
        <div v-if="selectedCampaigns.length > 0" class="my-2">
          <b-row>
            <b-col>
              <b-btn @click="openModalBulkReactivation" variant="primary">Réactiver les campagnes sélectionnées</b-btn>
              <b-btn class="ml-2" @click="selectAllRows" variant="info">
                Sélectionner toute la page
              </b-btn>
              <b-btn class="ml-2" @click="selectedCampaigns = []" variant="warning">Désélectionner tout</b-btn>
            </b-col>
          </b-row>
        </div>
        <div class="my-2">
          <b-row>
            <b-col class="d-flex" style="gap: 0.5rem">
              <div>
                <h4>
                  <b-badge @click="updateAchievableSavings" variant="danger" size="xl">
                    Total économisable: {{ achievableSavings }}€
                  </b-badge>
                </h4>
              </div>
              <div v-if="totalEconomy > 0">
                <h4>
                  <b-badge @click="updateTotalEconomy" variant="success" size="xl" style="cursor: pointer">
                    Total économisé: {{ totalEconomy }}€
                  </b-badge>
                </h4>
              </div>
            </b-col>
          </b-row>
        </div>
        <div style="overflow-x: auto">
          <b-table striped hover class="text-monospace" style="font-size: 0.8rem" :items="filterReport" :fields="fields"
            :per-page="perPage" :current-page="currentPage" stacked="sm" show-empty small selectable select-mode="multi"
            @row-selected="onRowSelected" ref="selectableTable">
            <template #cell(selected)="{ rowSelected, item }">
              <template v-if="rowSelected &&
      selectedCampaigns.includes(item) &&
      item.deactivated === 1
      ">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
            <template #cell(treated)="{ item }">
              <div @click="toggleTreated(item.id)" style="cursor: pointer">
                <p v-if="!item.treated">
                  <img width="32px" src="../assets/switch-off.png" alt="OFF" />
                </p>
                <p v-else>
                  <img width="32px" src="../assets/switch-on.png" alt="ON" />
                </p>
              </div>
            </template>
            <template #cell(reactivated_planned)="{ item }">
              <div @click="toggleReactivated(item.id)" style="cursor: pointer"
                v-if="showReactivatedPlanned && item.source_type === 2">
                <p v-if="item.reactivated_planned">
                  <img width="32px" src="../assets/switch-on.png" alt="ON" />
                </p>
                <p v-else>
                  <img width="32px" src="../assets/switch-off.png" alt="OFF" />
                </p>
              </div>
              <div v-else>
                <b-badge :variant="item.reactivated_planned_date ? 'success' : 'secondary'">
                  {{ item.reactivated_planned_date ? "Réactivation effectuée" : "Non" }}
                </b-badge>
              </div>
            </template>
            <template #cell(source_type)="{ item }">
              <div v-if="item.source_type === 2">Google</div>
              <div v-if="item.source_type === 17">Xandr</div>
              <div v-if="item.source_type === 34">Facebook</div>
              <div v-if="item.source_type === 35">Microsoft</div>
            </template>
            <template #cell(gestcam)="{ item }">
              <div v-if="item.gestcam">
                <b-avatar size="1.8rem" variant="secondary" :text="getGestcamFirstLetter(item.gestcam)"
                  v-b-tooltip.hover :title="item.gestcam"></b-avatar>
              </div>
              <div v-else>N/A</div>
            </template>
            <template #cell(tracking_label)="{ item }">
              <div class="d-flex align-items-center" style="gap: 0.25rem">
                <b-badge variant="secondary" v-b-popover.hover.top="`${item.tracking_label}`">
                  {{ generateLabel(item) }}
                </b-badge>
                <a :href="`https://ads.google.com/aw/overview?campaignId=${item.campaign_id}&adAccountId=${item.account_id}`"
                  target="_blank" v-if="item.source_type === 2">
                  <b-avatar size="1.8rem" variant="secondary" v-b-tooltip.hover title="Ouvrir dans Google Ads">
                    <b-icon icon="box-arrow-up-right" />
                  </b-avatar>
                </a>
              </div>
            </template>
            <template #cell(status)="{ item }">
              <div class="text-center">
                <img v-if="pendingCampaignsStatus?.[item.id]" width="16px" src="../assets/load.gif" alt="loader" />
                <div v-else>
                  <p v-if="activeCampaigns?.[item.id] === 0">
                    <img width="32px" src="../assets/switch-off.png" alt="OFF" />
                  </p>
                  <p v-else-if="activeCampaigns?.[item.id] === 1">
                    <img width="32px" src="../assets/switch-on.png" alt="ON" />
                  </p>
                  <p v-else>
                    <img width="16px" src="../assets/question.png" alt="N/A" />
                  </p>
                </div>
              </div>
            </template>
            <template #cell(advice)="{ item }">
              <div v-if="item.advice === 0">Oui</div>
              <div v-if="item.advice === 1">Non</div>
            </template>
            <template #cell(active_since)="{ item }">
              <div v-if="item.active_since">{{ item.active_since }} jours</div>
              <div v-else>N/A</div>
            </template>
            <template #cell(deactivated)="{ item }">
              <div v-if="item.deactivated === 1">Oui</div>
              <div v-if="item.deactivated === 0">Non</div>
            </template>
            <template #cell(turnover_customer_ttc)="{ item }">
              {{ item.turnover_customer_ttc }}€
            </template>
            <template #cell(turnover_customer_ht)="{ item }">
              {{ item.turnover_customer_ht }}€
            </template>
            <template #cell(turnover_ht)="{ item }">
              {{ item.turnover_ht }}€
            </template>
            <template #cell(turnover_ttc)="{ item }">
              {{ item?.turnover_ttc ?? 0 }}€
            </template>
            <template #cell(total_cost)="{ item }">
              {{ item?.total_cost ?? 0 }}€
            </template>
            <template #cell(margin)="{ item }"> {{ item.margin }}€ </template>
            <template #cell(margin_percent)="{ item }">
              {{ item.margin_percent }}%
            </template>
            <template #cell(reactivated)="{ item }">
              <div v-if="item.reactivated === 1">Oui</div>
              <div v-else>Non</div>
            </template>
            <template #cell(comment)="{ item }">
              <div v-if="item.comment">
                <b-badge v-b-popover.hover.top="`${item.comment}`" variant="secondary">Oui</b-badge>
              </div>
              <div v-else>
                <b-badge variant="secondary">Non</b-badge>
              </div>
            </template>
            <template #cell(action)="{ item }">
              <div class="d-flex align-items-center justify-content-center" style="gap: 0.25rem">
                <b-icon @click.stop="openModalViewDetails(item)" icon="journal-text"
                  v-b-popover.hover.top="'Afficher les détails'" />
                <b-icon @click.stop="openModalReactivation(item)"
                  v-if="item.deactivated === 1 && item.reactivated === 0" icon="chat-dots"
                  v-b-popover.hover.top="'Réactiver la campagne'" />
              </div>
            </template>
          </b-table>
        </div>
      </b-col>
      <b-col md="12" v-if="report">
        <div class="d-flex flex-row justify-content-center align-items-baseline">
          <b-pagination v-if="report.length > 0" align="center" v-model="currentPage" :total-rows="totalRows"
            :per-page="perPage" last-number first-number size="sm"></b-pagination>
          <b-badge pill class="ml-4"> {{ totalRows }} Résultats </b-badge>
        </div>
      </b-col>
    </b-row>
    <b-modal ref="modal_details" centered hide-footer size="lg" v-if="hasDetailsSet">
      <template #modal-title>
        <div class="d-flex flex-row" style="gap: 1rem">
          <div class="d-flex align-items-center justify-content-center">
            <b-icon v-if="viewDetails?.advice === 0" icon="check-circle-fill" variant="success"
              font-scale="1.5"></b-icon>
            <b-icon v-else-if="viewDetails?.advice === 1 && viewDetails?.deactivated === 0
      " icon="exclamation-circle-fill" variant="warning" font-scale="1.5"></b-icon>
            <b-icon v-else-if="viewDetails?.advice === 1 &&
      viewDetails?.deactivated === 1 &&
      viewDetails?.reactivated === 0
      " icon="exclamation-circle-fill" variant="danger" font-scale="1.5"></b-icon>
            <b-icon v-else-if="viewDetails?.advice === 1 &&
      viewDetails?.deactivated === 1 &&
      viewDetails?.reactivated === 1
      " icon="check-circle-fill" variant="info" font-scale="1.5"></b-icon>
          </div>
          <div class="d-flex flex-column">
            <div>
              {{ viewDetails.master_tracking_label }}
            </div>
            <div class="modal-subtitle">
              {{ viewDetails.tracking_label }}
            </div>
          </div>
        </div>
      </template>
      <div class="d-block">
        <b-row>
          <b-col md="4">
            <apexchart type="pie" :options="pieOptions" :series="[
      viewDetails?.turnover_ttc ?? 0,
      viewDetails?.total_cost ?? 0,
    ]"></apexchart>
          </b-col>
          <b-col md="8">
            <b-row>
              <b-col md="12">
                <b-form-group label="Motif de l'alerte :">
                  <b-input disabled :value="formatReason(viewDetails?.reason ?? '')" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Etat :">
                  <b-input disabled :value="formatState(
      viewDetails?.advice ?? 1,
      viewDetails?.deactivated ?? 1,
      viewDetails?.reactivated ?? 0
    )
      " />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Réactivation :">
                  <b-input disabled :value="formatReactivated(
      viewDetails?.reactivated ?? 0,
      viewDetails?.email ?? ''
    )
      " />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Commentaire :">
                  <b-textarea rows="5" no-resize disabled :value="formatComment(viewDetails?.comment ?? '')" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal ref="modal_reactivation" centered title="Réactivation" button-size="sm">
      <template #modal-title>
        <div class="d-flex flex-row" style="gap: 1rem">
          <div class="d-flex align-items-center justify-content-center">
            <b-icon v-if="reactivation?.advice === 0" icon="check-circle-fill" variant="success"
              font-scale="1.5"></b-icon>
            <b-icon v-else-if="reactivation?.advice === 1 && reactivation?.deactivated === 0
      " icon="exclamation-circle-fill" variant="warning" font-scale="1.5"></b-icon>
            <b-icon v-else-if="reactivation?.advice === 1 &&
      reactivation?.deactivated === 1 &&
      reactivation?.reactivated === 0
      " icon="exclamation-circle-fill" variant="danger" font-scale="1.5"></b-icon>
            <b-icon v-else-if="reactivation?.advice === 1 &&
      reactivation?.deactivated === 1 &&
      reactivation?.reactivated === 1
      " icon="check-circle-fill" variant="info" font-scale="1.5"></b-icon>
          </div>
          <div class="d-flex flex-column">
            <div>
              {{ reactivation.master_tracking_label }}
            </div>
            <div class="modal-subtitle">
              {{ reactivation.tracking_label }}
            </div>
          </div>
        </div>
      </template>
      <template #modal-footer>
        <div v-if="!sending">
          <b-button size="sm" variant="danger" @click="closeReactivation()">
            Annuler
          </b-button>
          &nbsp;
          <b-button size="sm" variant="success" @click="sendReactivation()">
            Réactiver
          </b-button>
        </div>
        <div v-else>
          <b-spinner small />
        </div>
      </template>
      <b-row>
        <b-col md="12">
          <b-form-group label="Commentaire :">
            <b-textarea :disabled="sending" rows="5" no-resize
              placeholder="Merci d'expliquer en quelques mots la raison pour laquelle vous souhaitez réactiver cette campagne."
              v-model="comment" />
          </b-form-group>
        </b-col>
        <b-col v-if="notExplicitComment" md="12" class="modal-subtitle">
          Le commentaire n'est pas suffisament explicite.
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="modal_bulk_reactivation" centered title="Réactivation en masse" button-size="sm">
      <template #modal-title>
        <div class="d-flex flex-row" style="gap: 1rem">
          <div class="d-flex align-items-center justify-content-center">
            <b-icon icon="exclamation-circle-fill" variant="warning" font-scale="1.5"></b-icon>
          </div>
          <div class="d-flex flex-column">
            <div>Réactivation des campagnes sélectionnées</div>
          </div>
        </div>
      </template>
      <div class="modal-subtitle mt-2">
        {{ selectedCampaigns.length }} campagnes sélectionnées
      </div>
      <ul class="modal-subtitle max-height">
        <li v-for="campaign in selectedCampaigns" :key="campaign.id">
          {{ campaign.master_tracking_label }}
        </li>
      </ul>
      <template #modal-footer>
        <div v-if="!sending">
          <b-button size="sm" variant="danger" @click="closeBulkReactivation()">
            Annuler
          </b-button>
          &nbsp;
          <b-button size="sm" variant="success" @click="sendBulkReactivation()">
            Réactiver
          </b-button>
        </div>
        <div v-else>
          <b-spinner small />
        </div>
      </template>
      <b-row>
        <b-col md="12">
          <b-form-group label="Commentaire :">
            <b-textarea :disabled="sending" rows="5" no-resize
              placeholder="Merci d'expliquer en quelques mots la raison pour laquelle vous souhaitez réactiver cette campagne."
              v-model="comment" />
          </b-form-group>
        </b-col>
        <b-col v-if="notExplicitComment" md="12" class="modal-subtitle">
          Le commentaire n'est pas suffisament explicite.
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<style>
.max-height {
  max-height: 200px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }
}
</style>
