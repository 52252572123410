<script>
export default {
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'donut',
        },
        labels: [],
        legend: {
          position: 'bottom',
        },
      },
      series: []
    }
  },
  created() {
    this.chartOptions.labels = this.chartData.labels;
    this.series = this.chartData.datasets[0].data;
  },
}
</script>

<template>
  <div>
    <apexchart type="donut" :options="chartOptions" :series="series" width="380" />
  </div>
</template>
