<template>
  <apexchart class="apexchart"
             height="350"
             :options="this.chartOption"
             :series="chartSerie"/>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "prospects",
  data: () => ({
    request: 'amenitiz/prospects',
    chartOption: {
      chart: {
        type: 'area',
        height: 350,
        width: '98%',
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: 'Prospects reçus (cumulatif)',
        align: 'left'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'dd-MM-yyyy'
        },
        categories: []
      },
      series: [
        {
          name: 'Prospects signés',
          type: 'area',
          data: []
        },
        {
          name: 'Prospects en attente',
          type: 'area',
          data: []
        },
        {
          name: 'Prospects abandonnés',
          type: 'area',
          data: []
        }
      ],
      legend: {
        position: 'left',
        offsetY: 100,
        fontFamily: 'Helvetica, Arial',
      },
      stroke: {
        curve: 'smooth'
      },
      colors: ['#28A745', '#FFC107', '#DC3545'],
      fill: {
        opacity: 1,
      },
    },
    chartSerie: [],
  }),

  props: {
    switchParent: {
      type: Boolean,
      default: false
    },
    date: {
      type: Object,
      default: () => ({
        startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      })
    }
  },

  computed: {
    checked() {
      return this.switchParent;
    }
  },

  async mounted() {
    await this.getProspectsSeries();
  },

  watch: {
    checked: async function () {
      await this.handleCheckValue();
    },

    date: {
      handler: async function () {
        await this.getProspectsSeries();
      },
      deep: true
    }
  },

  methods: {
    async handleCheckValue(shouldProspectsSeriesBeGenerated = true) {
      if (this.checked) {
        this.chartOption.title.text = 'Prospects par semaine';
        this.request = 'amenitiz/prospects';
      } else {
        this.chartOption.title.text = 'Prospects par jour';
        this.request = 'amenitiz/prospects';
      }
      this.chartOption = {
        ...this.chartOption
      }
      if (shouldProspectsSeriesBeGenerated)
        await this.getProspectsSeries();
    },

    async getProspectsSeries() {
      await this.handleCheckValue(false);
      const response = await this.$http.get(this.request, {
        params: {
          startDate: this.date.startDate,
          endDate: this.date.endDate,
          perWeek: this.checked
        }
      });
      if (response && response.data && response.data.content) {
        this.chartOption.series[0].data = response.data.content.serieSigned || [];
        this.chartOption.series[1].data = response.data.content.serieUnSigned || [];
        this.chartOption.series[2].data = response.data.content.serieSignatureWaiver || [];
        this.chartOption.xaxis.categories = response.data.content.xaxis || [];
        this.chartOption = {
          ...this.chartOption
        };
      } else {
        this.chartOption.series[0].data = [];
        this.chartOption.series[1].data = [];
        this.chartOption.series[2].data = [];
        this.chartOption.xaxis.categories = [];
        this.chartOption = {
          ...this.chartOption
        };
      }
    }
  },

}
</script>

<style scoped>
</style>
