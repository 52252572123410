import 'mutationobserver-shim'
import './plugins/bootstrap-vue'
import './plugins/vue-sweetalert2'
import './plugins/axios'
import 'video.js/dist/video-js.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApexCharts from 'vue-apexcharts'

import Spinner from './components/Spinner'
import Loading from './components/Loading'
import Breadcrumb from './components/Breadcrumb'
import Multiselect from 'vue-multiselect'
import Progress from './components/Progress'

Vue.component('Spinner', Spinner)
Vue.component('Breadcrumb', Breadcrumb)
Vue.component('Progress', Progress)
Vue.component('Loading', Loading)
Vue.component('multiselect', Multiselect)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueApexCharts);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
