import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pieChartLoading: false,
    requestChartLoading: false,
    requestsPerMonthsChartLoading: false,
    treeMapLoading: false,
    requestNumberReportsLoading: false,
    requestNumberAndUsersReportsLoading: false,
    requestNumberAndTypeReportsLoading: false,
    requestTypesReportsLoading: false,
    toPath: null,
    customer: null,
    totalRows_NA: 1,
    totalRows_A: 1,
    loading: false,
  },
  getters: {
    isLoading: state => {
      return state.loading
    },
    getCustomer: state => {
      return state.customer
    },
    getUser: state => {
      return state.user
    },
    isStatsLoading: state => {
      return state.pieChartLoading && state.requestChartLoading && state.requestsPerMonthsChartLoading && state.treeMapLoading
          && state.requestNumberReportsLoading
          && state.requestNumberAndUsersReportsLoading
          && state.requestTypesReportsLoading
          && state.requestNumberAndTypeReportsLoading
    },
    isLoggedIn: () => {
      return localStorage.auth
    }
  },
  mutations: {
    isLoading (state, loading) {
      state.loading = loading
    }
  },
  actions: {
  },
  modules: {
  }
})
