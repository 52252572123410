<template>
  <div class="chart">
    <apexchart
        :options="chartOptions"
        :series="pieChartData"
        type="pie"
        height="500"
        class="apexchart"
    >
    </apexchart>
  </div>
</template>

<script>
export default {
  name: "requestReports",
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
  },
  data: () => ({
    pieChartData: [],
    pieChartLabels: [],
    chartOptions: {
      title: {
        text: 'Type des rapports',
        style: {
          fontSize: '25px',
        }
      },
      chart: {
        toolbar: {
          show: true,
        },
        type: 'pie'
      },
      theme: {
        mode: 'light',
        palette: 'palette1',
      },
      dataLabels: {
        enabled: true,
      },
    }
  }),
  created() {
    this.getRequestsData();
  },
  watch: {
    startDate: function () {
      this.getRequestsData();
    },
    endDate: function () {
      this.getRequestsData();
    }
  },
  methods: {
    getRequestsData: async function () {
      this.$store.state.requestTypesReportsLoading = true
      const response = await this.$http.get('/stats/report/typeReport?startDate=' + this.startDate + '&endDate=' + this.endDate);
      this.pieChartData = response.data.content.series;
      this.pieChartLabels = response.data.content.categories
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels: this.pieChartLabels,
        }
      }
      this.$store.state.requestTypesReportsLoading = false
    },
  }
}
</script>

<style scoped>
.chart {
  margin: 0 auto;
  width: 100%;
}
</style>
