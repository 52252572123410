<template>
  <div class="stats_gcmv2">
    <b-row class="tools">
      <b-col>
        <PeriodSelect @Date="date => { this.date = date; }"/>
        <LoginSelector @login="login => { this.login = login }"/>
      </b-col>
    </b-row>
    <b-row class="charts">
      <b-col>
        <b-row v-if="chartSerieNav.length > 0">
          <b-col>
            <GCMV2Chart :date="date" :login="login" :titleParam="titleConv" :chartParam="chartSerieNav"
                        :xaxisParam="xaxisParam"/>
          </b-col>
        </b-row>
        <b-row v-if="chartSerieConv.length > 0">
          <b-col>
            <GCMV2Chart :date="date" :login="login" :titleParam="titleNav" :chartParam="chartSerieConv"
                        :xaxisParam="xaxisParam"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PeriodSelect from "@/components/PeriodSelect";
import LoginSelector from "@/components/LoginSelector.vue";
import GCMV2Chart from "@/components/charts/gcmv2/gcmv2Chart.vue";
import dayjs from "dayjs";

export default {
  name: 'GCMV2Stats',
  components: {
    GCMV2Chart,
    LoginSelector,
    PeriodSelect,
  },
  data: () => ({
    name: "Stats",
    titleNav: 'Taux des acceptations de cookies dans le traffic (par cookie par jour)',
    titleConv: 'Taux des acceptations de cookies dans les conversions (par cookie par jour)',
    login: null,
    loading: true,
    timer: null,
    bookings: [],
    checked: false,
    dataChartsPerDay: [],
    dataChartsPerWeek: [],
    perDay: [],
    filter: null,
    filterOn: [],
    date: {
      startDate: null,
      endDate: null,
    },
    chartSerieNav: [],
    chartSerieConv: [],
    xaxisParam: {categories: []},
  }),
  async mounted() {
    this.date.startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
    this.date.endDate = dayjs().format('YYYY-MM-DD');
  },
  computed: {
    isLoading: function () {
      return this.$store.getters.isLoading;
    }
  },
  watch: {
    date: {
      handler: async function () {
        await this.getStats();
      },
      deep: true
    },

    login: {
      handler: async function () {
        await this.getStats();
      },
      deep: true
    }
  },
  methods: {
    async getStats() {
      if (!this.login || this.login === 'Aucun') return;
      this.$store.commit("isLoading", true);
      try {
        const response = await this.$http.get(`/gc-consent/${this.login}/stats?startDate=${this.date.startDate}&endDate=${this.date.endDate}`);
        const {content} = response.data || {};
        this.chartSerieNav = [];
        this.chartSerieConv = [];
        if (content) {
          const {navStats, convStats, xaxis} = content;
          this.xaxisParam = {categories: xaxis ?? []};
          for (const index in convStats) this.chartSerieConv.push({name: index, data: convStats[index] ?? []})
          for (const index in navStats) this.chartSerieNav.push({name: index, data: navStats[index] ?? []})
        } else
          this.xaxisParam = {categories: []};
      } catch (_) {
        console.warn('Cannot retrieve data');
      }
      this.$store.commit("isLoading", false);
    },
  },
}
</script>

<style lang="scss">
.stats_gcmv2 {
  padding: 0 3rem;

  > * > * {
    margin-bottom: 2rem;
  }
}

.tools {
  align-items: center;
}

.stats {
  display: flex;
  gap: 4rem;

  span {
    font-weight: bold;

    &.negative {
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      background-color: #ff5252;
      color: white;
    }

    &.positive {
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      background-color: #4caf50;
      color: white;
    }
  }
}

.charts {
  > * > * {
    margin-bottom: 3rem;
  }
}

</style>
