import axios from 'axios';
import Vue from "vue";

axios.defaults.baseURL = process.env.VUE_APP_API;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = localStorage.auth ? 'Bearer ' + localStorage.auth : '';

const pendingRequests = {};

const getEndpointName = (url) => {
  if (!url) return null;
  const match = url.match(/\/(booking|finance|rate|stats|goal)/);
  return match ? match[0] : null;
};

axios.interceptors.request.use(config => {
  const endpointName = getEndpointName(config.url);

  if (endpointName) {
    if (pendingRequests[endpointName]) {
      pendingRequests[endpointName].cancel('Canceled due to new request');
    }

    config.cancelToken = new axios.CancelToken(cancel => {
      pendingRequests[endpointName] = { cancel };
    });
  }

  return config;
}, error => {
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  const endpointName = getEndpointName(response.config.url);

  if (endpointName) {
    delete pendingRequests[endpointName];
  }

  return response;
}, error => {
  const endpointName = error.config && getEndpointName(error.config.url);

  if (axios.isCancel(error)) {
    console.log('Request canceled:', error.message);
  } else {
    if (endpointName) {
      delete pendingRequests[endpointName];
    }

    if (error.response) {
      if (error.response.status === 401) {
        delete localStorage.auth;
        window.location.replace('/login');
      } else if (error.response.status === 403) {
        window.location.replace(localStorage.home ?? '/');
      }
    }
  }

  return Promise.reject(error);
});

Vue.prototype.$http = axios;
