<script setup>
import PeriodSelect from "@/components/PeriodSelect.vue";
import { ref, watch, onMounted } from "vue";

import { useApi } from "@/composables/useApi";
import { useToast } from "@/composables/useToast";
const api = useApi();
const toast = useToast();

const originsUnknown = ref([]);
const fields = [
  {key: 'login', label: 'Login', sortable: true},
  {key: 'roi_id', label: 'ID', sortable: true},
  {key: 'roi_ref', label: 'Référence', sortable: true},
  {key: 'tracking_id', label: 'ID Levier', sortable: true},
  {key: 'algo_ids', label: 'Algo', sortable: true},
  {key: 'user_corrector', label: 'Correcteur', sortable: true},
  {key: 'date_fixed', label: 'Résolue le', sortable: true},
  {key: 'date_roi', label: 'Date de conversion', sortable: true},
  {key: 'actions', label: 'Résoudre'},
];
const currentPage = ref(1);
const perPage = ref(10);
const totalRows = ref(0);
const filter = ref(null);
const filterOn = ref([
  "login",
  "user_corrector",
  "tracking_id",
  "roi_ref",
  "algo_ids"
]);
const date = ref({
  startDate: null,
  endDate: null,
});
const resolved = ref(0);
const isDisplayUnresolved = ref(false);
const algoName = ref({
  1: 'Premier clic',
  2: 'Dernier clic',
  3: 'Base',
  4: 'Linéaire',
  5: 'Progressif',
  6: 'Linéaire priorisé',
  7: 'Dernier clic payant',
  8: 'Custom'
});

onMounted(async () => {
  await getUnknownOrigins();
});

function onFiltered(filteredItems) {
  totalRows.value = filteredItems.length
  currentPage.value = 1
}

async function getUnknownOrigins() {
  try {
    if (date.value.startDate != null && date.value.endDate != null && !isDisplayUnresolved.value) {
      const response = await api.get(`/unknownOrigins?startDate=${date.value.startDate}&endDate=${date.value.endDate}`);
      if (response.status === 200) {
        originsUnknown.value = response.data.content;
        if (originsUnknown.value && Array.isArray(originsUnknown.value)) {
          originsUnknown.value = originsUnknown.value?.map(origin => {
            origin._showDetails = true;
            origin.algo_ids = origin.algo_ids.replace(/,/g, '');
            return origin;
          });
          resolved.value = originsUnknown.value.filter(origin => origin._rowVariant === 'success').length;
          totalRows.value = response.data?.total;
        } else {
          originsUnknown.value = [];
          totalRows.value = 0;
        }
      } else {
        originsUnknown.value = [];
        totalRows.value = 0;
      }
    }
  } catch (e) {
    toast.toast('Impossible de récupérer les origines inconnues.', {
      title: 'Error',
      noCloseButton: true,
      variant: 'danger',
      solid: true,
      autoHideDelay: 1500,
    })
    originsUnknown.value = [];
    totalRows.value = 0;
  }
}

async function updateRow(row) {
  if (row._rowVariant === 'warning') {
    row.user_corrector = localStorage.user;
    row.date_fixed = new Date();
    row.date_fixed = row.date_fixed.toISOString().slice(0, 19).replace('T', ' ');
    row._rowVariant = 'success';
  } else if (row._rowVariant === 'success') {
    row.user_corrector = null;
    row.date_fixed = null;
    row._rowVariant = 'warning';
  }
  try {
    await api.put("/unknownOrigins", {
      origin: row
    });
  } catch (e) {
    toast.toast('Impossible de mettre à jour cette origine inconnue.', {
      title: 'Error',
      noCloseButton: true,
      variant: 'danger',
      solid: true,
      autoHideDelay: 1500,
    })
  } finally {
    await getUnknownOrigins();
  }
}

async function displayUnresolved(e) {
  if (e) {
    originsUnknown.value = originsUnknown.value.filter(origin => origin._rowVariant === 'warning');
    totalRows.value = originsUnknown.value.length;
    resolved.value = 0;
    isDisplayUnresolved.value = true;
  } else {
    isDisplayUnresolved.value = false;
    await getUnknownOrigins();
  }
}

function copyToClipboard(item) {
  navigator.clipboard.writeText(item);
  toast.toast('Copié !', {
    title: 'Success',
    noCloseButton: true,
    variant: 'success',
    solid: true,
    autoHideDelay: 1500,
  });
}

watch(date, () => {
  getUnknownOrigins();
}, { deep: true })
</script>

<template>
  <div class="unknownOrigins-view">
    <div class="row">
      <div class="col-xl">
        <b-row>
          <b-col>
            <PeriodSelect @Date="newDate => date = newDate"/>
          </b-col>
          <b-col lg="4">
            <div class="menu-datatable">
              <b-form-group label="Recherche :">
                <b-input-group>
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Rechercher"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>

          <b-col lg="2">
            <b-form-group label="Non résolues uniquement">
              <b-form-checkbox @change="displayUnresolved" switch size="lg"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12">
            <b-progress class="mb-2" :value="totalRows" :max="100">
              <b-progress-bar variant="success" :value="(100 * resolved )/ totalRows"
                              :label="`${(((100 * resolved )/ totalRows)).toFixed(2)}%`"></b-progress-bar>
              <b-progress-bar variant="warning" :value="100 - ((resolved * 100)/ totalRows)"
                              :label="`${(100 - ((resolved * 100)/ totalRows)).toFixed(2)}%`"></b-progress-bar>
            </b-progress>
            <b-table
                id="origin-unknown-table"
                :items="originsUnknown"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :per-page="perPage"
                :current-page="currentPage"
                :filter-debounce="200"
                empty-text="Aucune donnée"
                striped
                bordered
                hover
                no-border-collapse
                @filtered="onFiltered"
                stacked="sm"
                show-empty
                small
            >
              <template #thead-top>
                <b-tr>
                  <b-th colspan="1">Origines inconnues</b-th>
                  <b-th colspan="6">Total : {{ totalRows }}</b-th>
                  <b-th colspan="1" variant="warning">
                    Non résolues : {{ totalRows - resolved }}
                  </b-th>
                  <b-th colspan="1" variant="success">
                    Résolues : {{ resolved }}
                  </b-th>
                </b-tr>
              </template>

              <template #cell(login)="{item}">
                <div :title="item.login"
                     style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                     @click="copyToClipboard(item.login)"
                >
                  {{ item.login }}
                </div>
              </template>

              <template #cell(roi_id)="{item}">
                <div :title="item.roi_id"
                     style="width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                     @click="copyToClipboard(item.roi_id)"
                >
                  {{ item.roi_id }}
                </div>
              </template>

              <template #cell(roi_ref)="{item}">
                <div :title="item.roi_ref"
                     style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                     @click="copyToClipboard(item.roi_ref)"
                >
                  {{ item.roi_ref }}
                </div>
              </template>

              <template #cell(tracking_id)="{item}">
                <div :title="item.tracking_id"
                     style="width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                     @click="copyToClipboard(item.tracking_id)"
                >
                  {{ item.tracking_id }}
                </div>
              </template>

              <template #cell(algo_ids)="item">
                <b-badge
                         v-for="algo in item.item.algo_ids"
                         v-b-popover.top.hover="algoName[algo]"
                         :key="algo"
                         variant="info"
                         >
                  {{ algo }}
                </b-badge>
              </template>

              <template v-slot:cell(actions)="{item}">
                <div v-if="item._rowVariant === 'warning'">
                  <b-badge variant="success" @click="updateRow(item)">
                    <b-icon icon="check"></b-icon>
                  </b-badge>
                </div>
                <div v-if="item._rowVariant === 'success'">
                  <b-badge variant="warning" @click="updateRow(item)">
                    <b-icon icon="x"></b-icon>
                  </b-badge>
                </div>
              </template>

              <template v-slot:cell(date_fixed)="{item}">
                <span v-if="item.date_fixed === null">N/A</span>
                <span v-else>{{ item.date_fixed }}</span>
              </template>

              <template v-slot:cell(user_corrector)="{item}">
                <span v-if="item.user_corrector === null">N/A</span>
                <span v-else>{{ item.user_corrector }}</span>
              </template>

            </b-table>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="origin-unknown-table"
                size="sm"
            ></b-pagination>
          </b-col>

        </b-row>

      </div>
    </div>
  </div>
</template>

<style scoped>
.menu-datatable {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 1230px) {
  .menu-datatable {
    flex-direction: column;
  }
}
.badge {
  margin-right: 5px;
}

.badge:last-child {
  margin: 0;
}
</style>
